import React, { useState } from "react";
import {
    RadioButtonGroupInput,
    useTranslate,
    TextInput,
    required,
    ReferenceInput,
    AutocompleteInput,
    FormDataConsumer,
} from "react-admin";
import _uniqueId from "lodash/uniqueId";
import CCTFormLabel from "../front/components/CCTFormLabel";
import classnames from "classnames";
import { get } from "lodash";

const ParticipantsInput = ({
    classes = {},
    fields,
    admin = false,
    removeButton,
    ...props
}) => {
    const translate = useTranslate();
    const [id] = useState(_uniqueId("participantsinput-"));
    return fields
        ? fields.map((member, index) => {
              return (
                  <div
                      key={index}
                      className={classnames(
                          classes.formLine,
                          classes.participantsInput
                      )}
                  >
                      <div
                          className={classnames(
                              classes.formLineInner,
                              classes.formLineInnerWithBlock
                          )}
                      >
                          {removeButton
                              ? React.cloneElement(removeButton, {
                                    index,
                                })
                              : null}
                          {admin ? null : (
                              <CCTFormLabel
                                  className={classes.formLabel}
                                  htmlFor={`${id}-${index}-firstName`}
                              >
                                  {translate("Invité {{number}}", {
                                      number: index + 1,
                                  })}
                              </CCTFormLabel>
                          )}
                          <div
                              className={classnames(
                                  classes.formLineBlock,
                                  classes.formLineBlockInlineCenter
                              )}
                          >
                              <TextInput
                                  validate={[required()]}
                                  source={`${member}.firstName`}
                                  id={`${id}-${index}-firstName`}
                                  size="small"
                                  placeholder={translate("Prénom")}
                                  label={""}
                                  variant="outlined"
                              />
                              <TextInput
                                  validate={[required()]}
                                  source={`${member}.lastName`}
                                  id={`${id}-${index}-lastName`}
                                  size="small"
                                  placeholder={translate("Nom")}
                                  label={""}
                                  variant="outlined"
                              />
                              <RadioButtonGroupInput
                                  validate={admin ? [] : [required()]}
                                  source={`${member}.type`}
                                  className={classes.radioGroup}
                                  options={{
                                      label: null,
                                  }}
                                  choices={[
                                      {
                                          id: "invite",
                                          name: translate("Invité"),
                                      },
                                      {
                                          id: "chevalier",
                                          name: translate("Membre"),
                                      },
                                      {
                                          id: "intronise",
                                          name: translate(
                                              "Candidat à l'intronisation"
                                          ),
                                      },
                                  ]}
                              />
                              {admin ? (
                                  <FormDataConsumer>
                                      {({ formData, ...rest }) =>
                                          true || // Si on n'affiche pas le champs on risque d'enregistrer un numéro de membre sur un "invité" en changeant son type de chevalier à invité.
                                          (formData &&
                                              get(formData, member) &&
                                              get(formData, member).type ===
                                                  "chevalier") ? (
                                              <ReferenceInput
                                                  {...rest}
                                                  variant="outlined"
                                                  allowEmpty
                                                  label="Membre"
                                                  reference="membre"
                                                  source={`${member}.memberNumber`}
                                                  id={`${id}-${index}-memberNumber`}
                                                  size="small"
                                                  sort={{
                                                      field: "id",
                                                      order: "ASC",
                                                  }}
                                                  filterToQuery={(
                                                      searchText
                                                  ) => ({
                                                      _search: searchText,
                                                  })}
                                              >
                                                  <AutocompleteInput
                                                      optionText="Nom"
                                                      matchSuggestion={() =>
                                                          true
                                                      }
                                                  />
                                              </ReferenceInput>
                                          ) : null
                                      }
                                  </FormDataConsumer>
                              ) : null}
                              {admin ? (
                                  <TextInput
                                      source={`${member}.notes`}
                                      id={`${id}-${index}-notes`}
                                      size="small"
                                      placeholder={translate("Notes")}
                                      label={""}
                                      variant="outlined"
                                  />
                              ) : null}
                          </div>
                      </div>
                  </div>
              );
          })
        : null;
};

export default ParticipantsInput;
