import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "inline-block",
    },
}));
const Logo = ({ className, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const title = translate("Confrérie des Chevaliers du Tastevin");
    return (
        <img
            className={classnames(className, classes.root)}
            src="/images/logo-cct.png"
            width="100"
            height="100"
            alt={title}
            title={title}
            {...props}
        />
    );
};

export default Logo;
