import ModelHelper from "./ModelHelper";

const PostJournalHelper = class extends ModelHelper {
    /**
     * @property {string}
     */
    name;
    /**
     * @property {number}
     */
    poster;
    /**
     * @property {number}
     */
    poster_small;
    get i18nDataProperty() {
        return "PostJournal_i18n";
    }
    get displayName() {
        return this.title || this.name;
    }
};

export default PostJournalHelper;
