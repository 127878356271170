import React from "react";
import SousCommanderiesController from "../../controllers/SousCommanderiesController";
import Select from "@material-ui/core/Select";
import { useTranslate } from "react-admin";
import MenuItem from "@material-ui/core/MenuItem";
import useCurrentUser from "../../hooks/useCurrentUser";

const SousCommanderieSelect = ({
    commanderieSelected,
    setCommanderieSelected,
    ...props
}) => {
    const translate = useTranslate();
    const user = useCurrentUser();
    return (
        user && (
            <SousCommanderiesController
                filter={{
                    "N° Sous-commanderie": {
                        "{{Op.in}}": user.codesCommanderies,
                    },
                }}
            >
                {({ data }) => (
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={commanderieSelected}
                        onChange={(event) =>
                            setCommanderieSelected(event.target.value)
                        }
                    >
                        <MenuItem value={-1}>
                            {translate("Tous les chapitres")}
                        </MenuItem>
                        <MenuItem value={0}>
                            {translate("Chapitres de la Confrérie")}
                        </MenuItem>
                        {data &&
                            data.map(
                                (souscommanderie, souscommanderieIndex) => (
                                    <MenuItem
                                        key={souscommanderieIndex}
                                        value={
                                            souscommanderie[
                                                "N° Sous-commanderie"
                                            ]
                                        }
                                    >
                                        {translate("{{type}} de {{name}}", {
                                            name: souscommanderie.displayName,
                                            type: souscommanderie.Type,
                                        })}
                                    </MenuItem>
                                )
                            )}
                    </Select>
                )}
            </SousCommanderiesController>
        )
    );
};

export default SousCommanderieSelect;
