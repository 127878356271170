import React from "react";
import Typography from "@material-ui/core/Typography";
import { useRosterStyles } from "../pages/Roster";
import classnames from "classnames";

const MemberDetailsCommittee = ({
    membre,
    className,
    component: Component = "div",
    ...props
}) => {
    const classes = useRosterStyles();
    return (
        <Typography
            component={Component}
            variant="body1"
            color="primary"
            className={classnames(
                classes.memberListItemLine,
                classes.memberListItemLineGray,
                classes.memberListItemLineSCTitle
            )}
        >
            {membre["Committee #1"] ? (
                <span>{membre["Committee #1"]}</span>
            ) : null}
            {membre["Committee #2"] ? (
                <span>{membre["Committee #2"]}</span>
            ) : null}
        </Typography>
    );
};

export default MemberDetailsCommittee;
