import React, { useState, useEffect } from "react";
import moment from "moment";
import TastevinHelper from "../helpers/TastevinHelper";

import ModelListController from "./ModelListController";

/**
 * @param {Object} props
 * @param {function({ data: TastevinHelper[], loaded: Boolean })} props.children
 * @param {Date} props.minDate Filtrer les chapitres : après la date spécifiée.
 * @param {Number} props.page pagination
 * @param {Number} props.perPage pagination
 * @returns {{data:TastevinHelper[], loaded:boolean}}
 */
const TastevinListController = ({
    participating,
    filter,
    sort = {
        field: "publishDate",
        order: "DESC",
    },
    ...props
}) => {
    const [now] = useState(new Date());
    return (
        <ModelListController
            {...props}
            sort={sort}
            filter={filter || { publishDate: { "{{Op.lte}}": now } }}
            resource="tastevin"
            helperClass={TastevinHelper}
        />
    );
};

export default TastevinListController;
