import ModelHelper from "./ModelHelper";
import getApiUrl from "../utils/getApiUrl";

const apiUrl = getApiUrl();

const AttachedDocumentHelper = class extends ModelHelper {
    get url() {
        return this._url ? `${apiUrl}/${this._url}` : null;
    }
    set url(val) {
        this._url = val;
    }
};

export default AttachedDocumentHelper;
