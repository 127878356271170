import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core/";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslate, TextInput, Create, useNotify } from "react-admin";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { Form } from "react-final-form";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import IconButton from "@material-ui/core/IconButton";

import SimpleSaveButton from "./SimpleSaveButton";
import InputAdornment from "@material-ui/core/InputAdornment";

import passwordValidator from "password-validator";
import createPasswordValidator from "../../shared/createPasswordValidator";

const useStyles = makeStyles((theme) => ({
    root: {},
    create: {
        [theme.breakpoints.down("xs")]: {
            display: "flex",
            height: "100%",
            flexDirection: "column",
        },
    },
    createMain: {
        marginTop: 0,
        [theme.breakpoints.down("xs")]: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
    },
    dialog: {
        textAlign: "center",
        padding: theme.spacing(0, 0),

        [theme.breakpoints.down(340)]: {
            padding: theme.spacing(0, 0),
            height: "100%",
        },
    },
    form: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            height: "100%",
        },
    },
    dialogActions: {
        padding: theme.spacing(2, 2, 4, 2),
        textAlign: "center",
        justifyContent: "center",
    },
    content: {
        padding: theme.spacing(1, 2), // prevent vertical scrolling due to fields labels(I guess)
        [theme.breakpoints.up("sm")]: {
            width: 300,
        },
        maxWidth: 300,
        boxSizing: "border-box",
        margin: theme.spacing(1),
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "left",
        [theme.breakpoints.down("xs")]: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
    },
    textInput: {},
}));
const UpdatePasswordForm = ({
    save,
    classes,
    handleClose,
    callback,
    ...props
}) => {
    const { saving } = props;
    const translate = useTranslate();
    const notify = useNotify();
    const submit = async (values, complete) => {
        try {
            await new Promise((resolve, reject) => {
                save(values, false, {
                    onSuccess: (...args) => {
                        notify("Mot de passe enregistré", "info");
                        resolve(args);
                        handleClose();
                    },
                    onFailure: (error) => {
                        reject(error);
                    },
                });
            });
        } catch (error) {
            // const returnValue = error && error.errorsByField ? error.errorsByField : null;
            notify(error.message, "error");
            // défini les submitErrors du formulaire
            // On le fait pas, ça marche pas à cause des TextInput de react-admin qui supportent pas submitErrors
            // https://github.com/marmelab/react-admin/issues/4351
            // return returnValue;
        }
    };

    const [values, setValues] = useState({
        showPassword: false,
        showNewPassword: false,
        showNewPasswordConfirm: false,
    });

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownNewPassword = (event) => {
        event.preventDefault();
    };
    const handleClickShowNewPassword = () => {
        setValues({ ...values, showNewPassword: !values.showNewPassword });
    };

    const handleMouseDownNewPasswordConfirm = (event) => {
        event.preventDefault();
    };
    const handleClickShowNewPasswordConfirm = () => {
        setValues({
            ...values,
            showNewPasswordConfirm: !values.showNewPasswordConfirm,
        });
    };
    return (
        <Form
            {...props}
            onSubmit={submit}
            render={({ handleSubmit, invalid, form, ...formProps }) => (
                <form className={classes.form}>
                    <DialogTitle
                        id="alert-dialog-title"
                        className={classes.title}
                        disableTypography={true}
                    >
                        <h2>{translate("UpdatePassword.popupTitle")}</h2>
                    </DialogTitle>
                    <DialogContent className={classes.content}>
                        <>
                            <TextInput
                                className={classes.textInput}
                                source="password"
                                id="standard-password-input"
                                label={translate(
                                    "UpdatePassword.labelPassword"
                                )}
                                autoComplete="current-password"
                                variant="outlined"
                                fullWidth={true}
                                type={values.showPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                            >
                                                {values.showPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextInput
                                className={classes.textInput}
                                source="passwordNew"
                                id="new-password-input"
                                label={translate(
                                    "UpdatePassword.labelPasswordNew"
                                )}
                                type={
                                    values.showNewPassword ? "text" : "password"
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowNewPassword
                                                }
                                                onMouseDown={
                                                    handleMouseDownNewPassword
                                                }
                                            >
                                                {values.showNewPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                fullWidth={true}
                                validate={[
                                    (value, values, field) => {
                                        let schema = createPasswordValidator(
                                            new passwordValidator()
                                        );

                                        return schema.validate(value)
                                            ? null
                                            : translate(
                                                  "UpdatePassword.motDePasseValidate"
                                              );
                                    },
                                ]}
                            />
                            <TextInput
                                className={classes.textInput}
                                source="passwordConfirm"
                                id="confirm-password-input"
                                type={
                                    values.showNewPasswordConfirm
                                        ? "text"
                                        : "password"
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowNewPasswordConfirm
                                                }
                                                onMouseDown={
                                                    handleMouseDownNewPasswordConfirm
                                                }
                                            >
                                                {values.showNewPasswordConfirm ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                label={translate(
                                    "UpdatePassword.labelPasswordConfirm"
                                )}
                                variant="outlined"
                                fullWidth={true}
                                validate={[
                                    (value, values, field) =>
                                        value !== values["passwordNew"]
                                            ? translate(
                                                  "UpdatePassword.identique"
                                              )
                                            : null,
                                ]}
                            />
                        </>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            onClick={handleClose}
                            variant="outlined"
                            color="secondary"
                            autoFocus
                        >
                            {translate("Fermer")}
                        </Button>
                        <SimpleSaveButton
                            color="secondary"
                            handleSubmitWithRedirect={handleSubmit}
                            invalid={invalid}
                            icon={null}
                            saving={saving}
                            submitOnEnter={true}
                            label={translate("Valider")}
                        />
                    </DialogActions>
                </form>
            )}
        />
    );
};

const ResetPasswordModal = ({
    className,
    open = false,
    handleClose,
    ...props
}) => {
    const classes = useStyles();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    return (
        <>
            <Dialog
                className={classes.dialog}
                open={open}
                elevation={0}
                fullScreen={fullScreen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <Create
                    resource="user/updatepassword"
                    basePath="/"
                    classes={{ root: classes.create, main: classes.createMain }}
                >
                    <UpdatePasswordForm
                        handleClose={handleClose}
                        classes={classes}
                    />
                </Create>
            </Dialog>
        </>
    );
};

export default ResetPasswordModal;
