import React from "react";

const AddressPartsLocalized = ({ zip, city, country, state, locale }) =>
    locale === "fr" ? (
        <>
            {zip}
            {city}
            {state}
            {country}
        </>
    ) : (
        <>
            {city}
            {state}
            {zip}
            {country}
        </>
    );

export default AddressPartsLocalized;
