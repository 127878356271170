import React, { useState } from "react";
import {
    Create,
    TextInput,
    useTranslate,
    required,
    useNotify,
    useMutation,
    SelectInput,
} from "react-admin";
import { Form } from "react-final-form";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { reservationFormStyles } from "./ChapitreReservationForm";
import SimpleSaveButton from "./SimpleSaveButton";
import classnames from "classnames";
import InductionRequestListController from "../../controllers/InductionRequestListController";
import { identity } from "lodash";
import moment from "moment";
import PayButton from "./PayButton";
import { CUSTOM_POST } from "../../restProvider";
import PageListController from "../../controllers/PageListController";
import PageButton from "./PageButton";
import Typography from "@material-ui/core/Typography";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { CA_STATES, US_STATES } from "./States";
import PaysSelectInput from "./PaysSelectInput";

const useStyles = makeStyles((theme) => {
    const reservationStyles = reservationFormStyles(theme);
    return {
        root: {},
        otherCandidates: {
            position: "relative",
        },
        create: {},
        listTitle: {
            marginTop: theme.spacing(2),
        },
        table: {
            marginTop: theme.spacing(2),
            //width: "100%",
            borderCollapse: "collapse",
            "& th, & td": {
                paddingLeft: 0,
                paddingRight: theme.spacing(2),
            },
        },
        lastCell: {
            textAlign: "right",
        },
        ...reservationStyles,
        actions: {
            justifyContent: "flex-end",
        },
        formLine: {
            width: "480px",
            [theme.breakpoints.down("xs")]: {
                maxWidth: "100%",
                width: "auto",
            },
        },
        sizeTiers: {
            width: "170px",
            marginRight: "10px",
        },
        pays: {
            width: "170px",
            zIndex: 5000,
        },
        sizeSmall: {
            width: "110px",
            marginRight: "10px",
        },
        sizeSemi: {
            width: "230px",
            marginRight: "10px",
            [theme.breakpoints.down("xs")]: {
                maxWidth: "100%",
            },
        },
        sizeFull: {
            width: "470px",
            marginRight: "10px",
            [theme.breakpoints.down("xs")]: {
                maxWidth: "100%",
                width: "auto",
            },
        },
        flex: {
            display: "flex",
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
            },
        },
        center: {
            textAlign: "center",
        },
        submitButton: {
            marginTop: 2,
        },
        formOverlay: {
            padding: theme.spacing(1, 2),
            position: "absolute",
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: fade(theme.palette.background.default, 0.5),
            textAlign: "center",
            "& .MuiTypography-overline": {
                fontWeight: 700,
                lineHeight: 1.1,
            },
        },
    };
});

const PaiementChancellerieForm = ({
    classes,
    save,
    disabled,
    setSubmitted,
    location,
    ...props
}) => {
    const { saving } = props;
    const translate = useTranslate();
    const notify = useNotify();
    const [
        pay,
        { loading: payFormLoading, data: payForm, error, total },
    ] = useMutation();
    const submit = async (values, complete) => {
        try {
            const [{ data: form }, ...res] = await new Promise(
                (resolve, reject) => {
                    pay(
                        {
                            type: CUSTOM_POST,
                            resource: "inductionrequest/payment",
                            payload: {
                                data: {
                                    ...values,
                                    returnUrl: `/#${location.pathname}`,
                                },
                            },
                        },
                        {
                            onSuccess: (...args) => {
                                resolve(args);
                            },
                            onFailure: (error) => {
                                reject(error);
                            },
                        }
                    );
                }
            );
            setSubmitted(true);
            const elem = document.createElement("div");
            elem.innerHTML = form;
            const htmlForm = elem.firstChild;
            //  htmlForm.setAttribute("target", "_blank");
            document.body.appendChild(htmlForm);
            htmlForm.submit();
        } catch (error) {
            // const returnValue = error && error.errorsByField ? error.errorsByField : null;
            notify(error.message, "error");
            // défini les submitErrors du formulaire
            // On le fait pas, ça marche pas à cause des TextInput de react-admin qui supportent pas submitErrors
            // https://github.com/marmelab/react-admin/issues/4351
            // return returnValue;
        }
    };
    const [country, setCountry] = useState();
    return (
        <Form
            onSubmit={submit}
            render={({ handleSubmit, invalid, form, ...formProps }) => {
                return (
                    <form className={classes.form}>
                        <div className={classes.formLine}>
                            <div className={classes.flex}>
                                <TextInput
                                    validate={[required()]}
                                    disabled={disabled}
                                    source="lastName"
                                    className={classes.sizeSemi}
                                    placeholder={translate(
                                        "pages.paiementChancellerie.form.lastName"
                                    )}
                                    label={translate(
                                        "pages.paiementChancellerie.form.lastName"
                                    )}
                                    variant="outlined"
                                />
                                <TextInput
                                    validate={[required()]}
                                    disabled={disabled}
                                    className={classes.sizeSemi}
                                    source="firstName"
                                    placeholder={translate(
                                        "pages.paiementChancellerie.form.firstName"
                                    )}
                                    label={translate(
                                        "pages.paiementChancellerie.form.firstName"
                                    )}
                                    variant="outlined"
                                />
                            </div>
                            <div className={classes.formLine}>
                                <div className={classes.flex}>
                                    <TextInput
                                        name="addressLine1"
                                        placeholder={translate(
                                            "billing.addressLine1"
                                        )}
                                        label={translate(
                                            "billing.addressLine1"
                                        )}
                                        className={classes.sizeFull}
                                        variant="outlined"
                                        validate={required()}
                                    />
                                </div>
                            </div>
                            <div className={classes.formLine}>
                                <div className={classes.flex}>
                                    <div className={classes.sizeSemi}>
                                        <TextInput
                                            name="city"
                                            placeholder={translate(
                                                "billing.city"
                                            )}
                                            label={translate("billing.city")}
                                            className={classes.sizeSemi}
                                            variant="outlined"
                                            validate={required()}
                                        />
                                    </div>
                                    <div className={classes.sizeSemi}>
                                        <TextInput
                                            name="postalCode"
                                            placeholder={translate(
                                                "billing.postalCode"
                                            )}
                                            label={translate(
                                                "billing.postalCode"
                                            )}
                                            validate={required()}
                                            variant="outlined"
                                            className={classes.sizeSemi}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.formLine}>
                                <div className={classes.flex}>
                                    <PaysSelectInput
                                        name="country"
                                        source="country"
                                        label={translate("billing.country")}
                                        variant="outlined"
                                        className={classes.sizeSemi}
                                        validate={required()}
                                        onChange={(newVal) =>
                                            setCountry(newVal)
                                        }
                                    ></PaysSelectInput>
                                    {country === "USA" || country === "CDN" ? (
                                        <SelectInput
                                            name="stateOrProvince"
                                            variant="outlined"
                                            label={translate(
                                                "billing.stateOrProvince"
                                            )}
                                            className={`${classes.sizeSemi} ${classes.option}`}
                                            choices={
                                                country === "USA"
                                                    ? US_STATES
                                                    : country === "CDN"
                                                    ? CA_STATES
                                                    : []
                                            }
                                            disabled={
                                                !(
                                                    country === "USA" ||
                                                    country === "CDN"
                                                )
                                            }
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                            <div className={classes.formLine}>
                                <div className={classes.formLine}>
                                    <div className={classes.sizeTiers}>
                                        <SimpleSaveButton
                                            color="secondary"
                                            disabled={disabled}
                                            handleSubmitWithRedirect={(
                                                ...args
                                            ) => {
                                                return handleSubmit(...args);
                                            }}
                                            invalid={invalid}
                                            icon={null}
                                            saving={saving || payFormLoading}
                                            submitOnEnter={true}
                                            className={classes.submitButton}
                                            label={translate(
                                                "Payer {{amount}}€",
                                                {
                                                    amount: 350,
                                                }
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                );
            }}
        />
    );
};

const PaiementChancellerie = ({
    location,
    setSubmitted,
    disabled,
    ...props
}) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <div className={classes.root}>
            <InductionRequestListController filter={{ transaction: null }}>
                {({ loaded, error, data, total }) =>
                    loaded && data && data.length ? (
                        <>
                            <Typography
                                variant="h3"
                                gutterBottom
                                className={classes.listTitle}
                            >
                                {translate(
                                    "pages.paiementChancellerie.listCandidats.title"
                                )}
                            </Typography>
                            <table className={classes.table}>
                                <thead>
                                    <tr>
                                        <th>
                                            {translate(
                                                "pages.paiementChancellerie.listCandidats.nom.th"
                                            )}
                                        </th>
                                        <th>
                                            {translate(
                                                "pages.paiementChancellerie.listCandidats.createdAt.th"
                                            )}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((request, index) => (
                                        <tr key={index}>
                                            <td>
                                                {[
                                                    request.lastName,
                                                    request.firstName,
                                                ]
                                                    .filter(identity)
                                                    .join(" ")}
                                            </td>
                                            <td>
                                                {moment(
                                                    request.createdAt
                                                ).format("L")}
                                            </td>
                                            <td className={classes.lastCell}>
                                                <PayButton
                                                    type={CUSTOM_POST}
                                                    resource={
                                                        "inductionrequest/payment"
                                                    }
                                                    payload={{
                                                        data: {
                                                            id: request.id,
                                                            returnUrl: `/#${location.pathname}`,
                                                        },
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                >
                                                    {translate(
                                                        "Payer {{amount}}€",
                                                        {
                                                            amount: 350,
                                                        }
                                                    )}
                                                </PayButton>
                                            </td>
                                            {/* {JSON.stringify(request, null, 2)} */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <h3>
                                {translate(
                                    "pages.paiementChancellerie.otherCandidat.title"
                                )}
                            </h3>
                        </>
                    ) : null
                }
            </InductionRequestListController>
            <div className={classes.otherCandidates}>
                <Create
                    resource={"inductionrequest/payment"}
                    basePath={location.pathname}
                    component="div"
                    classes={{ main: classes.create }}
                    className={classes.root}
                >
                    <PaiementChancellerieForm
                        setSubmitted={setSubmitted}
                        classes={classes}
                        location={location}
                        disabled={disabled}
                    />
                </Create>

                {disabled ? (
                    <div className={classes.formOverlay}>
                        <Typography variant="overline" color="secondary">
                            {translate(
                                "pages.paiementChancellerie.restrictedForUsMembers"
                            )}
                        </Typography>
                    </div>
                ) : null}
            </div>
            <h3>{translate("pages.paiementChancellerie.footer.title")}</h3>
            <p>
                {translate("pages.paiementChancellerie.footer.text")}{" "}
                <PageButton identifier="intronisation">
                    {translate("pages.paiementChancellerie.footer.linkText")}
                </PageButton>
            </p>
        </div>
    );
};

export default PaiementChancellerie;
