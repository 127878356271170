import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { useTranslate } from "react-admin";
import ResetPasswordModal from "./ResetPasswordModal";

const ResetPasswordModalButton = ({ className, ...props }) => {
    const [open, setOpen] = useState(false);

    const translate = useTranslate();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                variant="outlined"
                className={className}
                onClick={handleClickOpen}
            >
                {translate("Modifier")}
            </Button>
            <ResetPasswordModal
                open={open}
                handleClose={handleClose}
            ></ResetPasswordModal>
        </>
    );
};

export default ResetPasswordModalButton;
