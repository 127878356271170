import React, { useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import PostsPrivateAreaController from "../../controllers/PostsPrivateAreaController";
import PostJournalList from "../components/PostJournalList";
import SectionHeader from "../components/SectionHeader";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SousCommanderiesController from "../../controllers/SousCommanderiesController";
import Page from "./Page";
import Grid from "@material-ui/core/Grid";
import useCurrentUser from "../../hooks/useCurrentUser";

export default ({
    match: {
        params: { id, slug },
    },
    props,
}) => {
    const translate = useTranslate();
    const user = useCurrentUser();

    const idSousCommanderie =
        id && id !== "0" && id !== 0 ? id : id === "0" || id === 0 ? 0 : -1;

    const [commanderieSelected, setCommanderieSelected] = useState(
        idSousCommanderie
    );
    const [filter, setFilter] = useState({});
    useEffect(() => {
        setFilter(
            commanderieSelected === -1 // tous
                ? {}
                : commanderieSelected
                ? {
                      commanderie: commanderieSelected,
                  }
                : { commanderie: { "{{Op.is}}": null } }
        );
    }, [commanderieSelected]);
    return (
        <Page>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <SectionHeader
                        title={<h1>{translate("pages.privatearea.title")}</h1>}
                    />
                </Grid>
                <PostsPrivateAreaController filter={filter}>
                    {({ loaded, data: posts }) => (
                        <PostJournalList
                            loaded={loaded}
                            posts={posts}
                            type="private"
                        />
                    )}
                </PostsPrivateAreaController>
            </Grid>
        </Page>
    );
};
