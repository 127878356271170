import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Divider from "@material-ui/core/Divider";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
    table: {},
    tableRowUp: {
        "& td": {
            position: "relative",
            top: -2,
            fontSize: "0.9em",
        },
    },
    cellTitle: {},
    cellTitleIndent: {
        paddingLeft: theme.spacing(1),
    },
    cellValue: {
        textAlign: "right",
        paddingLeft: theme.spacing(1),
    },
}));

/**
 *
 * @param {object} props
 * @param {object} props.data
 * @param {number} props.data.prixUnitaire
 * @param {number} props.data.nbPlaces
 * @param {number} props.data.nbPlacesConfirmees
 * @param {number} props.data.nbPlacesGratuites
 * @param {number} props.data.nbPlacesListeAttente
 * @param {number} props.data.montantTotalHT
 * @param {number} props.data.montantTVA
 * @param {number} props.data.montantTotal
 */
const ChapitreReservationPriceTable = ({ data, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();
    return data ? (
        <table className={classes.table}>
            <tbody>
                <tr>
                    <td className={classes.cellTitle}>
                        {translate("Prix unitaire (1 place)")}
                    </td>
                    <td className={classes.cellValue}>
                        {data.prixUnitaire}&nbsp;€
                    </td>
                </tr>
                {typeof data.nbPlacesConfirmees !== "undefined" &&
                data.nbPlacesConfirmees !== data.nbPlaces ? (
                    <tr>
                        <td className={classes.cellTitle}>
                            {translate("Nombre de places confirmées")}
                        </td>
                        <td className={classes.cellValue}>
                            {data.nbPlacesConfirmees}
                        </td>
                    </tr>
                ) : (
                    <tr>
                        <td className={classes.cellTitle}>
                            {translate("Nombre de places")}
                        </td>
                        <td className={classes.cellValue}>{data.nbPlaces}</td>
                    </tr>
                )}
                {data.nbPlacesFileAttente ? (
                    <tr>
                        <td className={classes.cellTitle}>
                            {translate("Sur liste d'attente")}
                        </td>
                        <td className={classes.cellValue}>
                            {data.nbPlacesFileAttente}
                        </td>
                    </tr>
                ) : null}
                {data.nbPlacesGratuites ? (
                    <tr className={classes.tableRowUp}>
                        <td
                            className={`${classes.cellTitle} ${classes.cellTitleIndent}`}
                        >
                            {translate("Dont places gratuites")}
                        </td>
                        <td className={classes.cellValue}>
                            {data.nbPlacesGratuites}
                        </td>
                    </tr>
                ) : null}
                <tr>
                    <td className={classes.cellTitle}>
                        {translate("Sous-total HT")}
                    </td>
                    <td className={classes.cellValue}>
                        {data.montantTotalHT}&nbsp;€
                    </td>
                </tr>
                <tr>
                    <td className={classes.cellTitle}>{translate("TVA")}</td>
                    <td className={classes.cellValue}>
                        {data.montantTVA}&nbsp;€
                    </td>
                </tr>
                <tr className={classes.separator}>
                    <td colSpan="2">
                        <Divider />
                    </td>
                </tr>
                <tr>
                    <th className={classes.cellTitle}>
                        {translate("Total TTC")}
                    </th>
                    <th className={classes.cellValue}>
                        {data.montantTotal}&nbsp;€
                    </th>
                </tr>
                {data.montantPaye ? (
                    <>
                        <tr>
                            <td className={classes.cellTitle}>
                                {translate("Payé")}
                            </td>
                            <td className={classes.cellValue}>
                                {data.montantPaye}&nbsp;€
                            </td>
                        </tr>
                        {typeof data.montantRestant !== "undefined" ? (
                            <tr>
                                <td className={classes.cellTitle}>
                                    {translate("À payer")}
                                </td>
                                <td className={classes.cellValue}>
                                    {data.montantRestant}&nbsp;€
                                </td>
                            </tr>
                        ) : null}
                    </>
                ) : null}
            </tbody>
        </table>
    ) : null;
};

export default ChapitreReservationPriceTable;
