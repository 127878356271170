import React from "react";
import PageHelper from "../helpers/PageHelper";
import ModelController from "./ModelController";
/**
 * @param {Object} props
 * @param {function({ data: PageHelper, loaded: Boolean, error: Error })} props.children
 */
const PageController = (props) => {
    return (
        <ModelController {...props} helperClass={PageHelper} resource="page" />
    );
};

export default PageController;
