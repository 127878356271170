import React from "react";
import PostJournalHelper from "../helpers/PostJournalHelper";
import ModelController from "./ModelController";
/**
 * @param {Object} props
 * @param {function({ data: PostJournalHelper, loaded: Boolean, error: Error })} props.children
 */
const PostJournalController = (props) => {
    return (
        <ModelController
            {...props}
            helperClass={PostJournalHelper}
            resource="postjournal"
        />
    );
};

export default PostJournalController;
