import React, { useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import PostsJournalController from "../../controllers/PostsJournalController";
import PostJournalList from "../components/PostJournalList";
import SectionHeader from "../components/SectionHeader";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SousCommanderiesController from "../../controllers/SousCommanderiesController";
import Page from "./Page";
import Grid from "@material-ui/core/Grid";
import useCurrentUser from "../../hooks/useCurrentUser";

export default ({
    match: {
        params: { id, slug },
    },
    props,
}) => {
    const translate = useTranslate();
    const user = useCurrentUser();

    const idSousCommanderie =
        id && id !== "0" && id !== 0 ? id : id === "0" || id === 0 ? 0 : -1;

    const [commanderieSelected, setCommanderieSelected] = useState(
        idSousCommanderie
    );
    const [filter, setFilter] = useState({});
    useEffect(() => {
        setFilter(
            commanderieSelected === -1 // tous
                ? {}
                : commanderieSelected
                ? {
                      commanderie: commanderieSelected,
                  }
                : { commanderie: { "{{Op.is}}": null } }
        );
    }, [commanderieSelected]);
    return (
        <Page>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <SectionHeader
                        title={<h1>{translate("pages.journal.title")}</h1>}
                        right={
                            <FormControl>
                                {user && (
                                    <SousCommanderiesController
                                        filter={{
                                            "N° Sous-commanderie": {
                                                "{{Op.in}}":
                                                    user.codesCommanderies,
                                            },
                                        }}
                                    >
                                        {({ data }) => (
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={commanderieSelected}
                                                onChange={(event) =>
                                                    setCommanderieSelected(
                                                        event.target.value
                                                    )
                                                }
                                            >
                                                <MenuItem value={-1}>
                                                    {translate(
                                                        "Tous les articles"
                                                    )}
                                                </MenuItem>
                                                <MenuItem value={0}>
                                                    {translate(
                                                        "Articles de la Confrérie"
                                                    )}
                                                </MenuItem>
                                                {data &&
                                                    data.map(
                                                        (
                                                            souscommanderie,
                                                            souscommanderieIndex
                                                        ) => (
                                                            <MenuItem
                                                                key={
                                                                    souscommanderieIndex
                                                                }
                                                                value={
                                                                    souscommanderie[
                                                                        "N° Sous-commanderie"
                                                                    ]
                                                                }
                                                            >
                                                                {translate(
                                                                    "Sous-commanderie de {{name}}",
                                                                    {
                                                                        name:
                                                                            souscommanderie.displayName,
                                                                    }
                                                                )}
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        )}
                                    </SousCommanderiesController>
                                )}
                            </FormControl>
                        }
                    />
                </Grid>
                <PostsJournalController filter={filter}>
                    {({ loaded, data: posts }) => (
                        <PostJournalList loaded={loaded} posts={posts} />
                    )}
                </PostsJournalController>
            </Grid>
        </Page>
    );
};
