import React, { useState, useEffect } from "react";
import moment from "moment";
import ChapitreHelper from "../helpers/ChapitreHelper";

import ModelListController from "./ModelListController";

const getResource = ({ participating }) =>
    participating ? "chapitreweb/participating" : "chapitreweb";
/**
 * @param {Object} props
 * @param {function({ data: ChapitreHelper[], loaded: Boolean })} props.children
 * @param {Date} props.minDate Filtrer les chapitres : après la date spécifiée.
 * @param {Number} props.page pagination
 * @param {Number} props.perPage pagination
 * @returns {{data:ChapitreHelper[], loaded:boolean}}
 */
const ChapitresController = ({
    participating,
    minDate = moment().startOf("day").toDate(),
    maxDate,
    filter,
    sort = {
        field: "datetime",
        order: "ASC",
    },
    ...props
}) => {
    const [filterState, setFilterState] = useState(filter);
    const [resource, setResource] = useState(getResource({ participating }));
    useEffect(() => {
        setResource(getResource({ participating }));
    }, [participating]);
    useEffect(() => {
        const _filter = { ...filter };
        if (minDate) {
            _filter.datetime = _filter.datetime || {};
            _filter.datetime["{{Op.gte}}"] = minDate;
        }
        if (maxDate) {
            _filter.datetime = _filter.datetime || {};
            _filter.datetime["{{Op.lt}}"] = maxDate;
        }
        setFilterState(_filter);
    }, [JSON.stringify(minDate), JSON.stringify(maxDate), filter]);
    return (
        <ModelListController
            {...props}
            sort={sort}
            filter={filterState}
            resource={resource}
            helperClass={ChapitreHelper}
        />
    );
};

export default ChapitresController;
