const getApiUrl = () => {
    let url = process.env.REACT_APP_API_URL;
    if (
        window &&
        window.location &&
        window.location.hostname &&
        url.indexOf("//localhost") !== -1 &&
        window.location.hostname !== "localhost"
    ) {
        url = url.replace("//localhost", `//${window.location.hostname}`);
    }
    return url;
};

export default getApiUrl;
