import React from "react";
import MembreController from "../../controllers/MembreController";
import ErrorView from "./ErrorView";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogActions from "@material-ui/core/DialogActions";
import MembreHelper from "../../helpers/MembreHelper";
import IconButton from "@material-ui/core/IconButton";
import GradeController from "../../controllers/GradeController";
import { useTranslate } from "react-admin";
import Slide from "@material-ui/core/Slide";

import CloseIcon from "@material-ui/icons/Close";
import MemberDetailsInfo from "./MemberDetailsInfo";
import MemberDetailsTitle from "./MemberDetailsTitle";
import MemberDetailsTitleSC from "./MemberDetailsTitleSC";
import SousCommanderieController from "../../controllers/SousCommanderieController";
import Typography from "@material-ui/core/Typography";
import { useRosterStyles } from "../pages/Roster";
import useWidth from "../../hooks/useWidth";
import { isWidthDown } from "@material-ui/core/withWidth";
import MemberDetailsCommittee from "./MemberDetailsCommittee";

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {},
    content: {
        boxSizing: "border-box",
        [theme.breakpoints.up("sm")]: {
            minWidth: 400,
        },
        minWidth: 300,
    },
    memberName: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1.5),
    },
    memberGrade: { marginTop: theme.spacing(0) },
    memberTitle: { marginBottom: theme.spacing(1) },
    closeButton: {
        float: "right",
        marginLeft: theme.spacing(1),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
/**
 *
 * @param {object} props
 * @param {MembreHelper} props.member
 */
const MemberDetailsModal = ({
    open,
    handleClose,
    member,
    sousCommanderie,
    ...props
}) => {
    const classes = useStyles();
    const rosterClasses = useRosterStyles();
    const translate = useTranslate();
    const width = useWidth();
    const isExtraSmall = isWidthDown("xs", width);
    return (
        <Dialog
            className={classes.dialog}
            TransitionComponent={Transition}
            fullScreen={isExtraSmall}
            open={open}
            onClose={handleClose}
            aria-labelledby="member-details-dialog-title"
            aria-describedby="member-details-dialog-description"
            maxWidth="md"
        >
            <DialogTitle
                id="member-details-dialog-title"
                className={classes.title}
                disableTypography={true}
            >
                <IconButton
                    className={classes.closeButton}
                    onClick={() => handleClose()}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
                <h2 className={classes.memberName}>
                    {member.hasNationalTitle ? (
                        <MemberDetailsTitle
                            membre={member}
                            className={classes.memberTitle}
                        />
                    ) : null}
                    {member.fullName}
                    <GradeController id={member.codeGrade}>
                        {({ loaded, error, data: grade }) => {
                            return !loaded ? null : grade ? (
                                <Typography
                                    variant="body1"
                                    component="div"
                                    className={classes.memberGrade}
                                >
                                    {member.dateIntronisation
                                        ? translate(
                                              "{{grade}} intronisé le {{date}}",
                                              {
                                                  grade:
                                                      grade.displayName ||
                                                      translate("Membre"),
                                                  date:
                                                      member.dateIntronisation,
                                              }
                                          )
                                        : grade.displayName ||
                                          translate("Membre")}
                                </Typography>
                            ) : null;
                        }}
                    </GradeController>
                    {member.hasCommittee ? (
                        <div className={rosterClasses.memberListItemLine}>
                            <MemberDetailsCommittee
                                component="span"
                                membre={member}
                            />
                        </div>
                    ) : null}
                    {member.sousCommanderies?.map(
                        (memberHasSousCommanderie) => (
                            <div
                                className={rosterClasses.memberListItemLine}
                                key={memberHasSousCommanderie.commanderie}
                            >
                                {memberHasSousCommanderie.commanderie ? (
                                    <SousCommanderieController
                                        id={
                                            memberHasSousCommanderie.commanderie
                                        }
                                    >
                                        {({
                                            loaded,
                                            error,
                                            data: sousCommanderie,
                                        }) =>
                                            sousCommanderie ? (
                                                <>
                                                    {sousCommanderie[
                                                        "Code commanderie"
                                                    ] &&
                                                    sousCommanderie[
                                                        "Code commanderie"
                                                    ] !==
                                                        member.codeCommanderie ? (
                                                        <SousCommanderieController
                                                            id={
                                                                sousCommanderie[
                                                                    "Code commanderie"
                                                                ]
                                                            }
                                                        >
                                                            {({
                                                                loaded,
                                                                error,
                                                                data: parentCommanderie,
                                                            }) =>
                                                                parentCommanderie ? (
                                                                    <Typography
                                                                        component="span"
                                                                        color="secondary"
                                                                    >
                                                                        {
                                                                            parentCommanderie.displayName
                                                                        }
                                                                    </Typography>
                                                                ) : null
                                                            }
                                                        </SousCommanderieController>
                                                    ) : null}
                                                    <Typography
                                                        component="span"
                                                        color="primary"
                                                    >
                                                        {
                                                            sousCommanderie.displayName
                                                        }
                                                    </Typography>
                                                </>
                                            ) : null
                                        }
                                    </SousCommanderieController>
                                ) : null}
                                {memberHasSousCommanderie.titles?.map(
                                    (title) => (
                                        <MemberDetailsTitleSC
                                            membre={member}
                                            title={title.CommanderieTitle.title}
                                            component="span"
                                        />
                                    )
                                )}
                            </div>
                        )
                    )}
                    {member.nonRosterCommanderiesWithNoSousCommanderieMembership?.map(
                        (memberHasSousCommanderie) => (
                            <div
                                className={rosterClasses.memberListItemLine}
                                key={memberHasSousCommanderie.commanderie}
                            >
                                {memberHasSousCommanderie.commanderie ? (
                                    <SousCommanderieController
                                        id={
                                            memberHasSousCommanderie.commanderie
                                        }
                                    >
                                        {({
                                            loaded,
                                            error,
                                            data: sousCommanderie,
                                        }) =>
                                            sousCommanderie ? (
                                                <Typography
                                                    component="span"
                                                    color="secondary"
                                                >
                                                    {
                                                        sousCommanderie.displayName
                                                    }
                                                </Typography>
                                            ) : null
                                        }
                                    </SousCommanderieController>
                                ) : null}
                                {memberHasSousCommanderie.titles?.map(
                                    (title) => (
                                        <MemberDetailsTitleSC
                                            membre={member}
                                            title={title.CommanderieTitle.title}
                                            component="span"
                                        />
                                    )
                                )}
                            </div>
                        )
                    )}
                </h2>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <MembreController
                    id={member["N° Membre"]}
                    details
                    renderError={(error) => <ErrorView error={error} />}
                >
                    {({ loaded, error, data: memberDetails }) => (
                        <div>
                            <MemberDetailsInfo member={memberDetails} />
                        </div>
                    )}
                </MembreController>
            </DialogContent>
            <DialogActions className={classes.dialogActions}></DialogActions>
        </Dialog>
    );
};

export default MemberDetailsModal;
