import ModelHelper from "./ModelHelper";
import getApiUrl from "../utils/getApiUrl";

const apiUrl = getApiUrl();

const MediaHelper = class extends ModelHelper {
    /**
     * @property {number}
     */
    width;
    /**
     * @property {number}
     */
    height;
    /**
     * @property {string} src
     * @returns {string}
     */
    get src() {
        return this._src ? `${apiUrl}/${this._src}` : null;
    }
    set src(val) {
        this._src = val;
    }
};

export default MediaHelper;
