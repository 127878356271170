import { useQueryWithStore } from "react-admin";
import SousCommanderieHelper from "../helpers/SousCommanderieHelper";
/**
 * @param {Object} props
 * @param {function({ data: SousCommanderieHelper, loaded: Boolean })} props.children
 */
const SousCommanderieController = ({ children, id }) => {
    const { loaded, error, data } = useQueryWithStore({
        type: "getOne",
        resource: "souscommanderie",
        payload: { id },
    });
    return children({ loaded, error, data: new SousCommanderieHelper(data) });
};

export default SousCommanderieController;
