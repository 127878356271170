import ModelHelper from "./ModelHelper";

const ChapitreHelper = class extends ModelHelper {
    /**
     * @property {string}
     */
    name;
    /**
     * @property {number}
     */
    poster;
    /**
     * @property {number}
     */
    poster_small;
    /**
     * @property {number}
     */
    nombrePlacesParMembreSansModeration;
    /**
     * @property {boolean}
     */
    reservationIsOpen;
    get i18nDataProperty() {
        return "ChapitreWeb_i18n";
    }
    get displayName() {
        return this.title || this.name;
    }
    get timezone() {
        return this._timezone || "Europe/Paris";
    }
    set timezone(value) {
        this._timezone = value;
    }
    get datetime() {
        return this._datetime;
    }
    set datetime(value) {
        this._datetime = value;
    }
};

export default ChapitreHelper;
