import React from "react";

const MediaImg = ({ media, sizeFactor = 1, ...props }) =>
    media ? (
        <img
            src={media.src}
            width={media.width * sizeFactor}
            height={media.height * sizeFactor}
            alt={media.alt}
            title={media.title}
            {...props}
        />
    ) : null;

export default MediaImg;
