import React from "react";
import RecaptchaDisclaimer from "../components/RecaptchaDisclaimer";
import Login from "./Login";
import { CredentialsRequestForm } from "./LoginForm";

const CredentialsRequestPage = (props) => {
    return (
        <Login
            {...props}
            deferChildrenAfterCheckAuth
            after={<RecaptchaDisclaimer variant="caption" />}
        >
            <CredentialsRequestForm />
        </Login>
    );
};
export default CredentialsRequestPage;
