import React from "react";
import { useTranslate } from "react-admin";
import Button from "@material-ui/core/Button";
import classnames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Hidden from "@material-ui/core/Hidden";

import Typography from "@material-ui/core/Typography";
import TastevinListController from "../../controllers/TastevinListController";
import MediaController from "../../controllers/MediaController";
import MediaBlock from "./MediaBlock";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 80,
        display: "flex",
        justifyContent: "space-between",

        minHeight: 150,
        color: "#fff",
        maxWidth: "100%",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            paddingTop: 40,
        },
    },
    figure: {
        width: 184,
        padding: 0,
        margin: 0,
        textAlign: "center",
        flexGrow: 1,
        flexShrink: 0,
        fontSize: 0,

        maxWidth: "100%",
        [theme.breakpoints.down("md")]: {
            marginBottom: theme.spacing(2),
        },
    },
    imgWrapper: {
        maxWidth: 120,
        width: "90%",
        margin: "auto",
    },
    col2: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexGrow: 1,
        paddingLeft: 0,
    },
    content: {
        paddingLeft: 10,
        paddingRight: 10,
    },
    h1: {
        ...theme.typography.h1,
        fontSize: 14,
    },
    h2: {
        ...theme.typography.h2,
        fontSize: 12,
    },
}));

const TastevinEnMain = ({ className, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <TastevinListController>
            {({ data, loaded, error }) => {
                const tastevin = data ? data[0] : null;
                return tastevin ? (
                    <aside className={classnames(classes.root, className)}>
                        {/* <pre>
                            {JSON.stringify({ data, loaded, error }, null, 2)}
                        </pre> */}
                        <figure className={classes.figure}>
                            <div className={classes.imgWrapper}>
                                <MediaController id={tastevin.image_small}>
                                    {({ loaded, media }) => {
                                        const ratio =
                                            media && media.height
                                                ? media.height / media.width
                                                : 340 / 240;
                                        return (
                                            <MediaBlock
                                                srcLoaded={loaded}
                                                src={media.src}
                                                title={tastevin.displayName}
                                                alt={tastevin.displayName}
                                                ratio={ratio}
                                                className={classes.mediaBlock}
                                            />
                                        );
                                    }}
                                </MediaController>
                            </div>
                        </figure>
                        <div className={classes.col2}>
                            <Hidden smDown>
                                <div className={classes.content}>
                                    <Typography variant="h2">
                                        {tastevin.title}
                                    </Typography>

                                    <Typography variant="h4">
                                        {tastevin.versionTitle}
                                    </Typography>
                                </div>
                            </Hidden>
                            {tastevin.file ? (
                                <MediaController id={tastevin.file}>
                                    {({ loaded, media }) => {
                                        return media && media.src ? (
                                            <Button
                                                component={"a"}
                                                href={media.src}
                                                target="_blank"
                                                variant="contained"
                                                color="secondary"
                                                startIcon={
                                                    <Hidden mdUp>
                                                        <OpenInNewIcon />
                                                    </Hidden>
                                                }
                                            >
                                                <Hidden smDown>
                                                    {tastevin?.buttonText ||
                                                        translate(
                                                            "Ouvrir_tastevin"
                                                        )}
                                                </Hidden>
                                            </Button>
                                        ) : null;
                                    }}
                                </MediaController>
                            ) : tastevin.externalLink ? (
                                <Button
                                    component={"a"}
                                    href={tastevin.externalLink}
                                    target="_blank"
                                    variant="contained"
                                    color="secondary"
                                    startIcon={
                                        <Hidden mdUp>
                                            <OpenInNewIcon />
                                        </Hidden>
                                    }
                                >
                                    <Hidden smDown>
                                        {tastevin?.buttonText ||
                                            translate("Ouvrir_tastevin")}
                                    </Hidden>
                                </Button>
                            ) : null}
                        </div>
                    </aside>
                ) : null;
            }}
        </TastevinListController>
    );
};

export default TastevinEnMain;
