import React from "react";
import ChapitreHelper from "../helpers/ChapitreHelper";
import ModelController from "./ModelController";
/**
 * @param {Object} props
 * @param {function({ data: ChapitreHelper, loaded: Boolean, error: Error })} props.children
 */
const ChapitreController = ({ resume = false, ...props }) => {
    return (
        <ModelController
            {...props}
            helperClass={ChapitreHelper}
            resource={resume ? "chapitreweb/resume" : "chapitreweb"}
        />
    );
};

export default ChapitreController;
