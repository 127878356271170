import React from "react";
import PrivateAreaHelper from "../helpers/PrivateAreaHelper";
import ModelController from "./ModelController";
/**
 * @param {Object} props
 * @param {function({ data: PrivateAreaHelper, loaded: Boolean, error: Error })} props.children
 */
const PostPrivateAreaController = (props) => {
    return (
        <ModelController
            {...props}
            helperClass={PrivateAreaHelper}
            resource="privatearea"
        />
    );
};

export default PostPrivateAreaController;
