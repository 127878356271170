import React, { useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import ChapitresController from "../../controllers/ChapitresController";
import ChapitresList from "../components/ChapitresList";
import SectionHeader from "../components/SectionHeader";
import FormControl from "@material-ui/core/FormControl";
import Page from "./Page";
import Grid from "@material-ui/core/Grid";

import SousCommanderieSelect from "../components/SousCommanderieSelect";

export default (props) => {
    const translate = useTranslate();
    const [commanderieSelected, setCommanderieSelected] = useState(-1);
    const [filter, setFilter] = useState({});
    useEffect(() => {
        setFilter(
            commanderieSelected === -1 // tous
                ? {}
                : commanderieSelected
                ? {
                      commanderie: commanderieSelected,
                  }
                : { commanderie: { "{{Op.is}}": null } }
        );
    }, [commanderieSelected]);
    return (
        <Page>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <SectionHeader
                        title={<h1>{translate("pages.chapitres.title")}</h1>}
                        right={
                            <FormControl>
                                <SousCommanderieSelect
                                    commanderieSelected={commanderieSelected}
                                    setCommanderieSelected={
                                        setCommanderieSelected
                                    }
                                />
                            </FormControl>
                        }
                    />
                </Grid>
                <ChapitresController filter={filter}>
                    {({ loaded, data: chapitres }) => (
                        <ChapitresList loaded={loaded} chapitres={chapitres} />
                    )}
                </ChapitresController>
            </Grid>
        </Page>
    );
};
