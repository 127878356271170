import React from "react";
import Login from "./Login";
import { ResetForm } from "./LoginForm";
import RecaptchaDisclaimer from "../components/RecaptchaDisclaimer";

const ResetPasswordPage = (props) => {
    const { match } = props;
    return (
        <Login
            {...props}
            deferChildrenAfterCheckAuth
            after={<RecaptchaDisclaimer variant="caption" />}
        >
            <ResetForm match={match} />
        </Login>
    );
};
export default ResetPasswordPage;
