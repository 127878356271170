/**
 * Traduire les erreurs.
 * @param {Error} error l'erreur
 * @param {Function} translate la fonction de traduction
 * @returns {String}
 */
const getErrorMessage = (error, translate) => {
    if (error.status === 404) {
        return translate(`Cet élément n'existe pas.`);
    }
    if (error.status === 429) {
        return translate(`roster.ratelimit`);
    }
    return error.message;
};

export default getErrorMessage;
