import React from "react";
import Login from "./Login";
import { ForgotForm } from "./LoginForm";
import RecaptchaDisclaimer from "../components/RecaptchaDisclaimer";

const ForgotPasswordPage = (props) => {
    return (
        <Login
            {...props}
            deferChildrenAfterCheckAuth
            after={<RecaptchaDisclaimer variant="caption" />}
        >
            <ForgotForm />
        </Login>
    );
};
export default ForgotPasswordPage;
