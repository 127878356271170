import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const Loader = ({
    loaded,
    data,
    error,
    children,
    renderError = (error) => (error ? error.message : null),
}) => (!loaded && !error ? <CircularProgress size={20} /> : renderError(error));

export default Loader;
