import React from "react";
import MediaController from "../../controllers/MediaController";
import PostJournalHelper from "../../helpers/PostJournalHelper";
import PublicationJournalInfo from "../components/PublicationJournalInfo";

import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Routes } from "../routes";
import { useTranslate } from "react-admin";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import MediaBlock from "./MediaBlock";

const useStyles = makeStyles((theme) => ({
    root: {
        textDecoration: "none",
        position: "relative",
        display: "block",
        overflow: "visible",
        background: "transparent",
    },
    link: {
        textDecoration: "none",
    },
    linkFull: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    contentArea: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1),
        "&:last-child": {
            paddingBottom: 0,
        },
        textAlign: "justify",
    },
    title: {
        display: "flex",
        alignItems: "flex-start",
        marginTop: theme.spacing(1),
    },
    titleText: {
        color: "#000000",
        fontSize: 20,
        paddingTop: theme.spacing(1),
    },
    date: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    media: { pointerEvents: "none" },
    cardActions: {},
}));

/**
 * @param {object} props
 * @param {PostJournalHelper} props.post
 * @returns {React.ReactElement}
 */

const PostJournalsListItem = ({ post, type }) => {
    const classes = useStyles();
    const postLink = post
        ? type === "private"
            ? generatePath(Routes.privateareaPost, {
                  id: post.id,
                  slug: post.displayName,
              })
            : generatePath(Routes.journalPost, {
                  id: post.id,
                  slug: post.displayName,
              })
        : "";
    const translate = useTranslate();

    return post ? (
        <Card className={classes.root} elevation={0}>
            <CardActionArea>
                <Link className={classes.linkFull} to={postLink}></Link>
                <Typography className={classes.title} variant="h2">
                    <span className={classes.titleText}>
                        {post.displayName}
                    </span>
                </Typography>
                <MediaController id={post.poster_small}>
                    {({ loaded, media }) => (
                        <MediaBlock
                            srcLoaded={loaded}
                            src={media.src}
                            title={post.displayName}
                            alt={post.displayName}
                            ratio={0.65}
                            className={classes.media}
                        />
                    )}
                </MediaController>
                <CardContent className={classes.contentArea}>
                    <Typography component="div">
                        <PublicationJournalInfo
                            type={type}
                            post={post}
                        ></PublicationJournalInfo>
                    </Typography>
                    <Typography>
                        {post.shortDescription ? (
                            <>{post.shortDescription}</>
                        ) : null}
                    </Typography>
                </CardContent>
            </CardActionArea>

            <CardActions className={classes.cardActions}>
                <Link className={classes.link} to={postLink}>
                    <Button variant="outlined">{translate("LIRE")}</Button>
                </Link>
            </CardActions>
        </Card>
    ) : null;
};

export default PostJournalsListItem;
