import React, { useState } from "react";
import useCurrentUser from "../../hooks/useCurrentUser";

import { usePermissions } from "react-admin";
import MemberDetailsSharingConsentDialog from "./MemberDetailsSharingConsentDialog";

const MemberDetailsSharingConsent = (props) => {
    const permissions = usePermissions();
    const user = useCurrentUser();
    const [open, setOpen] = useState(false);
    const [hasOpen, setHasOpen] = useState(false);
    return user?.memberNumber && permissions?.permissions?.rosterAccess ? (
        <MemberDetailsSharingConsentDialog
            onPreferencesSet={(prefs) => {
                if (prefs !== false && !prefs && !hasOpen) {
                    setOpen(true);
                    setHasOpen(true);
                }
            }}
            memberNumber={user.memberNumber}
            codeCommanderie={permissions.permissions.rosterAccess}
            open={open}
            handleClose={() => setOpen(false)}
            onSuccess={() => setOpen(false)}
        />
    ) : null;
};

export default MemberDetailsSharingConsent;
