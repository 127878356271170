import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import SousCommanderieController from "../../controllers/SousCommanderieController";
import { generatePath } from "react-router";
import { Routes } from "../routes";
import DateJournal from "../components/DateJournal";
import moment from "moment";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "inline-block",
        margin: 0,
    },
    sousCommanderie: {
        zIndex: 2,
        position: "relative",
        color: theme.palette.primary.main,
        fontSize: "inherit",
    },
}));
const PublicationJournalInfo = ({ className, post, type, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <p className={classes.root}>
            {type === "private" ? (
                ""
            ) : (
                <>
                    {translate("Publié dans ", {
                        datetime: moment(post.datetime).toDate(),
                    })}
                    {post.commanderie ? (
                        <SousCommanderieController
                            component
                            id={post.commanderie}
                        >
                            {({ loaded, data }) =>
                                loaded ? (
                                    <>
                                        <Link
                                            onClick={(event) =>
                                                event.stopPropagation()
                                            }
                                            to={generatePath(
                                                Routes.journalSousCommanderie,
                                                {
                                                    id: post.commanderie,
                                                    slug: post.displayName,
                                                }
                                            )}
                                            className={classes.sousCommanderie}
                                        >
                                            {data.displayName}
                                        </Link>
                                    </>
                                ) : null
                            }
                        </SousCommanderieController>
                    ) : (
                        <Link
                            onClick={(event) => event.stopPropagation()}
                            to={generatePath(Routes.journalSousCommanderie, {
                                id: 0,
                                slug: "Confrérie",
                            })}
                            className={classes.sousCommanderie}
                        >
                            {translate("Confrérie")}
                        </Link>
                    )}
                </>
            )}

            <DateJournal className={classes.date} post={post}></DateJournal>
        </p>
    );
};

export default PublicationJournalInfo;
