import moment from "moment-timezone";
import React from "react";
import { DateInput } from "react-admin";

const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
const convertDateToString = (value, timezone) =>
    timezone
        ? moment(value).tz(timezone).format("YYYY-MM-DD")
        : moment(value).format("YYYY-MM-DD");
export default React.forwardRef(({ timezone, helperText, ...props }, ref) => {
    /**
     * format : valeur base de données -> valeur input
     * @param {Date} value valeur
     */
    const dateFormatter = (value) => {
        if (value == null || value === "") {
            return "";
        }

        if (value instanceof Date) {
            return convertDateToString(value, timezone);
        }

        // valid dates should not be converted
        if (dateRegex.test(value)) {
            return value;
        }

        return convertDateToString(value, timezone);
    };
    const _helperText =
        helperText === false
            ? false
            : `${helperText || ""}${helperText && timezone ? " - " : ""}${
                  timezone || ""
              }`;
    return (
        <DateInput
            ref={ref}
            format={dateFormatter}
            helperText={_helperText}
            {...props}
        />
    );
});
