import React from "react";
import SousCommanderieHelper from "../helpers/SousCommanderieHelper";

import ModelListController from "./ModelListController";

/**
 * @param {Object} props
 * @param {function({ data: SousCommanderieHelper[], loaded: Boolean, error:Error })} props.children
 * @param {Number} props.page pagination
 * @param {Number} props.perPage pagination
 * @returns {{data:SousCommanderieHelper[], loaded:boolean, error:Error}}
 */
const SousCommanderiesController = (props) => {
    return (
        <ModelListController
            {...props}
            resource="souscommanderie"
            helperClass={SousCommanderieHelper}
        />
    );
};

export default SousCommanderiesController;
