import React from "react";
import JaugeController from "../../controllers/JaugeController";
import { useTranslate } from "react-admin";
import makeStyles from "@material-ui/core/styles/makeStyles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
    icon: {
        verticalAlign: "top",
        marginRight: theme.spacing(0.5), // Espacement entre l'icône et le texte
        fontSize: 18,
    },
}));
const ChapitreReservationJaugeMessage = ({
    chapitre,
    limite_message_guichet_ouvert,
    ...props
}) => {
    const translate = useTranslate();

    const classes = useStyles();
    return (
        <JaugeController id={chapitre.id}>
            {({ loaded, error, data }) =>
                loaded && !error && data && data.showMessage ? (
                    <>
                        <InfoOutlinedIcon className={classes.icon} />
                        {translate(
                            "Il reste au moins {{count}} places disponibles en guichet ouvert",
                            {
                                count: limite_message_guichet_ouvert,
                            }
                        )}
                    </>
                ) : null
            }
        </JaugeController>
    );
};

export default ChapitreReservationJaugeMessage;
