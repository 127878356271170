import { useQueryWithStore } from "react-admin";
import AttachedDocumentHelper from "../helpers/AttachedDocumentHelper";
import MediaHelper from "../helpers/MediaHelper";
/**
 * @param {object} props
 * @param {function({media: AttachedDocumentHelper, loaded: boolean})} props.children
 * @returns {any}
 */
const AttachedDocumentsController = ({
    type,
    postJournal_id,
    language_id,
    children,
}) => {
    const { loaded, error, data } = useQueryWithStore({
        type: "getList",
        resource: "attachedDocuments",
        payload: {
            filter: {
                type: type,
                postJournal_id: type === "private" ? null : postJournal_id,
                privatearea_Id: type === "private" ? postJournal_id : null,
                language_id,
            },
            pagination: { page: 1, perPage: 100 },
        },
    });
    return children({
        loaded,
        error,
        docs:
            loaded && data
                ? data.map((doc) => new AttachedDocumentHelper(doc))
                : [],
    });
};
export default AttachedDocumentsController;
