import { usePermissions } from "react-admin";
import UserHelper from "../helpers/UserHelper";

/**
 * @return UserHelper
 */
const useCurrentUser = () => {
    const permissions = usePermissions();
    return permissions &&
        permissions.permissions &&
        permissions.permissions.user
        ? new UserHelper(permissions.permissions.user)
        : null;
};

export default useCurrentUser;
