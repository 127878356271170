import useCurrentUser from "../hooks/useCurrentUser";

/**
 * CF cette jsdoc comme exemple
 * @param {Object} props
 * @param {function(user:UserHelper)} props.children
 */
const CurrentUserController = ({ children }) => {
    const user = useCurrentUser();
    return user ? children(user) : null;
};

export default CurrentUserController;
