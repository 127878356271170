import React from "react";
import { useTranslate } from "react-admin";
import { PropTypes } from "prop-types";
import Check from "@material-ui/icons/Check";
import PaymentIcon from "@material-ui/icons/Payment";
import NotInterested from "@material-ui/icons/NotInterested";
import Schedule from "@material-ui/icons/Schedule";
import Help from "@material-ui/icons/Help";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classnames from "classnames";
import PendingActions from "../../icons/PendingActions";
import Alert from "../../components/Alert";

const ChapitreReservationStatusIcon = ({ reservation, ...props }) => {
    if (!reservation) {
        return null;
    }
    let Icon;
    switch (reservation.validationStatus) {
        case "accepted":
            Icon = Check;

            if (reservation.canBePaid) Icon = PaymentIcon;
            break;
        case "refused":
            Icon = NotInterested;
            break;
        case "request":
            Icon = Schedule;
            break;
        case "waiting_list":
            Icon = PendingActions;
            break;
        case "partially_accepted":
            Icon = Check;
            break;

        default:
            Icon = Help;
            break;
    }
    return <Icon {...props} />;
};

const ChapitreReservationStatusPanel = ({
    reservation,
    textVariant,
    children,
    ...props
}) => {
    if (!reservation) {
        return null;
    }
    return (
        <span {...props}>
            <ChapitreReservationStatusIcon reservation={reservation} />{" "}
            <ChapitreReservationStatus
                textVariant={textVariant}
                reservation={reservation}
            />
        </span>
    );
};

const useChapitreReservationStatusBadgeStyles = makeStyles((theme) => ({
    badge: {
        display: "flex",
        alignItems: "center",
        ...theme.typography.button,
        textTransform: "none",
        color: ({ validationStatus }) => {
            switch (validationStatus) {
                case "canBePaid":
                    return theme.palette.primary.main;
                case "accepted":
                    return theme.palette.success.main;
                case "refused":
                    return theme.palette.secondary.main;
                case "request":
                    return theme.palette.primary.main;
                case "waiting_list":
                    return theme.palette.primary.main;
                default:
                    return theme.palette.common.black;
            }
        },
        /*color: ({ validationStatus }) => {
            switch (validationStatus) {
                case "accepted":
                    return theme.palette.success.contrastText;
                case "refused":
                    return theme.palette.secondary.contrastText;
                case "request":
                    return theme.palette.primary.contrastText;
                default:
                    return theme.palette.common.black;
            }
        },*/
    },
    floating: {
        position: "absolute",
        right: theme.spacing(-1.5),
        top: theme.spacing(-1),
        //border: "1px solid",
        boxShadow: theme.shadows[3],
        background: theme.palette.background.paper,
        borderRadius: theme.spacing(2),
        padding: 2,
        "&$floating": {
            fontSize: 18,
        },
    },

    icon: {
        marginRight: ({ iconOnly, useAlert }) =>
            iconOnly || useAlert ? 0 : theme.spacing(1),
    },
}));
const getAlertSeverity = (reservation) => {
    if (!(reservation && reservation.validationStatus)) {
        return "info";
    }
    switch (reservation.validationStatus) {
        case "accepted":
            if (reservation.canBePaid) return "info";
            return "success";
        case "refused":
            return "error";
        case "request":
            return "info";
        case "waiting_list":
            return "warning";
        case "partially_accepted":
            return "info";

        default:
            return "info";
    }
};
const ChapitreReservationStatusBadge = ({
    reservation,
    textVariant = "tiny",
    iconOnly = false,
    children,
    className,
    floating = false,
    useAlert = false,
    ...props
}) => {
    if (!reservation) {
        return null;
    }
    const classes = useChapitreReservationStatusBadgeStyles({
        validationStatus:
            reservation.validationStatus === "accepted" && reservation.canBePaid
                ? "canBePaid"
                : reservation.validationStatus,
        iconOnly,
        useAlert,
    });
    const icon = (
        <ChapitreReservationStatusIcon
            reservation={reservation}
            fontSize="inherit"
            className={classes.icon}
        />
    );
    const text = (
        <ChapitreReservationStatus
            textVariant={textVariant}
            reservation={reservation}
        />
    );
    return useAlert ? (
        <Alert severity={getAlertSeverity(reservation)} icon={icon}>
            <span>{text}</span>
        </Alert>
    ) : (
        <span
            {...props}
            className={classnames(
                classes.badge,
                className,
                floating ? classes.floating : null
            )}
        >
            {icon}
            {iconOnly ? null : <span>{text}</span>}
        </span>
    );
};

const ChapitreReservationStatus = ({ reservation, textVariant }) => {
    const translate = useTranslate();
    if (!reservation) {
        return null;
    }

    let status = reservation.validationStatus;
    if (reservation.alreadyPaid) status = "already_paid";
    if (reservation.canBePaid) status = "canbepaid";
    const text = translate(`reservation.status.${status}.${textVariant}`);
    return text;
};

ChapitreReservationStatus.propTypes = {
    textVariant: PropTypes.oneOf(["short", "long", "tiny"]),
};
ChapitreReservationStatus.defaultProps = {
    textVariant: "short",
};

export {
    ChapitreReservationStatusPanel,
    ChapitreReservationStatusBadge,
    ChapitreReservationStatusIcon,
};

export default ChapitreReservationStatus;
