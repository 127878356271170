import ModelHelper from "./ModelHelper";

export default class PageHelper extends ModelHelper {
    /**
     * @property {string}
     */
    title;
    /**
     * @property {string}
     */
    slug;
    get i18nDataProperty() {
        return "Page_i18n";
    }
    set menuTitle(val) {
        this._menuTitle = val;
    }
    get menuTitle() {
        return this._menuTitle || this.title;
    }
}
