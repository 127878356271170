import React, { useEffect } from "react";
import { useQueryWithStore, useLocale } from "react-admin";
import ModelHelper from "../helpers/ModelHelper";
import Loader from "../front/components/Loader";
/**
 * @param {Object} props
 * @param {function({ data: ModelHelper, loaded: Boolean, error: Error })} props.children
 * @param {string} props.resource Le nom de la resource à charger
 * @param {string} props.id L'identifiant de la resource à charger
 * @param {Function} props.helperClass La classe à instancier pour chaque entité
 * @param {boolean} props.withLoader Afficher un loader pendant le chargement. Par défaut : true
 */
const ModelController = ({
    children = () => null,
    id,
    resource,
    withLoader = true,
    renderError,
    onLoaded = () => null,
    helperClass = ModelHelper,
}) => {
    const locale = useLocale();
    const { loaded, error, data } = useQueryWithStore({
        type: "getOne",
        resource: resource,
        payload: { id },
    });
    useEffect(() => {
        let detached = false;
        if (loaded && !error && !detached) {
            onLoaded(new helperClass(data, locale));
        }
        return () => {
            detached = true;
        };
    }, [loaded, error, data, locale]);
    return withLoader && id && (!loaded || error) ? (
        <Loader error={error} renderError={renderError} />
    ) : (
        children({
            loaded,
            error,
            data: new helperClass(data, locale),
        })
    );
};

export default ModelController;
