import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import HtmlToReact from "html-to-react";

import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        "& p": {
            textAlign: "justify",
        },
        "& .ql-align-left": {
            textAlign: "left",
        },
        "& .ql-align-right": {
            textAlign: "right",
        },
        "& .ql-align-center": {
            textAlign: "center",
        },
        "& .ql-align-justify": {
            textAlign: "justify",
        },
        "& img": {
            maxWidth: "100%",
            height: "auto",
        },
    },
    clear: {
        clear: "both",
    },
}));
const parser = new HtmlToReact.Parser();

const isValidNode = () => true;
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
const processingInstructions = [
    {
        // Custom <h1> processing
        shouldProcessNode: function (node, ...args) {
            const classNameIndex = node?.attribs?.class?.indexOf(
                "ql-mui-button-true"
            );
            return classNameIndex !== undefined && classNameIndex !== -1;
        },
        processNode: function (node, children, ...args) {
            const { ...props } = node.attribs;
            delete props.class;
            return (
                <Button {...props} component={node.name} variant="outlined">
                    {children}
                </Button>
            );
        },
    },
    {
        // Anything else
        shouldProcessNode: function (node) {
            return true;
        },
        processNode: processNodeDefinitions.processDefaultNode,
    },
];
const QuillContent = ({
    content,
    className,
    contentClassName,
    noClear = false,
}) => {
    const classes = useStyles();
    const parsedContent = parser.parseWithInstructions(
        content,
        isValidNode,
        processingInstructions
    );
    return (
        <div className={classnames(className, classes.root)}>
            <div className={classnames(contentClassName, classes.content)}>
                {parsedContent}
            </div>
            {noClear ? null : <div className={classes.clear} />}
        </div>
    );
};
export default QuillContent;
