import React from "react";
import { useTranslate, useLocale } from "react-admin";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
    },
}));

const CookieSettings = ({
    hideText = false,
    buttonProps = null,
    className = null,
    ...props
}) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles({ hideText });
    return (
        <Button
            size="small"
            color="primary"
            onClick={() => {
                try {
                    /* eslint-disable-next-line no-undef */
                    openAxeptioCookies();
                } catch (err) {
                    console.log(err);
                }
            }}
        >
            {translate("cookie.settings")}
        </Button>
    );
};

export default CookieSettings;
