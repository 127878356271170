import apiI18nLocales from "../i18n/apiI18nLocales";
import I18nHelper from "./I18nHelper";

const ModelHelper = class {
    constructor(data = {}, locale = apiI18nLocales.fallback) {
        this.locale = locale;
        Object.assign(this, data);
        // On affecte d'abord les autres locales
        apiI18nLocales.locales.forEach((_locale) => {
            if (_locale !== locale) {
                Object.assign(this, this.i18n(_locale));
            }
        });
        // Puis on écrase avec la locale en cours
        Object.assign(this, this.i18n(locale));
    }
    /**
     * @returns {string} Le nom de la propriété qui détient le contenu internationnalisé
     */
    get i18nDataProperty() {
        return null;
    }
    get i18n() {
        if (!this.i18nDataProperty) {
            return () => {};
        }
        if (!this._i18n) {
            this._i18n = I18nHelper(this, this.i18nDataProperty);
        }
        return this._i18n;
    }
};

export default ModelHelper;
