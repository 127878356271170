import ModelHelper from "./ModelHelper";
import {
    upperCaseFirstLetter,
    lowerCaseAllWordsExceptFirstLetters,
} from "../utils/caseUtils";
export default class SousCommanderieHelper extends ModelHelper {
    /**
     * @property {string}
     */
    Type;
    get displayName() {
        return this["Intitulé"] ? this["Intitulé"] : "";
    }
    get isSousCommanderie() {
        return this.Type === "Sous-commanderie";
    }
}
