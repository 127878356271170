import React, { useState, useEffect } from "react";
import moment from "moment";
import InductionRequestHelper from "../helpers/InductionRequestHelper";

import ModelListController from "./ModelListController";

/**
 * @param {Object} props
 * @param {function({ data: InductionRequestHelper[], loaded: Boolean })} props.children
 * @param {Date} props.minDate Filtrer les chapitres : après la date spécifiée.
 * @param {Number} props.page pagination
 * @param {Number} props.perPage pagination
 * @returns {{data:InductionRequestHelper[], loaded:boolean}}
 */
const InductionRequestListController = (props) => {
    return (
        <ModelListController
            {...props}
            resource="inductionrequest"
            helperClass={InductionRequestHelper}
        />
    );
};

export default InductionRequestListController;
