import React from "react";
import ReservationWebHelper from "../helpers/ReservationWebHelper";
import ModelController from "./ModelController";
/**
 * @param {Object} props
 * @param {function({ data: ReservationWebHelper, loaded: Boolean, error: Error })} props.children
 */
const ReservationWebController = (props) => {
    return (
        <ModelController
            {...props}
            helperClass={ReservationWebHelper}
            resource="reservationweb"
        />
    );
};

export default ReservationWebController;
