import React, { useState } from "react";
import { Collapse } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.primary.dark,
        borderBottom: "1px solid rgba(255,255,255,0.2)",
    },
    child: {
        ...theme.typography.h6,
        color: "white",
    },
    subMenu: {
        "& a": {
            ...theme.typography.h6,
            border: "none",
            padding: theme.spacing(1, 2, 1, 5),
        },
        "& li:last-child": {
            marginBottom: theme.spacing(2),
        },
    },
}));

const MenuItem = ({
    children,
    subMenu,
    className,
    startOpen = false,
    ...props
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(startOpen);
    const onClick = () => {
        setOpen(!open);
    };
    return (
        <li {...props} className={classnames(classes.root, className)}>
            {React.Children.map(children, (child) =>
                React.cloneElement(child, {
                    onClick,
                    className: classnames(classes.child, child.props.className),
                })
            )}
            <Collapse in={open} className={classes.subMenu}>
                {subMenu}
            </Collapse>
        </li>
    );
};

export default MenuItem;
