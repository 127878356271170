import ModelHelper from "./ModelHelper";

export default class GradeHelper extends ModelHelper {
    get displayName() {
        return this["Grade"];
    }
    /**
     * @returns {{width: number, height: number, srcUrl: string, alt: string}}
     
     * @todo retourner un objet
     */
    get medailleImgInfo() {
        /**
         * 0	SANS GRADE	null	null
         * 1	Chevalier	CHEVALIER	CH
         * 2	Commandeur	COMMANDEUR	CR
         * 3	Officier Commandeur	OFFICIER-COMMANDEUR	OC
         * 4	Grand Officier	GRAND OFFICIER	GO
         * 5	Non Membre	null	null
         * 6	null
         */

        let src = null;
        let height = 0;
        let width = 0;

        switch (this["id"]) {
            case 1:
                src = "/images/grades/Chevalier du Tastevin.png";
                height = 550;
                width = 230;
                break;
            case 2:
                src = "/images/grades/Commandeur du Tastevin.png";
                height = 550;
                width = 286;
                break;
            case 3:
            case 4:
                src = "/images/grades/Officier Commandeur.png";
                height = 550;
                width = 226;
                break;
            default:
                src = null;
                break;
        }

        return {
            width: width / 2,
            height: height / 2,
            src,
            alt: this.displayName,
        };
    }
}
