import React from "react";
import Button from "@material-ui/core/Button";
import classnames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 100,

        textAlign: "center",
        background: "transparent",
        paddingTop: 0,
        color: "#fff",
        borderBottom: "1px solid rgba(0,0,0,0.2)",
        borderRadius: "0",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
            minHeight: 40,
            height: 40,
            paddingBottom: 0,
            borderBottom: 0,
        },
    },
    icon: {
        backgroundImage: "url('/images/dessin.png')",
        backgroundSize: "32px 32px",
        backgroundRepeat: "no-repeat",
        width: "100%",
        backgroundPosition: "center",
        minHeight: 32,
        minWidth: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    img: {
        width: 12,
        height: 12,
    },
    label: {
        flexDirection: "column",
    },
}));

const sanitizeButtonProps = ({ navigate, ...props }) => props;

const RoundButton = ({ className, srcUrl, label, ...props }) => {
    const classes = useStyles();
    const buttonProps = sanitizeButtonProps(props);

    return (
        <Button
            classes={{
                label: classes.label, // class name, e.g. `classes-nesting-label-x`
            }}
            className={classnames(classes.root, className)}
            {...buttonProps}
        >
            <span className={classes.icon}>
                <img src={srcUrl} alt={label} className={classes.img} />
            </span>
            {label}
        </Button>
    );
};

export default RoundButton;
