import React from "react";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1, 0),
        marginTop: "auto",
        padding: theme.spacing(2, 2, 0, 2),
        textAlign: "center",
        display: "block",
        whiteSpace: "normal",
        color: fade(theme.palette.primary.contrastText, 0.5),
        fontSize: "smaller",
        "& a": {
            color: "inherit",
        },
    },
}));

const RecaptchaDisclaimer = ({ className, props }) => {
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <Typography className={clsx(classes.root, className)} {...props}>
            {translate("recaptcha.disclaimer.text")}{" "}
            <a href="https://policies.google.com/privacy">
                {translate("recaptcha.disclaimer.privacy")}
            </a>{" "}
            {translate("recaptcha.disclaimer.and")}{" "}
            <a href="https://policies.google.com/terms">
                {translate("recaptcha.disclaimer.terms")}
            </a>{" "}
            {translate("recaptcha.disclaimer.apply")}.
        </Typography>
    );
};

export default RecaptchaDisclaimer;
