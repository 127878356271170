import React, { useState, useEffect, forwardRef } from "react";
import { useTranslate, useNotify, useMutation } from "react-admin";
import Button from "@material-ui/core/Button";
import { CUSTOM_GET, CUSTOM_POST } from "../../restProvider";

const PayButton = forwardRef(
    (
        {
            id,
            disabled,
            resource,
            payload,
            addressInfo,
            children,
            type = CUSTOM_GET,
            ...props
        },
        ref
    ) => {
        const translate = useTranslate();
        const notify = useNotify();
        const [pay, { loading, data: form }] = useMutation(
            {
                type,
                resource,
                payload: {
                    data: { addressInfo: addressInfo }, // include addressInfo in the body of the request
                },
            },
            {
                onSuccess: ({ data }) => {},
                onFailure: (error) => {
                    console.error(error);
                    notify(error.message, "warning");
                },
            }
        );

        const [submitted, setSubmitted] = useState(false);

        useEffect(() => {
            let dismounted = false;
            if (form && !dismounted && !submitted) {
                try {
                    const elem = document.createElement("div");
                    elem.innerHTML = form;
                    const htmlForm = elem.firstChild;
                    //  htmlForm.setAttribute("target", "_blank");
                    document.body.appendChild(htmlForm);
                    htmlForm.submit();
                    setSubmitted(true);
                } catch (e) {
                    console.error(e);
                    setSubmitted(false);
                }
            }
            return () => {
                dismounted = true;
            };
        }, [form, submitted]);
        return (
            <Button
                ref={ref}
                variant="contained"
                color="secondary"
                onClick={pay}
                disabled={disabled || loading || submitted}
                {...props}
            >
                {children}
            </Button>
        );
    }
);

export default PayButton;
