import React, { useState } from "react";
import Divider from "@material-ui/core/Divider";
import CCTFormLabel from "./CCTFormLabel";
import CCTNumberInput from "./CCTNumberInput";
import Typography from "@material-ui/core/Typography";
import _uniqueId from "lodash/uniqueId";
import { OnChange } from "react-final-form-listeners";
import arrayMutators from "final-form-arrays";
import {
    TextInput,
    required,
    minValue,
    FormWithRedirect,
    BooleanInput,
    FormDataConsumer,
    useTranslate,
} from "react-admin";
import { sanitizeEmptyValues, ArrayInput } from "react-admin";
import SimpleSaveButton from "./SimpleSaveButton";
import classnames from "classnames";
import ParticipantsInput from "../../components/ParticipantsInput";
import HotelInput from "../../components/HotelInput";
import ChapitreReservationEstimate from "./ChapitreReservationEstimate";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LinkPage from "./LinkPage";
import InfoIcon from "@material-ui/icons/Info";

const mobileViewBreakpoint = "sm";

const addReservataire = (values, currentUser, initialRecord) => {
    if (!(currentUser && values)) {
        return values;
    }
    const participants = [...(values.participants || [])];
    participants.unshift(
        initialRecord &&
            initialRecord.participants &&
            initialRecord.participants.length
            ? initialRecord.participants[0]
            : {
                  memberNumber: currentUser.memberNumber,
                  firstName: values.reservataireFirstName,
                  lastName: values.reservataireLastName,
                  type: "chevalier",
              }
    );
    return {
        ...values,
        participants,
    };
};

export const reservationFormStyles = (theme) => {
    return {
        formLine: {
            marginBottom: theme.spacing(1),
            "& .MuiFormControl-root": {
                [theme.breakpoints.down(mobileViewBreakpoint)]: {
                    marginBottom: theme.spacing(1),
                },
            },
            "& .MuiRadio-root": {
                paddingTop: 0,
                paddingBottom: 0,
            },
            "& .MuiFormHelperText-root": {
                position: "absolute",
                top: "100%",
                margin: 0,
                marginTop: 1,
                /*boxShadow: theme.shadows[2],
                background: theme.palette.background.paper,
                padding: theme.spacing(0.5, 1),*/
                ...theme.typography.caption,
                zIndex: 1,
                opacity: 0,
                visibility: "hidden",
                "&.Mui-error": {
                    opacity: 1,
                    visibility: "visible",
                },
                "&:before": {},
            },
        },
        formLineInner: {
            marginBottom: theme.spacing(1),
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
            "& > *:not($formLineBlock)": {
                marginRight: theme.spacing(2),
            },
        },
        formLineFlex: {
            display: "flex",
            flexWrap: "wrap",
            "&$formLineFlex": {
                width: "auto",
            },
            marginRight: theme.spacing(-2),
            "& > *": {
                flexGrow: 1,
            },
        },
        bottomSticky: {
            position: "sticky",
            bottom: 0,
            zIndex: 1,
        },
        responsiveBottomButton: {},
        responsiveBottomButtons: {
            background: theme.palette.background.paper,
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1 / 2),
            [theme.breakpoints.down(mobileViewBreakpoint)]: {
                paddingTop: theme.spacing(1 / 2),
            },
            borderTop: `1px solid ${theme.palette.divider}`,
            "& $formLineInner": {
                justifyContent: "flex-end",
                "& > *:last-child": {
                    marginRight: 0,
                },
            },
        },
        formLineFullWidth: {
            width: "100%",
        },
        formLineInnerWithBlock: {
            alignItems: "flex-start",
            flexWrap: "nowrap",
            [theme.breakpoints.down(mobileViewBreakpoint)]: {
                flexWrap: "wrap",
            },
            "& $formLabel": {
                marginTop: theme.spacing(2),
            },
        },
        dividerParticipants: {
            marginBottom: theme.spacing(4),
        },
        formLineReservataire: {
            marginBottom: theme.spacing(4),
            "& .MuiFormHelperText-root": {
                minWidth: 130,
            },
        },
        formLabel: {
            flexShrink: 0,
            width: 100,
            textAlign: "right",
            [theme.breakpoints.down(mobileViewBreakpoint)]: {
                width: "100%",
                textAlign: "left",
                marginBottom: theme.spacing(1),
            },
        },
        participantsInput: {
            marginBottom: 0,
            "& $formLabel": {
                // marginBottom: `calc(${theme.spacing(1)}px + 1em)`,
                // [theme.breakpoints.down(mobileViewBreakpoint)]: {
                //     marginBottom: 0,
                // },
            },
            [theme.breakpoints.down(mobileViewBreakpoint)]: {
                marginBottom: theme.spacing(1),
            },
        },

        label: {
            color: theme.palette.common.black,
            flexShrink: 0,
        },
        formLineTextValue: {
            marginBottom: 0,
        },
        formLineButton: {
            marginTop: 4,
        },
        formLineBlock: {
            "& > *:not($formLineBlock)": {
                marginRight: theme.spacing(2),
            },
        },
        formLineRight: {
            display: "flex",
            alignItems: "flex-end",
            width: "100%",
            flexDirection: "column",
        },
        formLineBlockInnerRight: {
            display: "flex",
            alignItems: "flex-end",
            textAlign: "right",
        },
        formLineBlockInlineCenter: {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
        },
        seatsInput: {
            flexShrink: 0,
            width: 80,
        },
        helpText: {
            flexShrink: 1,
            fontStyle: "italic",
        },
        linkpage: {
            fontFamily: '"Lato", serif',
            fontSize: 14,
            color: theme.palette.primary.dark,
        },
        radioGroup: {
            "& legend": { display: "none" },
        },
        commentInput: {},
        hotelInput: {
            minWidth: 200,
        },
    };
};

const useReservationFormStyles = makeStyles(reservationFormStyles);

const defaultParticipant = {
    type: "invite",
};

const ChapitreReservationForm = ({
    user,
    save,
    classes: overrideClasses,
    debugForm = false,
    isEdit = false,
    ...props
}) => {
    const { record, saving, chapitre, redirect } = props;
    const classesReservations = useReservationFormStyles();
    const classes = { ...overrideClasses, ...classesReservations };
    const translate = useTranslate();
    const [id] = useState(_uniqueId("reservation-"));
    const submit = (values, ...args) => {
        // need to add user to participants
        const parsedValues = addReservataire(values, user, record);
        const { ...sanitizedValues } = parsedValues;
        // React-final-form removes empty values from the form state.
        // To allow users to *delete* values, this must be taken into account
        save(sanitizeEmptyValues(record, sanitizedValues), redirect);
    };
    if (record) {
        if (
            record.participants &&
            record.participants.length > record.nbPlacesDemandees
        ) {
            record.nbPlacesDemandees = record.participants.length;
        }
    }
    const initialNbPlacesDemandees =
        record && record.nbPlacesDemandees
            ? record.nbPlacesDemandees
            : chapitre.nombrePlacesParMembreSansModeration
            ? chapitre.nombrePlacesInitial
            : 4;

    const initialParticipants =
        record && record.participants && record.participants.length
            ? (() => {
                  const participants = [...record.participants];
                  if (participants.length < initialNbPlacesDemandees) {
                      for (
                          let i = 0;
                          i < initialNbPlacesDemandees - participants.length;
                          i++
                      ) {
                          participants.push({ ...defaultParticipant });
                      }
                  }
                  participants.shift(); // on enlève le réservataire
                  return participants;
              })()
            : Array.from({
                  length:
                      initialNbPlacesDemandees -
                      1 /*on enlève le réservataire*/,
              }).map((el) => ({
                  ...defaultParticipant,
              }));
    const initialValues = {
        nbPlacesDemandees: initialNbPlacesDemandees,
        reservataireFirstName:
            user && user.member && user.member.Prénom
                ? user.member.Prénom
                : null,
        reservataireLastName:
            user && user.member && user.member["Nom propre"]
                ? user.member["Nom propre"]
                : null,
        ...record,
        participants: initialParticipants,
    };
    const handleNbPlacesDemandeesChange = (value, form) => {
        const participants = form.getFieldState("participants");
        const totalParticipantsCount = (participants.length || 0) + 1; // on ajouter le réservataire
        // sync participants array length with nbPlacesDemandees
        if (totalParticipantsCount === value) {
            return;
        } else {
            for (let i = 0; i < Math.abs(totalParticipantsCount - value); i++) {
                if (totalParticipantsCount < value) {
                    const originalIndex = totalParticipantsCount - 1 + i;
                    form.mutators.push(
                        "participants",
                        initialValues &&
                            initialValues.participants &&
                            initialValues.participants[originalIndex]
                            ? { ...initialValues.participants[originalIndex] }
                            : { ...defaultParticipant }
                    );
                } else {
                    form.mutators.pop("participants");
                }
            }
        }
    };
    const [acceptCGV, setAcceptCGV] = useState(false);
    const nbPlacesDemandeesValidation = [
        required(),
        minValue(
            1,
            translate("Veuillez réserver au moins {{min}} place", { min: 1 })
        ),
    ];
    const aLaConfrerieOuALaSousCommanderie = chapitre.commanderie
        ? translate("à la sous-commanderie")
        : translate("à la confrérie");
    return (
        <FormWithRedirect
            {...props}
            mutators={{
                ...arrayMutators,
                resetHotel: (args, state, utils) => {
                    utils.changeValue(state, "hotel", () => null);
                },
            }} // necessary for ArrayInput
            initialValues={initialValues}
            record={null}
            save={submit}
            render={({
                handleSubmitWithRedirect,
                invalid,
                form,
                ...formProps
            }) => {
                return (
                    <form>
                        <div
                            className={classnames(
                                classes.formLine,
                                classes.formLineReservataire
                            )}
                        >
                            <div className={classes.formLineInner}>
                                <CCTFormLabel
                                    htmlFor={`${id}-nbPlacesDemandees`}
                                >
                                    {translate("Nombre de places")}
                                </CCTFormLabel>
                                <CCTNumberInput
                                    validate={nbPlacesDemandeesValidation}
                                    source="nbPlacesDemandees"
                                    id={`${id}-nbPlacesDemandees`}
                                    size="small"
                                    label={""}
                                    inputProps={{
                                        min: 1,
                                        max: null,
                                        size: 2,
                                    }}
                                    variant="outlined"
                                    className={classes.seatsInput}
                                    options={{
                                        label: null,
                                    }}
                                />
                                <OnChange name="nbPlacesDemandees">
                                    {(value, previous) =>
                                        handleNbPlacesDemandeesChange(
                                            value,
                                            form
                                        )
                                    }
                                </OnChange>
                                <Typography
                                    variant="caption"
                                    className={classes.helpText}
                                >
                                    {chapitre.nombrePlacesParMembreSansModeration
                                        ? translate(
                                              "Au delà de {{count}} place par membre, votre demande sera transmise {{aLaConfrerieOuALaSousCommanderie}} pour validation",
                                              {
                                                  aLaConfrerieOuALaSousCommanderie: aLaConfrerieOuALaSousCommanderie,
                                                  count:
                                                      chapitre.nombrePlacesParMembreSansModeration,
                                              }
                                          )
                                        : translate(
                                              "Votre demande sera transmise {{aLaConfrerieOuALaSousCommanderie}} pour validation",
                                              {
                                                  aLaConfrerieOuALaSousCommanderie: aLaConfrerieOuALaSousCommanderie,
                                              }
                                          )}
                                </Typography>
                            </div>
                        </div>
                        <Divider className={classes.dividerParticipants} />
                        <div className={classes.formLine}>
                            <div className={classes.formLineInner}>
                                <CCTFormLabel
                                    className={classes.formLabel}
                                    htmlFor={`${id}-reservataireFirstName`}
                                >
                                    {translate("Réservataire")}
                                </CCTFormLabel>
                                <TextInput
                                    validate={[required()]}
                                    source="reservataireFirstName"
                                    id={`${id}-reservataireFirstName`}
                                    size="small"
                                    placeholder={translate("Prénom")}
                                    label={""}
                                    variant="outlined"
                                    options={{
                                        label: null,
                                    }}
                                />
                                <TextInput
                                    validate={[required()]}
                                    source="reservataireLastName"
                                    id={`${id}-reservataireLastName`}
                                    size="small"
                                    placeholder={translate("Nom")}
                                    label={""}
                                    variant="outlined"
                                    options={{
                                        label: null,
                                    }}
                                />
                            </div>
                        </div>
                        <ArrayInput source="participants" label={""}>
                            <ParticipantsInput classes={classes} />
                        </ArrayInput>
                        <TextInput
                            validate={[]}
                            multiline
                            source="preferencePlacement"
                            id={`${id}-preferencePlacement`}
                            size="small"
                            placeholder={translate(
                                "reservationweb.preferencePlacement.placeholder"
                            )}
                            label={<>{translate("preferencePlacement")}</>}
                            variant="outlined"
                            className={classes.commentInput}
                            fullWidth
                            helperText={translate(
                                "reservationweb.preferencePlacement.helperText"
                            )}
                            inputProps={{ rows: 2 }}
                        />
                        <TextInput
                            validate={[]}
                            multiline
                            source="comment"
                            id={`${id}-comment`}
                            size="small"
                            placeholder={translate(
                                "reservationweb.comment.placeholder"
                            )}
                            label={translate("Commentaire")}
                            variant="outlined"
                            className={classes.commentInput}
                            fullWidth
                            helperText={translate(
                                "reservationweb.comment.helperText"
                            )}
                            inputProps={{ rows: 2 }}
                        />
                        {chapitre.commanderie ? null : (
                            <div className={classes.formLine}>
                                <div className={classes.formLineInner}>
                                    <BooleanInput
                                        source="navette"
                                        label="reservationweb.navette.label"
                                    />
                                    <OnChange name="navette">
                                        {(value, previous) =>
                                            !value && form.mutators.resetHotel()
                                        }
                                    </OnChange>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) =>
                                            formData.navette ? (
                                                <HotelInput
                                                    {...rest}
                                                    className={
                                                        classes.hotelInput
                                                    }
                                                    label="reservationweb.hotel.label"
                                                    source="hotel"
                                                    emptyText="reservationweb.hotel.nohotel"
                                                />
                                            ) : null
                                        }
                                    </FormDataConsumer>
                                </div>
                            </div>
                        )}

                        <BooleanInput
                            source="needInvoice"
                            label="reservationweb.needInvoice.label"
                        />
                        {debugForm && (
                            <FormDataConsumer>
                                {({ formData }) => (
                                    <pre>
                                        {JSON.stringify(formData, null, 2)}
                                    </pre>
                                )}
                            </FormDataConsumer>
                        )}
                        <FormDataConsumer>
                            {({ formData, ...rest }) => (
                                <div className={classes.estimate}>
                                    <ChapitreReservationEstimate
                                        reservation={sanitizeEmptyValues(
                                            record,
                                            addReservataire(
                                                formData,
                                                user,
                                                record
                                            )
                                        )}
                                    />
                                </div>
                            )}
                        </FormDataConsumer>
                        <div className={`${classes.formLineRight}`}>
                            <div
                                className={`  ${classes.formLineBlockInnerRight}`}
                            >
                                <BooleanInput
                                    source="acceptCGV"
                                    label={
                                        <>
                                            {translate(
                                                "J'ai lu et j'accepte les "
                                            )}
                                            <LinkPage
                                                identifier="cgv"
                                                className={classes.linkpage}
                                            >
                                                <span
                                                    className={classes.linkpage}
                                                >
                                                    {translate(
                                                        "conditions générales de réservation"
                                                    )}
                                                    {"."}
                                                </span>
                                            </LinkPage>
                                        </>
                                    }
                                    validate={[required()]}
                                />

                                <OnChange name="acceptCGV">
                                    {(value, previous) => {
                                        setAcceptCGV(value);
                                    }}
                                </OnChange>
                            </div>
                        </div>
                        <div className={classes.actions}>
                            <SimpleSaveButton
                                color="secondary"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                disabled={invalid || !acceptCGV}
                                icon={null}
                                saving={saving}
                                submitOnEnter={true}
                                label={
                                    isEdit
                                        ? translate("Enregistrer")
                                        : translate("BoutonRéserver")
                                }
                            />
                        </div>
                    </form>
                );
            }}
        />
    );
};

export { useReservationFormStyles };

export default ChapitreReservationForm;
