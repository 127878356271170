import React from "react";
import VenueHelper from "../helpers/VenueHelper";
import ModelController from "./ModelController";
/**
 * @param {Object} props
 * @param {function({ data: VenueHelper, loaded: Boolean, error: Error })} props.children
 */
const VenueController = (props) => {
    return (
        <ModelController
            {...props}
            helperClass={VenueHelper}
            resource="venue"
        />
    );
};

export default VenueController;
