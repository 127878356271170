import React, { useState } from "react";
import APIPage from "./APIPage";
import { Field, Form } from "react-final-form";
import {
    useTranslate,
    useSafeSetState,
    useMutation,
    useNotify,
    SelectInput,
} from "react-admin";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PayButton from "../components/PayButton";
import { CUSTOM_POST } from "../../restProvider";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {},
    footer: {
        background: theme.palette.background.default,
        display: "none",
    },
    footerContent: {
        maxWidth: 500,
        boxSizing: "border-box",
        margin: "auto",
        padding: theme.spacing(6, 2, 10),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(8, 4, 12),
        },
    },
    form: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    button: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
    },
}));

const Input = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input: inputProps, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

// TODO : faire fonctionner les ancres avec https://github.com/rafrex/react-router-hash-link
const APIPageMecenat = ({ location, ...props }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles();
    const [submitted, setSubmitted] = useState();
    const [loading, setLoading] = useSafeSetState(false);
    const [
        pay,
        { loading: payFormLoading, data: payForm, error, total },
    ] = useMutation();

    const validate = (values) => {
        const errors = { amount: undefined };

        if (!values.amount) {
            errors.amount = translate("ra.validation.required");
        }

        return errors;
    };

    const submit = async (values) => {
        setLoading(true);

        const [{ data: form }, ...res] = await new Promise(
            (resolve, reject) => {
                pay(
                    {
                        type: CUSTOM_POST,
                        resource: "mecenat",
                        payload: {
                            data: {
                                ...values,
                                returnUrl: `/#${location.pathname}`,
                            },
                        },
                    },
                    {
                        onSuccess: (...args) => {
                            setLoading(false);
                            resolve(args);
                        },
                        onFailure: (error) => {
                            setLoading(false);
                            notify(
                                typeof error === "string"
                                    ? error
                                    : typeof error === "undefined" ||
                                      !error.message
                                    ? "ra.auth.sign_in_error"
                                    : error.message,
                                "warning"
                            );
                            reject(error);
                        },
                    }
                );
            }
        );
        setSubmitted(true);
        const elem = document.createElement("div");
        elem.innerHTML = form;
        const htmlForm = elem.firstChild;
        //  htmlForm.setAttribute("target", "_blank");
        document.body.appendChild(htmlForm);
        htmlForm.submit();
    };

    const [values, setValues] = useState({
        showPassword: false,
    });
    return (
        <APIPage
            {...props}
            footer={
                <footer className={classes.footer}>
                    <div className={classes.footerContent}>
                        <h2 id="request">
                            {translate("pages.mecenat.footerTitle")}
                        </h2>
                        {submitted ? (
                            <h3>{translate("pages.mecenat.success")}</h3>
                        ) : (
                            <>
                                <Form
                                    onSubmit={submit}
                                    validate={validate}
                                    render={({ handleSubmit }) => (
                                        <form
                                            onSubmit={handleSubmit}
                                            noValidate
                                        >
                                            <div className={classes.form}>
                                                <div className={classes.input}>
                                                    <SelectInput
                                                        autoFocus
                                                        id="amount"
                                                        name="amount"
                                                        variant="outlined"
                                                        choices={[
                                                            {
                                                                id: 25,
                                                                name: translate(
                                                                    "pages.mecenat.amounts.25"
                                                                ),
                                                            },
                                                            {
                                                                id: 120,
                                                                name: translate(
                                                                    "pages.mecenat.amounts.120"
                                                                ),
                                                            },
                                                            {
                                                                id: 150,
                                                                name: translate(
                                                                    "pages.mecenat.amounts.150"
                                                                ),
                                                            },
                                                            {
                                                                id: 260,
                                                                name: translate(
                                                                    "pages.mecenat.amounts.260"
                                                                ),
                                                            },
                                                            {
                                                                id: 500,
                                                                name: translate(
                                                                    "pages.mecenat.amounts.500"
                                                                ),
                                                            },
                                                            {
                                                                id: 1000,
                                                                name: translate(
                                                                    "pages.mecenat.amounts.1000"
                                                                ),
                                                            },
                                                            {
                                                                id: 1934,
                                                                name: translate(
                                                                    "pages.mecenat.amounts.1934"
                                                                ),
                                                            },
                                                        ]}
                                                        label={
                                                            <span
                                                                className={
                                                                    classes.label
                                                                }
                                                            >
                                                                {translate(
                                                                    "pages.mecenat.amountLabel"
                                                                )}
                                                            </span>
                                                        }
                                                        disabled={loading}
                                                    />
                                                </div>
                                                <Button
                                                    type="submit"
                                                    color="secondary"
                                                    disabled={loading}
                                                    variant="contained"
                                                    className={classes.button}
                                                >
                                                    {loading && (
                                                        <CircularProgress
                                                            className={
                                                                classes.icon
                                                            }
                                                            size={18}
                                                            thickness={2}
                                                        />
                                                    )}
                                                    {translate(
                                                        "pages.mecenat.payButton"
                                                    )}
                                                </Button>
                                            </div>
                                        </form>
                                    )}
                                />
                            </>
                        )}
                    </div>
                </footer>
            }
        />
    );
};

export default APIPageMecenat;
