import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { useTranslate } from "react-admin";
import AppelCotisationModal from "./AppelCotisationModal";

const AppelCotisationModalButton = ({
    className,
    idAppelCotisation,
    ...props
}) => {
    const [open, setOpen] = useState(false);

    const translate = useTranslate();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleClickOpen}
            >
                {translate("Payer ma cotisation")}
            </Button>
            <AppelCotisationModal
                open={open}
                idAppelCotisation={idAppelCotisation}
                handleClose={handleClose}
            ></AppelCotisationModal>
        </>
    );
};

export default AppelCotisationModalButton;
