import React, { useState, useEffect } from "react";
import moment from "moment";
import PostJournalHelper from "../helpers/PostJournalHelper";

import ModelListController from "./ModelListController";

/**
 * @param {Object} props
 * @param {function({ data: PostJournalHelper[], loaded: Boolean })} props.children
 * @param {Date} props.minDate Filtrer les posts : après la date spécifiée.
 * @param {Number} props.page pagination
 * @param {Number} props.perPage pagination
 * @returns {{data:PostJournalHelper[], loaded:boolean}}
 */
const PostsJournalController = ({
    minDate,
    maxDate = moment().startOf("minute").toDate(),
    filter,
    sort = {
        field: "datetime",
        order: "DESC",
    },
    ...props
}) => {
    const [filterState, setFilterState] = useState(filter);

    useEffect(() => {
        const _filter = { ...filter };
        if (minDate) {
            _filter.datetime = _filter.datetime || {};
            _filter.datetime["{{Op.gte}}"] = minDate;
        }
        if (maxDate) {
            _filter.datetime = _filter.datetime || {};
            _filter.datetime["{{Op.lt}}"] = maxDate;
        }
        setFilterState(_filter);
    }, [JSON.stringify(minDate), JSON.stringify(maxDate), filter]);
    return (
        <ModelListController
            {...props}
            sort={sort}
            filter={filterState}
            resource="postjournal"
            helperClass={PostJournalHelper}
        />
    );
};
export default PostsJournalController;
