import React from "react";
import AppelCotisationHelper from "../helpers/AppelCotisationHelper";

import ModelListController from "./ModelListController";

/**
 * @param {Object} props
 * @param {function({ data: AppelCotisationHelper[], loaded: Boolean, error:Error })} props.children
 * @param {Number} props.page pagination
 * @param {Number} props.perPage pagination
 * @returns {{data:AppelCotisationHelper[], loaded:boolean, error:Error}}
 */
const AppelCotisationListController = ({ userRequiredPayments, ...props }) => {
    return (
        <ModelListController
            {...props}
            resource={`appelcotisation${
                userRequiredPayments ? "/userRequiredPayments" : ""
            }`}
            helperClass={AppelCotisationHelper}
        />
    );
};

export default AppelCotisationListController;
