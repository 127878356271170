import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Separator from "../components/Separator";
import LinkPage from "../components/LinkPage";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        margin: theme.spacing(2, 0),
        padding: theme.spacing(2, 4),
        "&$root$root$root p a": {
            textDecoration: "none",
            color: "inherit",
            fontSize: "inherit",
        },
    },
    separator: {
        margin: theme.spacing(1, 0, 2, 0),
    },
}));

const Footer = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <footer className={classes.root}>
            <Separator className={classes.separator} />
            <Typography variant="h2" component="h3">
                Confrérie des Chevaliers du Tastevin
            </Typography>
            <p>
                2 rue de Chaux, 21700 Nuits-Saint-Georges
                <br />
                Tél&nbsp;:&nbsp;
                <a href="tel:+33380610712">
                    +33&nbsp;3&nbsp;80&nbsp;61&nbsp;07&nbsp;12
                </a>{" "}
                Fax&nbsp;:&nbsp;
                <a href="tel:+33380623709">
                    +33&nbsp;3&nbsp;80&nbsp;62&nbsp;37&nbsp;09
                </a>
            </p>
            <p>
                <Button
                    variant="outlined"
                    component="a"
                    href="mailto:info@tastevin-bourgogne.com"
                >
                    {translate("Nous contacter")}
                </Button>
            </p>
            <p>
                {<LinkPage identifier="mentions" />} |{" "}
                {<LinkPage identifier="cgv" />} |{" "}
                {<LinkPage identifier="conditionspaiement" />}
            </p>
        </footer>
    );
};
export default Footer;
