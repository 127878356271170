import React from "react";
import { useTranslate } from "react-admin";
import getErrorMessage from "../../helpers/getErrorMessage";

/**
 * Affiche une erreur éventuellement traduite par `getErrorMessage`
 * @param {Object} props
 * @param {Error} props.error L'erreur à afficher
 * @param {string|Function} props.component le composant à utiliser pour l'affichage ("span" par défaut)
 */
const ErrorView = ({ error, component: Component = "span", ...props }) => {
    const translate = useTranslate();
    return <Component>{getErrorMessage(error, translate)}</Component>;
};

export default ErrorView;
