import React from "react";
import PageListController from "../../controllers/PageListController";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { Routes } from "../routes";
import { generatePath } from "react-router";

const PageButton = ({ identifier, listProps = {}, children, ...props }) => {
    return (
        <PageListController
            filter={{ identifier }}
            withLoader={false}
            {...listProps}
        >
            {({ loaded, data, error }) =>
                loaded && data && data.length ? (
                    <Link
                        to={generatePath(Routes.catchAll, {
                            slug: data[0].slug || "i",
                            id: data[0].id,
                        })}
                        {...props}
                    >
                        {children || data[0].title}
                    </Link>
                ) : null
            }
        </PageListController>
    );
};

export default PageButton;
