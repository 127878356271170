import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        marginTop: theme.spacing(4),
        /*[theme.breakpoints.down("sm")]: {
            display: "block",
        },*/
        "&$root$root h1": {
            margin: 0,
        },
    },
    spacer: {
        flexGrow: 1,
    },
    left: {
        marginRight: theme.spacing(2),
    },
    right: {
        float: "right",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const SectionHeader = ({ title, right }) => {
    const classes = useStyles();
    return (
        <header className={classes.root}>
            <div className={classes.left}>{title}</div>
            <span className={classes.spacer}></span>
            <div className={classes.right}>{right}</div>
        </header>
    );
};

export default SectionHeader;
