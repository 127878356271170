import React, { useState, useEffect } from "react";
import PageController from "../../controllers/PageController";
import NotFound from "./NotFound";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import { generatePath } from "react-router";
import { Routes } from "../routes";
import { useLocale } from "react-admin";
import APIPage from "./APIPage";
import APIPageIntronisation from "./APIPageIntronisation";
import APIPageMecenat from "./APIPageMecenat";
import APIPagePaiementChancellerie from "./APIPagePaiementChancellerie";

const CatchAllPage = (props) => {
    const {
        match: {
            params: { id, slug },
        },
    } = props;
    const locale = useLocale();
    const history = useHistory();
    const [loadedPage, setLoadedPage] = useState();
    useEffect(() => {
        if (
            loadedPage &&
            loadedPage.slug &&
            String(loadedPage.id) === String(id) &&
            loadedPage.slug !== slug
        ) {
            history.replace(
                generatePath(Routes.catchAll, { slug: loadedPage.slug, id })
            );
        }
    }, [JSON.stringify(loadedPage), slug, locale, id]);
    return (
        <PageController id={id} withLoader={false} onLoaded={setLoadedPage}>
            {({ loaded, error, data: page }) =>
                !loaded ? (
                    <CircularProgress />
                ) : (
                    <>
                        {page && page.id ? (
                            (() => {
                                switch (page.identifier) {
                                    case "intronisation":
                                        return (
                                            <APIPageIntronisation
                                                {...props}
                                                page={page}
                                            />
                                        );
                                    case "paiement-chancellerie":
                                        return (
                                            <APIPagePaiementChancellerie
                                                {...props}
                                                page={page}
                                            />
                                        );
                                    case "mecenat":
                                        return (
                                            <APIPageMecenat
                                                {...props}
                                                page={page}
                                            />
                                        );
                                    default:
                                        return (
                                            <APIPage {...props} page={page} />
                                        );
                                }
                            })()
                        ) : (
                            <NotFound />
                        )}
                    </>
                )
            }
        </PageController>
    );
};

export default CatchAllPage;
