import React from "react";
import ReservationWebHelper from "../helpers/ReservationWebHelper";

import ModelListController from "./ModelListController";

/**
 * @param {Object} props
 * @param {function({ data: ReservationWebHelper[], loaded: Boolean, error:Error })} props.children
 * @param {Number} props.page pagination
 * @param {Number} props.perPage pagination
 * @returns {{data:ReservationWebHelper[], loaded:boolean, error:Error}}
 */
const ReservationWebListController = (props) => {
    return (
        <ModelListController
            {...props}
            resource="reservationweb"
            helperClass={ReservationWebHelper}
        />
    );
};

export default ReservationWebListController;
