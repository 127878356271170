import React, { useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import ReservationWebListController from "../../controllers/ReservationWebListController";
import ChapitresController from "../../controllers/ChapitresController";
import ChapitresList from "../components/ChapitresList";
import SectionHeader from "../components/SectionHeader";
import FormControl from "@material-ui/core/FormControl";
import Page from "./Page";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { uniq } from "lodash";

import SousCommanderieSelect from "../components/SousCommanderieSelect";
import { Routes } from "../routes";
import { Link } from "react-router-dom";

export default ({ location: { pathname }, ...props }) => {
    const translate = useTranslate();
    const [commanderieSelected, setCommanderieSelected] = useState(-1);
    const [filter, setFilter] = useState({});
    useEffect(() => {
        setFilter(
            commanderieSelected === -1 // tous
                ? {}
                : commanderieSelected
                ? {
                      commanderieChapitre: commanderieSelected,
                  }
                : { commanderieChapitre: { "{{Op.is}}": null } }
        );
    }, [commanderieSelected]);
    return (
        <Page>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <SectionHeader
                        title={<h1>{translate("pages.reservations.title")}</h1>}
                        right={
                            <FormControl>
                                <SousCommanderieSelect
                                    commanderieSelected={commanderieSelected}
                                    setCommanderieSelected={
                                        setCommanderieSelected
                                    }
                                />
                            </FormControl>
                        }
                    />
                </Grid>
                <ReservationWebListController filter={filter}>
                    {({ loaded, data: reservations }) =>
                        loaded && reservations && reservations.length ? (
                            <ChapitresController
                                filter={{
                                    id: {
                                        "{{Op.in}}": uniq(
                                            reservations.map(
                                                (resa) => resa.chapitre
                                            )
                                        ),
                                    },
                                }}
                            >
                                {({ loaded, data: chapitres }) => (
                                    <ChapitresList
                                        backUrl={pathname}
                                        loaded={loaded}
                                        chapitres={chapitres}
                                    />
                                )}
                            </ChapitresController>
                        ) : (
                            <Grid item xs={12}>
                                <h2>
                                    {translate(
                                        "Vous n'avez fait aucune réservation"
                                    )}
                                </h2>
                                <p>
                                    <Button
                                        component={Link}
                                        to={Routes.chapitres}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        {translate("Chapitres à venir")}
                                    </Button>
                                </p>
                            </Grid>
                        )
                    }
                </ReservationWebListController>
            </Grid>
        </Page>
    );
};
