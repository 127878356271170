import React from "react";
import moment from "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import { useLocale } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { fade } from "@material-ui/core/styles/colorManipulator";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
        padding: ".5em 0.5em",
        background: fade(theme.palette.secondary.main, 0.8),
        color: theme.palette.secondary.contrastText,
        lineHeight: 1.25,
        textTransform: "none",
        fontSize: 16,
    },
    top: {},
    middle: {},
    bottom: {},
    commanderie: {
        background: fade(theme.palette.primary.main, 0.8),
        color: theme.palette.primary.contrastText,
    },
    confrerie: {},
}));

const DateChapitre = ({
    className,
    chapitre,
    variant = "button",
    component = "div",
    ...props
}) => {
    const classes = useStyles();
    const locale = useLocale();
    const momentDate = moment(chapitre.datetime).tz(chapitre.timezone);
    return (
        <Typography
            className={classnames(
                classes.root,
                className,
                chapitre.commanderie ? classes.commanderie : classes.confrerie
            )}
            variant={variant}
            component={component}
            {...props}
        >
            {locale === "fr" ? (
                // TODO use i18next and translate interpolation if more languages are supported
                <>
                    <span className={classes.top}>
                        {momentDate.format("DD")}
                    </span>
                    <span className={classes.middle}>
                        {momentDate.format("MMM")}
                    </span>
                    <span className={classes.bottom}>
                        {momentDate.format("YYYY")}
                    </span>
                </>
            ) : (
                <>
                    <span className={classes.top}>
                        {momentDate.format("DD")}
                    </span>
                    <span className={classes.middle}>
                        {momentDate.format("MMM")}
                    </span>
                    <span className={classes.bottom}>
                        {momentDate.format("YYYY")}
                    </span>
                </>
            )}
        </Typography>
    );
};

export default DateChapitre;
