import ModelHelper from "./ModelHelper";

export default class NotificationHelper extends ModelHelper {
    /**
     * @property {string}
     */
    name;
    /**
     * @property {string}
     */
    text;
    get i18nDataProperty() {
        return "Notification_i18n";
    }
}
