import ModelHelper from "./ModelHelper";

const PaysHelper = class extends ModelHelper {
    /**
     * @property {string}
     */
    Pays;
};

export default PaysHelper;
