import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import {
    useTranslate,
    useLogin,
    useNotify,
    useSafeSetState,
    useMutation,
} from "react-admin";
import { Link } from "react-router-dom";
import useForgot from "../hooks/useForgot";
import useReset from "../hooks/useReset";
import passwordValidator from "password-validator";
import createPasswordValidator from "../shared/createPasswordValidator";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Routes } from "../front/routes";
import { CUSTOM_POST } from "../restProvider";
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import DateInputTZ from "../admin/components/fields/DateInputTZ";
import moment from "moment";

const getRecaptchaActionSuffix = () => moment().format("YYYYMMDD");

const useStyles = makeStyles(
    (theme) => ({
        form: {
            padding: theme.spacing(2),
            "& .MuiInput-underline:before": {
                borderColor: fade(theme.palette.common.white, 0.85),
            },
            "& .MuiInput-underline:hover:before": {
                borderColor: fade(theme.palette.primary.main, 0.85),
            },
            "& .MuiFormLabel-root": {},
            "& a": {
                color: "inherit",
            },
        },
        labelComponent: {
            color: theme.palette.common.white,
        },
        label: {},
        input: {},
        inputTextArea: {
            marginTop: "1em",
        },
        inputRow: {
            display: "flex",
            alignItems: "center",
            "& > *": {
                marginRight: theme.spacing(1),
            },
            "& > *:last-child": {
                marginRight: theme.spacing(0),
            },
        },
        inputComponent: {
            color: theme.palette.common.white,
            "& fieldset": {
                borderColor: fade(theme.palette.common.white, 0.85),
            },
            "&:hover:hover fieldset": {
                borderColor: fade(theme.palette.primary.main, 0.85),
            },
            "&.Mui-disabled": {
                color: fade(theme.palette.common.white, 0.35),
            },
        },
        dateInputComponent: {
            "& input": {
                color: theme.palette.common.white,
                "&::-webkit-calendar-picker-indicator": {
                    filter: "invert(1)",
                },
            },
        },
        inputHidden: {
            display: "none",
        },
        button: {
            width: "100%",
            "&.Mui-disabled": {
                color: fade(theme.palette.common.white, 0.5),
            },
        },
        icon: {
            marginRight: theme.spacing(1),
        },
        iconPass: {
            color: theme.palette.primary.main,
        },
        responseMessage: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(2),
            textAlign: "center",
        },
    }),
    { name: "RaLoginForm" }
);

const Input = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input: inputProps, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={
            (touched && error) || (
                <span dangerouslySetInnerHTML={{ __html: "&#8203;" }} />
            )
        }
        {...inputProps}
        {...props}
        fullWidth
    />
);

const LoginForm = (props) => {
    const { redirectTo } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();
    const classes = { ...useStyles(props), ...useRecaptcaStyles() };

    const validate = (values) => {
        const errors = { username: undefined, password: undefined };

        if (!values.username) {
            errors.username = translate("ra.validation.required");
        }
        if (!values.password) {
            errors.password = translate("ra.validation.required");
        }

        return errors;
    };

    const submit = async (values) => {
        if (!executeRecaptcha) {
            return;
        }
        setLoading(true);
        const token = await executeRecaptcha(
            `login_page_${getRecaptchaActionSuffix()}`
        );
        login({ ...values, token }, redirectTo)
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                notify(
                    typeof error === "string"
                        ? error
                        : typeof error === "undefined" || !error.message
                        ? "ra.auth.sign_in_error"
                        : error.message,
                    "warning"
                );
            });
    };

    const [values, setValues] = useState({
        showPassword: false,
    });
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    return (
        <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <Field
                                autoFocus
                                id="username"
                                name="username"
                                component={Input}
                                InputLabelProps={{
                                    className: classes.labelComponent,
                                }}
                                InputProps={{
                                    className: classes.inputComponent,
                                }}
                                label={
                                    <span className={classes.label}>
                                        {translate("auth.username")}
                                    </span>
                                }
                                disabled={loading}
                            />
                        </div>
                        <div className={classes.input}>
                            <Field
                                id="password"
                                name="password"
                                component={Input}
                                label={
                                    <span className={classes.label}>
                                        {translate("ra.auth.password")}
                                    </span>
                                }
                                type={values.showPassword ? "text" : "password"}
                                InputLabelProps={{
                                    className: classes.labelComponent,
                                }}
                                InputProps={{
                                    className: classes.inputComponent,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                                style={{ color: "inherit" }}
                                            >
                                                {values.showPassword ? (
                                                    <Visibility
                                                        fontSize="small"
                                                        className={
                                                            classes.iconPass
                                                        }
                                                    />
                                                ) : (
                                                    <VisibilityOff
                                                        fontSize="small"
                                                        className={
                                                            classes.iconPass
                                                        }
                                                    />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                disabled={loading}
                                autoComplete="current-password"
                            />
                        </div>
                    </div>
                    <CardActions>
                        <Button
                            variant="contained"
                            type="submit"
                            color="secondary"
                            disabled={loading}
                            className={classes.button}
                        >
                            {loading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            <span>{translate("ra.auth.sign_in")}</span>
                        </Button>
                    </CardActions>
                    <Button
                        type="button"
                        color="primary"
                        className={classes.button}
                        component={Link}
                        to={`/forgot`}
                    >
                        {translate("auth.forgot")}
                    </Button>
                    <Button
                        type="button"
                        color="default"
                        className={classes.button}
                        component={Link}
                        to={Routes.credentialsRequest}
                    >
                        {translate("auth.credentialsRequest")}
                    </Button>
                </form>
            )}
        />
    );
};

LoginForm.propTypes = {
    redirectTo: PropTypes.string,
};

const ForgotForm = (props) => {
    const { redirectTo } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const forgot = useForgot();
    const translate = useTranslate();
    const notify = useNotify();
    const classes = { ...useStyles(props), ...useRecaptcaStyles() };
    const { executeRecaptcha } = useGoogleReCaptcha();

    const validate = (values) => {
        const errors = { email: undefined };

        if (!values.email) {
            errors.email = translate("ra.validation.required");
        }

        return errors;
    };

    const submit = async (values) => {
        if (!executeRecaptcha) {
            return;
        }
        setLoading(true);
        const token = await executeRecaptcha(
            `forgot_page_${getRecaptchaActionSuffix()}`
        );
        forgot({ ...values, token }, redirectTo)
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                notify(
                    typeof error === "string"
                        ? error
                        : typeof error === "undefined" || !error.message
                        ? "ra.auth.sign_in_error"
                        : error.message,
                    "warning"
                );
            });
    };

    return (
        <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <Field
                                autoFocus
                                id="email"
                                name="email"
                                component={Input}
                                InputLabelProps={{
                                    className: classes.labelComponent,
                                }}
                                InputProps={{
                                    className: classes.inputComponent,
                                }}
                                label={
                                    <span className={classes.label}>
                                        {translate("auth.email")}
                                    </span>
                                }
                                disabled={loading}
                            />
                        </div>
                    </div>
                    <CardActions>
                        <Button
                            variant="contained"
                            type="submit"
                            color="secondary"
                            disabled={loading}
                            className={classes.button}
                        >
                            {loading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {translate("auth.forgotButton")}
                        </Button>
                    </CardActions>

                    <div className={classes.form}>
                        <Typography variant="body1">
                            {translate(
                                "Si vous ne recevez pas d'email, faites une"
                            )}{" "}
                            <Link
                                color="primary"
                                className={classes.button}
                                to={Routes.credentialsRequest}
                            >
                                {translate(
                                    "auth.credentialsRequest"
                                ).toLowerCase()}
                            </Link>
                        </Typography>
                    </div>
                    <CardActions>
                        <Button
                            type="button"
                            color="primary"
                            className={classes.button}
                            component={Link}
                            to={`/login`}
                        >
                            {translate("ra.auth.sign_in")}
                        </Button>
                    </CardActions>
                </form>
            )}
        />
    );
};

ForgotForm.propTypes = {
    redirectTo: PropTypes.string,
};

const ResetForm = (props) => {
    const { redirectTo, match } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const reset = useReset();
    const translate = useTranslate();
    const notify = useNotify();
    const classes = { ...useStyles(props), ...useRecaptcaStyles() };
    const { executeRecaptcha } = useGoogleReCaptcha();

    const schema = createPasswordValidator(new passwordValidator());
    const validate = (values) => {
        const errors = { email: undefined };

        if (!values.email) {
            errors.email = translate("ra.validation.required");
        }
        if (!values.token) {
            errors.token = translate("ra.validation.required");
        }

        if (!values.password) {
            errors.password = translate("ra.validation.required");
        }

        if (!schema.validate(values.password)) {
            errors.password = translate("auth.motDePasseValidate");
        }
        return errors;
    };

    const [values, setValues] = React.useState({
        showPassword: false,
    });
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const submit = async (values) => {
        if (!executeRecaptcha) {
            return;
        }
        setLoading(true);
        const token = await executeRecaptcha(
            `reset_page_${getRecaptchaActionSuffix()}`
        );
        reset({ ...values, recaptchaToken: token }, redirectTo)
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                notify(
                    typeof error === "string"
                        ? error
                        : typeof error === "undefined" || !error.message
                        ? "ra.auth.sign_in_error"
                        : error.message,
                    "warning"
                );
            });
    };

    return (
        <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <Field
                                autoFocus
                                id="email"
                                name="email"
                                initialValue={match.params.email}
                                component={Input}
                                InputLabelProps={{
                                    className: classes.labelComponent,
                                }}
                                InputProps={{
                                    className: classes.inputComponent,
                                }}
                                label={
                                    <span className={classes.label}>
                                        {translate("auth.email")}
                                    </span>
                                }
                                disabled={true}
                            />
                        </div>
                        <div className={classes.inputHidden}>
                            <Field
                                id="token"
                                name="token"
                                initialValue={match.params.token}
                                component={Input}
                                InputLabelProps={{
                                    className: classes.labelComponent,
                                }}
                                InputProps={{
                                    className: classes.inputComponent,
                                }}
                                disabled={loading}
                            />
                        </div>
                        <div className={classes.input}>
                            <Field
                                autoFocus
                                id="password"
                                name="password"
                                component={Input}
                                label={
                                    <span className={classes.label}>
                                        {translate("ra.auth.password")}
                                    </span>
                                }
                                disabled={loading}
                                type={values.showPassword ? "text" : "password"}
                                InputLabelProps={{
                                    className: classes.labelComponent,
                                }}
                                InputProps={{
                                    className: classes.inputComponent,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                            >
                                                {values.showPassword ? (
                                                    <Visibility
                                                        fontSize="small"
                                                        className={
                                                            classes.iconPass
                                                        }
                                                    />
                                                ) : (
                                                    <VisibilityOff
                                                        fontSize="small"
                                                        className={
                                                            classes.iconPass
                                                        }
                                                    />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    <CardActions>
                        <Button
                            variant="contained"
                            type="submit"
                            color="secondary"
                            disabled={loading}
                            className={classes.button}
                        >
                            {loading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            <span>{translate("auth.resetButton")}</span>
                        </Button>
                    </CardActions>
                    <CardActions>
                        <Button
                            type="button"
                            color="primary"
                            className={classes.button}
                            component={Link}
                            to={`/login`}
                        >
                            {translate("ra.auth.sign_in")}
                        </Button>
                    </CardActions>
                </form>
            )}
        />
    );
};

ResetForm.propTypes = {
    redirectTo: PropTypes.string,
};

const useRecaptcaStyles = makeStyles((theme) => ({
    "@global": {
        ".grecaptcha-badge": {
            display: "none",
        },
    },
}));

const CredentialsRequestFormInner = (props) => {
    const [
        sendCredentialRequest,
        {
            loading: sendCredentialRequestLoading,
            data: sendCredentialRequestResponse,
            error: sendCredentialRequestError,
        },
    ] = useMutation();
    const translate = useTranslate();
    const notify = useNotify();
    const classes = { ...useStyles(props), ...useRecaptcaStyles() };
    const { executeRecaptcha } = useGoogleReCaptcha();

    const schema = createPasswordValidator(new passwordValidator());
    const validate = (values) => {
        const errors = { email: undefined };

        if (!values.firstName) {
            errors.firstName = translate("ra.validation.required");
        }
        if (!values.lastName) {
            errors.lastName = translate("ra.validation.required");
        }
        if (!values.email) {
            errors.email = translate("ra.validation.required");
        }
        if (!values.dateOfBirth) {
            errors.dateOfBirth = translate("ra.validation.required");
        }
        if (!values.identityVerification) {
            errors.identityVerification = translate("ra.validation.required");
        }
        return errors;
    };

    const submit = async (values) => {
        if (!executeRecaptcha) {
            return;
        }
        try {
            const token = await executeRecaptcha(
                `credentialsRequest_page_${getRecaptchaActionSuffix()}`
            );
            const [{ data: response }, ...res] = await new Promise(
                (resolve, reject) => {
                    sendCredentialRequest(
                        {
                            type: CUSTOM_POST,
                            resource: "credentialsRequest",
                            payload: {
                                data: {
                                    ...values,
                                    token,
                                },
                            },
                        },
                        {
                            onSuccess: (...args) => {
                                resolve(args);
                            },
                            onFailure: (error) => {
                                reject(error);
                            },
                        }
                    );
                }
            );
            notify(response.message, "success");
        } catch (error) {
            // const returnValue = error && error.errorsByField ? error.errorsByField : null;
            notify(error.message, "error");
            // défini les submitErrors du formulaire
            // On le fait pas, ça marche pas à cause des TextInput de react-admin qui supportent pas submitErrors
            // https://github.com/marmelab/react-admin/issues/4351
            // return returnValue;
        }
    };

    const submitted =
        sendCredentialRequestResponse && !sendCredentialRequestError;

    return (
        <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.form}>
                        {submitted ? (
                            <div className={classes.responseMessage}>
                                <Typography variant="h5">
                                    {sendCredentialRequestResponse?.message}
                                </Typography>
                                <Typography variant="h5">
                                    {translate(
                                        "Nous allons traiter votre demande. Vous pourrez ensuite réinitialiser votre mot de passe"
                                    )}
                                </Typography>
                            </div>
                        ) : (
                            <>
                                <div className={classes.input}>
                                    <Typography variant="h2">
                                        {translate("auth.credentialsRequest")}
                                    </Typography>
                                    <Typography variant="body1">
                                        {translate(
                                            "Remplissez ce formulaire si vous pensez que nous n'avons pas votre adresse e-mail à jour"
                                        )}
                                    </Typography>
                                    <Typography variant="body1">
                                        {translate("Sinon, cliquez sur")}{" "}
                                        <Link
                                            color="default"
                                            display="inline"
                                            variant="text"
                                            to={`/forgot`}
                                        >
                                            {translate(
                                                "auth.forgot"
                                            ).toLowerCase()}
                                        </Link>
                                    </Typography>
                                    <br />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        autoFocus
                                        id="lastName"
                                        name="lastName"
                                        component={Input}
                                        InputLabelProps={{
                                            className: classes.labelComponent,
                                        }}
                                        InputProps={{
                                            className: classes.inputComponent,
                                        }}
                                        label={
                                            <span className={classes.label}>
                                                {translate("auth.lastName")}
                                            </span>
                                        }
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        id="firstName"
                                        name="firstName"
                                        component={Input}
                                        InputLabelProps={{
                                            className: classes.labelComponent,
                                        }}
                                        InputProps={{
                                            className: classes.inputComponent,
                                        }}
                                        label={
                                            <span className={classes.label}>
                                                {translate("auth.firstName")}
                                            </span>
                                        }
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        id="email"
                                        name="email"
                                        component={Input}
                                        InputLabelProps={{
                                            className: classes.labelComponent,
                                        }}
                                        InputProps={{
                                            className: classes.inputComponent,
                                        }}
                                        label={
                                            <span className={classes.label}>
                                                {translate("auth.email")}
                                            </span>
                                        }
                                    />
                                </div>
                                <div className={classes.input}>
                                    <DateInputTZ
                                        variant="standard"
                                        id="dateOfBirth"
                                        name="dateOfBirth"
                                        className={[
                                            classes.inputComponent,
                                            classes.dateInputComponent,
                                        ].join(" ")}
                                        fullWidth
                                        color="inherit"
                                        label={
                                            <span
                                                className={[
                                                    classes.label,
                                                    classes.labelComponent,
                                                ].join(" ")}
                                            >
                                                {translate("auth.dateOfBirth")}
                                            </span>
                                        }
                                        helperText={
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: "&#8203;",
                                                }}
                                            />
                                        }
                                    />
                                </div>
                                <div className={classes.inputTextArea}>
                                    <Field
                                        id="identityVerification"
                                        name="identityVerification"
                                        component={Input}
                                        InputLabelProps={{
                                            className: classes.labelComponent,
                                        }}
                                        InputProps={{
                                            className: classes.inputComponent,
                                            variant: "outlined",
                                            multiline: true,
                                        }}
                                        variant={"outlined"}
                                        label={
                                            <span className={classes.label}>
                                                {translate(
                                                    "auth.identityVerification"
                                                )}
                                            </span>
                                        }
                                        placeholder={translate(
                                            "auth.identityVerificationPlaceholder"
                                        )}
                                        rows={4}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    {!submitted && (
                        <CardActions>
                            <Button
                                variant="contained"
                                type="submit"
                                color="secondary"
                                disabled={
                                    sendCredentialRequestLoading || submitted
                                }
                                className={classes.button}
                            >
                                {sendCredentialRequestLoading && (
                                    <CircularProgress
                                        className={classes.icon}
                                        size={18}
                                        thickness={2}
                                    />
                                )}
                                <span>
                                    {translate("auth.sendCredentialRequest")}
                                </span>
                            </Button>
                        </CardActions>
                    )}
                    <CardActions>
                        <Button
                            type="button"
                            color="primary"
                            className={classes.button}
                            component={Link}
                            to={`/login`}
                        >
                            {translate("ra.auth.sign_in")}
                        </Button>
                    </CardActions>
                </form>
            )}
        />
    );
};
const siteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY;
const CredentialsRequestForm = (props) => (
    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
        <CredentialsRequestFormInner {...props} />
    </GoogleReCaptchaProvider>
);

const LoginFormWithRecaptcha = (props) => (
    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
        <LoginForm {...props} />
    </GoogleReCaptchaProvider>
);

const ForgotFormWithRecaptcha = (props) => (
    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
        <ForgotForm {...props} />
    </GoogleReCaptchaProvider>
);

const ResetFormWithRecaptcha = (props) => (
    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
        <ResetForm {...props} />
    </GoogleReCaptchaProvider>
);

export {
    ForgotFormWithRecaptcha as ForgotForm,
    ResetFormWithRecaptcha as ResetForm,
    CredentialsRequestForm,
};

export default LoginFormWithRecaptcha;
