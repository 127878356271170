import React from "react";
import { Route } from "react-router-dom";
import ForgotPasswordPage from "../../layout/ForgotPasswordPage";
import ResetPasswordPage from "../../layout/ResetPasswordPage";
import theme from "../theme";
import Chapitres from "../pages/Chapitres";
import Home from "../pages/Home";
import Account from "../pages/Account";
import Chapitre from "../pages/Chapitre";
import Journal from "../pages/Journal";
import JournalPost from "../pages/JournalPost";
import PrivateareaPost from "../pages/PrivateareaPost";
import ChapitresParticipations from "../pages/ChapitresParticipations";
import ChapitresReservations from "../pages/ChapitresReservations";
import ChapitreReserver from "../pages/ChapitreReserver";
import ChapitreResume from "../pages/ChapitreResume";
import CatchAllPage from "../pages/CatchAllPage";
import Roster from "../pages/Roster";
import CredentialsRequestPage from "../../layout/CredentialsRequestPage";
import Privatearea from "../pages/Privatearea";

const Routes = {
    forgot: "/forgot",
    credentialsRequest: "/credentials-request",
    reset: "/reset/:email?/:token?",
    home: "/",
    compte: "/compte",
    chapitres: "/chapitres",
    chapitresParticipations: "/chapitres/partitipations",
    chapitresReservations: "/chapitres/reservations",
    chapitre: "/chapitres/:id/:slug?",
    chapitreReserver: "/chapitres/:id/:slug?/reserver/:idReservation?/:action?",
    chapitreResume: "/chapitres/:id/:slug?/resume",
    journal: "/journal",
    journalSousCommanderie: "/journal/filter/:id/:slug?",
    journalPost: "/journal/:id/:slug?",
    privateareaPost: "/privatearea/:id/:slug?",
    privatearea: "/privatearea",
    roster: "/roster",
    appelCotisation: "/cotisation/:id",
    appelCotisationRetour: "/cotisation/:id/retour",
    catchAll: "/:slug/:id",
};

export { Routes };

export default [
    <Route
        exact
        path={Routes.forgot}
        key={Routes.forgot}
        render={(props) => <ForgotPasswordPage theme={theme} {...props} />}
        noLayout
    />,
    <Route
        exact
        path={Routes.credentialsRequest}
        key={Routes.credentialsRequest}
        render={(props) => <CredentialsRequestPage theme={theme} {...props} />}
        noLayout
    />,
    <Route
        path={Routes.reset}
        key={Routes.reset}
        render={(props) => <ResetPasswordPage theme={theme} {...props} />}
        noLayout
    />,
    <Route exact path={Routes.home} key={Routes.home} component={Home} />,
    <Route
        exact
        path={Routes.compte}
        key={Routes.compte}
        component={Account}
    />,
    <Route
        exact
        path={Routes.chapitres}
        key={Routes.chapitres}
        component={Chapitres}
    />,
    <Route
        exact
        path={Routes.chapitresParticipations}
        key={Routes.chapitresParticipations}
        component={ChapitresParticipations}
    />,
    <Route
        exact
        path={Routes.chapitresReservations}
        key={Routes.chapitresReservations}
        component={ChapitresReservations}
    />,
    <Route
        path={Routes.chapitreReserver}
        key={Routes.chapitreReserver}
        component={ChapitreReserver}
    />,
    <Route
        path={Routes.chapitreResume}
        key={Routes.chapitreResume}
        component={ChapitreResume}
    />,
    <Route path={Routes.chapitre} key={Routes.chapitre} component={Chapitre} />,
    <Route
        exact
        path={Routes.journal}
        key={Routes.journal}
        component={Journal}
    />,
    <Route
        exact
        path={Routes.privatearea}
        key={Routes.privatearea}
        component={Privatearea}
    />,
    <Route
        exact
        path={Routes.journalSousCommanderie}
        key={Routes.journalSousCommanderie}
        component={Journal}
    />,
    <Route
        exact
        path={Routes.journalPost}
        key={Routes.journalPost}
        component={JournalPost}
    />,
    <Route
        exact
        path={Routes.privateareaPost}
        key={Routes.privateareaPost}
        component={PrivateareaPost}
    />,
    <Route
        exact
        path={Routes.appelCotisationRetour}
        key={Routes.appelCotisationRetour}
        component={Home}
    />,
    <Route exact path={Routes.roster} key={Routes.roster} component={Roster} />,
    <Route
        path={Routes.catchAll}
        key={Routes.catchAll}
        component={CatchAllPage}
    />,
];
