import { isArray, pickBy, identity } from "lodash";
import apiI18nLocales from "../i18n/apiI18nLocales";

/**
 * Analyse le contenu de la propriété (`key`) dans l'objet `data`
 * Crée un objet avec une propriété par langue disponible dans `apiI18nLocales.locales` (fr, en)
 * Et renvoie une fonction de traduction
 * @todo Pour chaque langue, remplir les données manquantes avec celles de la langue par défaut
 * définie dans `apiI18nLocales.fallback`
 * @param {Object} data Les données
 * @param {String} key Le nom de la propriété à analyser
 * @returns {(locale:string) => {}}
 */
const I18nHelper = (data, key) => {
    const translations = {};
    const i18nData = data[key];
    apiI18nLocales.locales.forEach((locale) => {
        translations[locale] =
            i18nData && isArray(i18nData)
                ? i18nData.find(({ language_id }) => language_id === locale)
                : null;
    });
    return (locale) =>
        // suppression des propriétés vides
        pickBy(translations[locale], identity);
};

export default I18nHelper;
