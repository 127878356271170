import React from "react";
import MediaController from "../../controllers/MediaController";
import ChapitreHelper from "../../helpers/ChapitreHelper";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { fade } from "@material-ui/core/styles/colorManipulator";
import DateChapitre from "./DateChapitre";
import { useTranslate } from "react-admin";
import SousCommanderieController from "../../controllers/SousCommanderieController";
import { Routes } from "../routes";
import MediaBlock from "../components/MediaBlock";
import ReservationWebListController from "../../controllers/ReservationWebListController";
import { useInView } from "react-intersection-observer";
import { stringify } from "querystring";
import { ChapitreReservationStatusBadge } from "./ChapitreReservationStatus";

const useStyles = makeStyles((theme) => ({
    root: {
        textDecoration: "none",
        position: "relative",
        display: "block",
        overflow: "visible",
        background: "transparent",
    },
    link: {
        textDecoration: "none",
    },
    contentArea: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        "&:last-child": {
            paddingBottom: 0,
        },
        textAlign: "justify",
    },
    title: {
        display: "flex",
        alignItems: "flex-start",
        marginTop: theme.spacing(-5),
    },
    titleText: {
        color: "#000000",
        fontSize: 20,
        paddingTop: theme.spacing(4.2),
    },
    date: {
        marginRight: theme.spacing(1),
        position: "relative",
    },
    media: {},
    cardActions: {
        padding: 0,
    },
    sousCommanderie: {
        position: "relative",
        zIndex: 2,
        color: theme.palette.primary.contrastText,
        right: 0,
        top: 0,
        height: 0,
        verticalAlign: "middle",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        marginRight: theme.spacing(1),

        "& span": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "calc(100% - 140px)",
            display: "inline-block",
            lineHeight: "28px",
            height: 28,
            verticalAlign: "middle",
            padding: theme.spacing(0.4, 2, 0.4, 4),
            background: fade(theme.palette.primary.main, 0.8),
            backgroundImage: "url(/images/SCfleche.svg)",
            backgroundSize: "17px 16px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "7px center",
        },
    },
}));

/**
 * @param {object} props
 * @param {ChapitreHelper} props.chapitre
 * @returns {React.ReactElement}
 */
const ChapitresListItem = ({ chapitre, participating, backUrl }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const chapitreLinkTo = {
        pathname: generatePath(Routes.chapitre, {
            id: chapitre.id,
            slug: chapitre.displayName,
        }),
    };
    const chapitreLinkToResume = {
        pathname: generatePath(Routes.chapitreResume, {
            id: chapitre.id,
            slug: chapitre.displayName,
        }),
    };
    let search;
    if (backUrl) {
        search = stringify({ backUrl: backUrl });
        chapitreLinkTo.search = search;
        chapitreLinkToResume.search = search;
    }
    const infosButton = (
        <Button
            size="small"
            color="secondary"
            component={Link}
            className={classes.link}
            to={chapitreLinkTo}
        >
            {translate("Informations")}
        </Button>
    );
    return chapitre ? (
        <Card className={classes.root} elevation={0} ref={ref}>
            {chapitre.commanderie ? (
                <SousCommanderieController component id={chapitre.commanderie}>
                    {({ loaded, data }) =>
                        loaded ? (
                            <div className={classes.sousCommanderie}>
                                <span>{data.displayName}</span>
                            </div>
                        ) : null
                    }
                </SousCommanderieController>
            ) : null}
            <CardActionArea>
                <Link
                    className={classes.link}
                    to={participating ? chapitreLinkToResume : chapitreLinkTo}
                >
                    <MediaController id={chapitre.poster_small}>
                        {({ loaded, media }) => (
                            <MediaBlock
                                srcLoaded={loaded}
                                src={media.src}
                                title={chapitre.displayName}
                                alt={chapitre.displayName}
                                ratio={0.65}
                                className={classes.media}
                            />
                        )}
                    </MediaController>
                    <CardContent className={classes.contentArea}>
                        <Typography
                            className={classes.title}
                            variant="h5"
                            component="h3"
                        >
                            <DateChapitre
                                chapitre={chapitre}
                                className={classes.date}
                            ></DateChapitre>
                            <span className={classes.titleText}>
                                {chapitre.displayName}
                            </span>
                        </Typography>
                        {chapitre.shortDescription ? (
                            <p>{chapitre.shortDescription}</p>
                        ) : null}
                    </CardContent>
                </Link>
            </CardActionArea>
            <CardActions className={classes.cardActions}>
                {participating ? (
                    <Button
                        component={Link}
                        variant="contained"
                        color="secondary"
                        className={classes.link}
                        to={chapitreLinkToResume}
                    >
                        {translate("Photos et résumé")}
                    </Button>
                ) : inView ? (
                    <ReservationWebListController
                        filter={{ chapitre: chapitre.id }}
                        withLoader={false}
                    >
                        {({ data, loaded, error }) => {
                            const disabled =
                                !loaded || !chapitre.reservationIsOpen;
                            let to = {
                                    pathname: "",
                                    search: search ? search : null,
                                },
                                label = "";
                            if (!loaded) {
                                label = translate("Réserver");
                            } else if (data && data[0] && data[0].id) {
                                to.pathname = generatePath(
                                    Routes.chapitreReserver,
                                    {
                                        id: chapitre.id,
                                        slug: chapitre.displayName,
                                        idReservation: data[0].id,
                                    }
                                );
                                label = translate("Ma réservation");
                            } else {
                                to.pathname = generatePath(
                                    Routes.chapitreReserver,
                                    {
                                        id: chapitre.id,
                                        slug: chapitre.displayName,
                                    }
                                );
                                label = translate("Réserver");
                            }
                            return (
                                <>
                                    <Button
                                        className={classes.link}
                                        component={Link}
                                        disabled={disabled}
                                        variant="contained"
                                        color="secondary"
                                        to={to}
                                    >
                                        {label}
                                        <ChapitreReservationStatusBadge
                                            reservation={data[0]}
                                            floating
                                            iconOnly
                                        />
                                    </Button>
                                </>
                            );
                        }}
                    </ReservationWebListController>
                ) : null}
                {participating ? null : infosButton}
            </CardActions>
        </Card>
    ) : null;
};

export default ChapitresListItem;
