import React, { useRef } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { NumberInput } from "react-admin";
import InputAdornment from "@material-ui/core/InputAdornment";
import classnames from "classnames";
import Button from "@material-ui/core/Button";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useForm } from "react-final-form";

const useStyles = makeStyles((theme) => ({
    root: {},
    muiInput: {
        paddingRight: 0, // disable adornment padding
    },
    input: {
        "&::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
        },
        "&::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
        },
    },
    arrows: {
        position: "absolute",
        width: 30,
        flexShrink: 0,
        right: 0,
        top: 0,
        bottom: 0,
        maxHeight: "none",
        height: "auto",
        background: theme.palette.secondary.main,
    },
    arrow: {
        background: theme.palette.common.black,
        color: theme.palette.common.white,
        padding: theme.spacing(0.5),
        position: "absolute",
        width: "100%",
        height: "50%",
        left: 0,
        right: 0,
        minWidth: 0,
        "& > span": {
            // safari fix
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
    },
    arrowUp: {
        top: 0,
        bottom: "50%",
    },
    arrowDown: {
        top: "50%",
        bottom: 0,
    },
}));

const CCTNumberInputArrows = ({
    classes = {},
    position = "end",
    inputRef,
    form,
    source,
    step,
    ...props
}) => {
    const handleClick = (val) => {
        if (!(inputRef && inputRef.current)) {
            return;
        }
        val > 0 ? inputRef.current.stepUp() : inputRef.current.stepDown();
        /*inputRef.current.value = parseFloat(
            parseFloat(inputRef.current.value || 0) + (val > 0 ? step : -step)
        );*/
        form && source && form.change(source, inputRef.current.value);
    };
    return (
        <InputAdornment className={classes.arrows} position={position}>
            <Button
                onClick={() => handleClick(1)}
                className={classnames(classes.arrow, classes.arrowUp)}
                variant="outlined"
            >
                <KeyboardArrowUpIcon />
            </Button>
            <Button
                onClick={() => handleClick(-1)}
                className={classnames(classes.arrow, classes.arrowDown)}
                variant="outlined"
            >
                <KeyboardArrowDownIcon />
            </Button>
        </InputAdornment>
    );
};

// TODO skin arrows https://stackoverflow.com/a/45396364/1491212
const CCTNumberInput = ({
    children,
    className,
    source,
    inputProps: _inputProps,
    step = 1,
    ...props
}) => {
    const form = useForm();

    const classes = useStyles();
    const inputRef = useRef();
    const inputProps = {
        ..._inputProps,
        className: classes.input,
        ref: inputRef,
    };
    return (
        <NumberInput
            step={step}
            InputProps={{
                className: classes.muiInput,
                endAdornment: (
                    <CCTNumberInputArrows
                        classes={classes}
                        inputRef={inputRef}
                        form={form}
                        source={source}
                        step={step}
                    />
                ),
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={inputProps}
            className={classnames(classes.root, className)}
            source={source}
            {...props}
        />
    );
};

export default CCTNumberInput;
