import React, { useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import ChapitresController from "../../controllers/ChapitresController";
import ChapitresList from "../components/ChapitresList";
import SectionHeader from "../components/SectionHeader";
import FormControl from "@material-ui/core/FormControl";
import Page from "./Page";
import Grid from "@material-ui/core/Grid";

import SousCommanderieSelect from "../components/SousCommanderieSelect";
import moment from "moment";
import { Routes } from "../routes";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

export default ({ location: { pathname }, ...props }) => {
    const translate = useTranslate();
    const [commanderieSelected, setCommanderieSelected] = useState(-1);
    const [filter, setFilter] = useState({});
    const [maxDate] = useState(moment().startOf("day").toDate());
    useEffect(() => {
        setFilter(
            commanderieSelected === -1 // tous
                ? {}
                : commanderieSelected
                ? {
                      commanderie: commanderieSelected,
                  }
                : { commanderie: { "{{Op.is}}": null } }
        );
    }, [commanderieSelected]);
    return (
        <Page>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <SectionHeader
                        title={
                            <h1>
                                {translate(
                                    "pages.chapitresParticipations.title"
                                )}
                            </h1>
                        }
                        right={
                            <FormControl>
                                <SousCommanderieSelect
                                    commanderieSelected={commanderieSelected}
                                    setCommanderieSelected={
                                        setCommanderieSelected
                                    }
                                />
                            </FormControl>
                        }
                    />
                </Grid>
                <ChapitresController
                    participating
                    filter={filter}
                    minDate={null}
                    maxDate={maxDate}
                    sort={{
                        field: "datetime",
                        order: "DESC",
                    }}
                >
                    {({ loaded, data: chapitres }) =>
                        loaded && chapitres && chapitres.length ? (
                            <ChapitresList
                                itemsProps={{ participating: true }}
                                loaded={loaded}
                                chapitres={chapitres}
                                backUrl={pathname}
                            />
                        ) : (
                            <Grid item xs={12}>
                                <h2>
                                    {translate(
                                        "Vous n'avez participé à aucun chapitre"
                                    )}
                                </h2>
                                <p>
                                    <Button
                                        component={Link}
                                        to={Routes.chapitres}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        {translate("Chapitres à venir")}
                                    </Button>
                                </p>
                            </Grid>
                        )
                    }
                </ChapitresController>
            </Grid>
        </Page>
    );
};
