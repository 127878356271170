import ModelHelper from "./ModelHelper";

export default class ReservationWebHelper extends ModelHelper {
    /**
     * @property {string}
     */
    reservataireFirstName;
    /**
     * @property {string}
     */
    reservataireLastName;
}
