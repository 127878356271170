import React from "react";
import { usePermissions } from "react-admin";
import MembreHelper from "../helpers/MembreHelper";

import ModelListController from "./ModelListController";

/**
 * @param {Object} props
 * @param {function({ data: MembreHelper[], loaded: Boolean, error:Error })} props.children
 * @param {Number} props.page pagination
 * @param {Number} props.perPage pagination
 * @returns {{data:MembreHelper[], loaded:boolean, error:Error}}
 */
const MembreListController = (props) => {
    const { permissions } = usePermissions();
    return (
        <ModelListController
            {...props}
            resource="membre"
            helperClass={MembreHelper}
            extraAttributes={{
                rosterCommanderie: permissions?.rosterAccess,
            }}
        />
    );
};

export default MembreListController;
