import React, { useState } from "react";
import { TextInput, useTranslate, FormDataConsumer } from "react-admin";
import { Collapse, Card, CardHeader, CardContent } from "@material-ui/core";
import { get, set } from "lodash";
import ReactCountryFlag from "react-country-flag";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import apiI18nLocales from "../../../i18n/apiI18nLocales";

const i18nCountryCodes = {
    en: "gb",
};
export const getCountryCode = (locale) => i18nCountryCodes[locale] || locale;

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(4),
        minWidth: 280,
    },
    cardHeader: {
        display: "flex",
        alignItems: "center",
    },
    spacer: {
        flexGrow: 1,
    },
    cardTitleFlag: {
        marginRight: theme.spacing(1),
    },
    cardTitleText: {},
    cardTitleArrow: {},
}));

const I18nFieldsInputLanguage = ({
    render,
    renderInput,
    record,
    source,
    locale,
    form,
    secondaryAction,
    ...props
}) => {
    const [open, setOpen] = useState(true);
    const translate = useTranslate();
    const classes = useStyles();
    if (!get(record, source)) {
        set(record, source, []);
    }
    const values = get(record, source);
    let localeIndex = values.findIndex(
        ({ language_id }) => language_id === locale
    );
    if (localeIndex === -1) {
        localeIndex = values.length;
        values.push({
            language_id: locale,
        });
    }
    const localeSource = `${source}[${localeIndex}]`;
    const languageIdSource = `${localeSource}.language_id`;
    return (
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                if (!get(formData, languageIdSource)) {
                    set(formData, languageIdSource, locale);
                }
                return (
                    <Card className={classes.card}>
                        <CardHeader
                            onClick={() => setOpen(!open)}
                            title={
                                <span className={classes.cardHeader}>
                                    <ReactCountryFlag
                                        countryCode={getCountryCode(locale)}
                                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/"
                                        svg
                                        className={classes.cardTitleFlag}
                                    />{" "}
                                    <span className={classes.cardTitleText}>
                                        {translate(locale)}
                                    </span>
                                    <span className={classes.spacer}></span>
                                    {secondaryAction && secondaryAction(locale)}
                                    <span className={classes.cardTitleArrow}>
                                        {open ? <ExpandLess /> : <ExpandMore />}
                                    </span>
                                </span>
                            }
                            titleTypographyProps={{
                                variant: "h6",
                            }}
                        />
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <CardContent>
                                {renderInput(
                                    <TextInput
                                        style={{ display: "none" }}
                                        source={`${localeSource}.language_id`}
                                        label="langue"
                                    />
                                )}
                                {render({
                                    renderInput,
                                    record,
                                    source,
                                    localeIndex,
                                    locale,
                                    localeSource,
                                    form,
                                })}
                            </CardContent>
                        </Collapse>
                    </Card>
                );
            }}
        </FormDataConsumer>
    );
};

const I18nFieldsInput = (props) => {
    return apiI18nLocales.locales.map((locale) => (
        <I18nFieldsInputLanguage key={locale} {...props} locale={locale} />
    ));
};

I18nFieldsInput.defaultProps = {
    formPassInputRenderer: true,
};

export default I18nFieldsInput;
