import React, { Fragment } from "react";
export default (text = "") =>
    text
        ? text.split(/(?:\r\n|\r|\n)/).map((item, key) => {
              return (
                  <Fragment key={key}>
                      {item}
                      <br />
                  </Fragment>
              );
          })
        : null;
