import React from "react";
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 0,
    },
    label: {
        ...theme.typography.h3,
        color: theme.palette.common.black,
        marginBottom: 0,
        fontWeight: "bold",
    },
}));

const CCTFormLabel = ({
    children,
    className,
    formLabelComponent: FormLabelComponent = FormLabel,
    ...props
}) => {
    const classes = useStyles();
    return (
        <Typography
            variant="h3"
            component="span"
            className={classnames(classes.root, className)}
        >
            <FormLabelComponent className={classes.label} {...props}>
                {children}
            </FormLabelComponent>
        </Typography>
    );
};

export default CCTFormLabel;
