import ModelHelper from "./ModelHelper";

const FichierHelper = class extends ModelHelper {
    /**
     * @property {string}
     */
    name;
    /**
     * @property {string}
     */
    shortTitle;
    /**
     * @property {string}
     */
    media;
    get i18nDataProperty() {
        return "Fichier_i18n";
    }
    get buttonText() {
        return this.shortTitle || this.name;
    }
    get link() {
        return this.file;
    }
};

export default FichierHelper;
