import React from "react";
import {
    Create,
    TextInput,
    useTranslate,
    DateInput,
    required,
    useNotify,
} from "react-admin";
import { Form } from "react-final-form";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { reservationFormStyles } from "./ChapitreReservationForm";
import SimpleSaveButton from "../components/SimpleSaveButton";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import DateInputTZ from "../../admin/components/fields/DateInputTZ";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => {
    const reservationStyles = reservationFormStyles(theme);
    return {
        root: {},
        form: {
            position: "relative",
            maxWidth: 700,
        },
        create: {},
        ...reservationStyles,
        actions: {
            justifyContent: "flex-end",
        },
        formOverlay: {
            padding: theme.spacing(1, 2),
            position: "absolute",
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: fade(theme.palette.background.default, 0.5),
            textAlign: "center",
            "& .MuiTypography-overline": {
                fontWeight: 700,
                lineHeight: 1.1,
            },
        },
        messageColor: {
            marginTop: theme.spacing(0),
            color: theme.palette.primary.main,
        },
    };
});

const IntronisationFormForm = ({
    classes,
    save,
    setSubmitted,
    disabled,
    ...props
}) => {
    const { saving } = props;
    const translate = useTranslate();
    const notify = useNotify();
    const submit = async (values, complete) => {
        if (disabled) {
            return;
        }
        try {
            await new Promise((resolve, reject) => {
                save(values, false, {
                    onSuccess: (...args) => {
                        notify("pages.intronisation.form.success", "info");
                        resolve(args);
                        setSubmitted(true);
                    },
                    onFailure: (error) => {
                        reject(error);
                    },
                });
            });
        } catch (error) {
            // const returnValue = error && error.errorsByField ? error.errorsByField : null;
            notify(error.message, "error");
            // défini les submitErrors du formulaire
            // On le fait pas, ça marche pas à cause des TextInput de react-admin qui supportent pas submitErrors
            // https://github.com/marmelab/react-admin/issues/4351
            // return returnValue;
        }
    };
    return (
        <Form
            onSubmit={submit}
            render={({ handleSubmit, invalid, form, ...formProps }) => {
                return (
                    <form className={classes.form}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={4}>
                                <TextInput
                                    fullWidth
                                    disabled={disabled}
                                    validate={[required()]}
                                    source="lastName"
                                    placeholder={translate(
                                        "pages.intronisation.form.lastName"
                                    )}
                                    label={translate(
                                        "pages.intronisation.form.lastName"
                                    )}
                                    variant="outlined"
                                    helperText={false}
                                />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <TextInput
                                    fullWidth
                                    disabled={disabled}
                                    validate={[required()]}
                                    source="firstName"
                                    placeholder={translate(
                                        "pages.intronisation.form.firstName"
                                    )}
                                    label={translate(
                                        "pages.intronisation.form.firstName"
                                    )}
                                    variant="outlined"
                                    helperText={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <DateInputTZ
                                    fullWidth
                                    disabled={disabled}
                                    timezone="Etc/GMT"
                                    validate={[required()]}
                                    source="dateOfBirth"
                                    placeholder={translate(
                                        "pages.intronisation.form.dateOfBirth"
                                    )}
                                    label={translate(
                                        "pages.intronisation.form.dateOfBirth"
                                    )}
                                    variant="outlined"
                                    helperText={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    fullWidth
                                    disabled={disabled}
                                    validate={[required()]}
                                    source="placeResidence"
                                    placeholder={translate(
                                        "pages.intronisation.form.placeResidence"
                                    )}
                                    label={translate(
                                        "pages.intronisation.form.placeResidence"
                                    )}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    disabled={disabled}
                                    source="souhaitChapitre"
                                    fullWidth
                                    placeholder={translate(
                                        "pages.intronisation.form.souhaitChapitre"
                                    )}
                                    label={translate(
                                        "pages.intronisation.form.souhaitChapitre"
                                    )}
                                    variant="outlined"
                                    helperText={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    fullWidth
                                    disabled={disabled}
                                    validate={[required()]}
                                    source="email"
                                    placeholder={translate(
                                        "pages.intronisation.form.email"
                                    )}
                                    label={translate(
                                        "pages.intronisation.form.email"
                                    )}
                                    variant="outlined"
                                    helperText={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    disabled={disabled}
                                    validate={[required()]}
                                    source="phone"
                                    placeholder={translate(
                                        "pages.intronisation.form.phone"
                                    )}
                                    label={translate(
                                        "pages.intronisation.form.phone"
                                    )}
                                    variant="outlined"
                                    helperText={false}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    fullWidth
                                    disabled={disabled}
                                    validate={[required()]}
                                    source="nationality"
                                    placeholder={translate(
                                        "pages.intronisation.form.nationality"
                                    )}
                                    label={translate(
                                        "pages.intronisation.form.nationality"
                                    )}
                                    variant="outlined"
                                    helperText={false}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    fullWidth
                                    disabled={disabled}
                                    validate={[required()]}
                                    source="profession"
                                    placeholder={translate(
                                        "pages.intronisation.form.profession"
                                    )}
                                    label={translate(
                                        "pages.intronisation.form.profession"
                                    )}
                                    variant="outlined"
                                    helperText={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    fullWidth
                                    disabled={disabled}
                                    validate={[required()]}
                                    source="sponsor1"
                                    placeholder={translate(
                                        "pages.intronisation.form.sponsor1_placeholder"
                                    )}
                                    label={translate(
                                        "pages.intronisation.form.sponsor1"
                                    )}
                                    variant="outlined"
                                    helperText={false}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    fullWidth
                                    disabled={disabled}
                                    validate={[required()]}
                                    source="sponsor2"
                                    placeholder={translate(
                                        "pages.intronisation.form.sponsor2_placeholder"
                                    )}
                                    label={translate(
                                        "pages.intronisation.form.sponsor2"
                                    )}
                                    variant="outlined"
                                    helperText={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextInput
                                    disabled={disabled}
                                    validate={[required()]}
                                    multiline
                                    fullWidth
                                    inputProps={{ rows: 5 }}
                                    source="motivations"
                                    placeholder={translate(
                                        "pages.intronisation.form.motivations"
                                    )}
                                    label={translate(
                                        "pages.intronisation.form.motivations"
                                    )}
                                    variant="outlined"
                                    helperText={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <h4 className={classes.messageColor}>
                                    {translate(
                                        "pages.intronisation.form.avertissement"
                                    )}
                                </h4>
                                <div
                                    className={classnames(
                                        classes.formLineInner,
                                        classes.actions
                                    )}
                                >
                                    <SimpleSaveButton
                                        disabled={disabled}
                                        color="secondary"
                                        handleSubmitWithRedirect={(...args) => {
                                            return handleSubmit(...args);
                                        }}
                                        invalid={invalid}
                                        icon={null}
                                        saving={saving}
                                        submitOnEnter={true}
                                        label={translate("Enregistrer")}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        {disabled ? (
                            <div className={classes.formOverlay}>
                                <Typography
                                    variant="overline"
                                    color="secondary"
                                >
                                    {translate(
                                        "pages.intronisation.restrictedForUsMembers"
                                    )}
                                </Typography>
                            </div>
                        ) : null}
                    </form>
                );
            }}
        />
    );
};

const IntronisationForm = ({ location, setSubmitted, disabled, ...props }) => {
    const classes = useStyles();
    return (
        <Create
            resource={"inductionrequest"}
            basePath={location.pathname}
            component="div"
            classes={{ main: classes.create }}
            className={classes.root}
        >
            <IntronisationFormForm
                setSubmitted={setSubmitted}
                classes={classes}
                disabled={disabled}
            />
        </Create>
    );
};

export default IntronisationForm;
