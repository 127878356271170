import React from "react";
import PageListController from "../../controllers/PageListController";
import QuillContent from "../../components/QuillContent";

const HomepageText = ({ listControllerProps = {}, ...props }) => {
    return (
        <PageListController
            filter={{ identifier: "home" }}
            sort={[
                {
                    field: "commanderie", // D'abord la version de la commanderie / sous-commanderie
                    order: "DESC",
                },
                {
                    field: "updatedAt",
                    order: "DESC",
                },
            ]}
            perPage={1}
            withLoader={false}
            {...listControllerProps}
        >
            {({ loaded, error, data: [page] }) =>
                page && page.content ? (
                    <QuillContent content={page.content} />
                ) : null
            }
        </PageListController>
    );
};

export default HomepageText;
