import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useMediaQuery } from "@material-ui/core/";
import {
    useTranslate,
    useMutation,
    useQueryWithStore,
    useNotify,
} from "react-admin";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classnames from "classnames";
import GradeController from "../../controllers/GradeController";
import { useTheme } from "@material-ui/core/styles";
import { Trans } from "react-i18next";
import { CUSTOM_GET, CUSTOM_POST } from "../../restProvider";
import { Form } from "react-final-form";

import useCurrentUser from "../../hooks/useCurrentUser";
import { AddressForm } from "../../admin/components/AddressForm";
import SimpleSaveButton from "./SimpleSaveButton";

const useStyles = makeStyles((theme) => ({
    root: {},
    dialog: {
        textAlign: "center",
        padding: theme.spacing(2, 4),
        [theme.breakpoints.down(400)]: {
            padding: theme.spacing(0, 0),
        },
    },
    dialogActions: {
        padding: theme.spacing(2, 2),
        textAlign: "center",
        justifyContent: "center",
    },
    title: {
        marginTop: 0,
        marginBottom: 0,
    },
    textePrincipal: {
        marginTop: 0,
        color: "#000",
        padding: theme.spacing(0, 2),
    },
    membres: {
        marginTop: theme.spacing(4),
        marginBottom: 0,
        paddingBottom: 0,
    },
    content: {
        [theme.breakpoints.up("md")]: {
            width: 570,
        },
    },
}));
function isFormEmpty(form) {
    for (var key in form) {
        if (form[key] === "" || form[key] === null) {
            return true;
        }
    }
    return false;
}
const AppelCotisationModal = ({
    className,
    idAppelCotisation,
    open = false,
    handleClose,
    isRetourBanque = false,
    retourVar = "",
    ...props
}) => {
    const [addressInfo, setAddressInfo] = useState(null);
    const user = useCurrentUser();
    const { loaded, data: appelcotisation } = useQueryWithStore({
        type: "getOne",
        resource: "appelcotisation",
        payload: {
            id: idAppelCotisation,
        },
    });
    const [
        pay,
        { loading: payFormLoading, data: payForm, error, total },
    ] = useMutation();

    const { saving } = props;
    const classes = useStyles();
    const translate = useTranslate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const [paymentPayload, setPaymentPayload] = useState({});

    useEffect(() => {
        if (user) {
            setPaymentPayload({
                query: { memberNumber: user.memberNumber },
            });
        } else {
            setPaymentPayload({});
        }
    }, [JSON.stringify(user)]);

    const notify = useNotify();

    const submit = async (values, complete) => {
        try {
            const [{ data: form }, ...res] = await new Promise(
                (resolve, reject) => {
                    pay(
                        {
                            type: CUSTOM_GET,
                            resource: `appelcotisation/${idAppelCotisation}/payment?address=${encodeURIComponent(
                                JSON.stringify(values)
                            )}`,
                            payload: paymentPayload,
                        },
                        {
                            onSuccess: (...args) => {
                                resolve(args);
                                try {
                                    const elem = document.createElement("div");
                                    elem.innerHTML = args[0].data;
                                    const htmlForm = elem.firstChild;
                                    //  htmlForm.setAttribute("target", "_blank");
                                    document.body.appendChild(htmlForm);
                                    htmlForm.submit();
                                    setSubmitted(true);
                                } catch (e) {
                                    console.error(e);
                                    setSubmitted(false);
                                }
                            },
                            onFailure: (error) => {
                                reject(error);
                            },
                        }
                    );
                }
            );
        } catch (error) {
            // const returnValue = error && error.errorsByField ? error.errorsByField : null;
            notify(error.message, "error");
            // défini les submitErrors du formulaire
            // On le fait pas, ça marche pas à cause des TextInput de react-admin qui supportent pas submitErrors
            // https://github.com/marmelab/react-admin/issues/4351
            // return returnValue;
        }
    };
    const [payDisabled, setPayDisabled] = useState(true);
    const [submitted, setSubmitted] = useState(false);

    /* useEffect(() => {
        let dismounted = false;
        if (form && !dismounted && !submitted) {
            try {
                const elem = document.createElement("div");
                elem.innerHTML = form;
                const htmlForm = elem.firstChild;
                //  htmlForm.setAttribute("target", "_blank");
                document.body.appendChild(htmlForm);
                htmlForm.submit();
                setSubmitted(true);
            } catch (e) {
                console.error(e);
                setSubmitted(false);
            }
        }
        return () => {
            dismounted = true;
        };
    }, [form, submitted]);*/

    return (
        <Form
            onSubmit={submit}
            render={({ handleSubmit, invalid, form, ...formProps }) => {
                return (
                    <form>
                        <Dialog
                            className={classes.dialog}
                            open={open}
                            onClose={handleClose}
                            fullScreen={fullScreen}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth="md"
                        >
                            <DialogTitle
                                id="alert-dialog-title"
                                className={classes.title}
                                disableTypography={true}
                            >
                                {error ? (
                                    <h2 className={classes.title}>
                                        {error.message}
                                    </h2>
                                ) : (
                                    <>
                                        {user ? (
                                            <p className={classes.membres}>
                                                {user.displayName}{" "}
                                                <GradeController
                                                    id={user.codeGrade}
                                                >
                                                    {({
                                                        loaded,
                                                        error,
                                                        data: grade,
                                                    }) => {
                                                        return !loaded ? (
                                                            translate(
                                                                "Chargement"
                                                            )
                                                        ) : grade ? (
                                                            <>
                                                                {user.dateIntronisation
                                                                    ? translate(
                                                                          "{{grade}} intronisé le {{date}}",
                                                                          {
                                                                              grade:
                                                                                  grade.displayName ||
                                                                                  translate(
                                                                                      "Membre"
                                                                                  ),
                                                                              date:
                                                                                  user.dateIntronisation,
                                                                          }
                                                                      )
                                                                    : grade.displayName ||
                                                                      translate(
                                                                          "Membre"
                                                                      )}
                                                            </>
                                                        ) : null;
                                                    }}
                                                </GradeController>
                                            </p>
                                        ) : null}
                                        {appelcotisation ? (
                                            <h2 className={classes.title}>
                                                {translate(
                                                    "Paiement de votre cotisation {{year}}",
                                                    {
                                                        year:
                                                            appelcotisation.year,
                                                    }
                                                )}
                                            </h2>
                                        ) : null}
                                    </>
                                )}
                            </DialogTitle>
                            <DialogContent className={classes.content}>
                                <DialogContentText id="alert-dialog-description">
                                    {loaded ? (
                                        isRetourBanque ? (
                                            retourVar === "ok" ? (
                                                <h3
                                                    className={
                                                        classes.textePrincipal
                                                    }
                                                >
                                                    {translate(
                                                        "Nous vous remercions pour le paiement de votre cotisation"
                                                    )}
                                                </h3>
                                            ) : (
                                                <h3
                                                    className={
                                                        classes.textePrincipal
                                                    }
                                                >
                                                    {translate(
                                                        "Erreur ou annulation, merci de réessayer"
                                                    )}
                                                </h3>
                                            )
                                        ) : appelcotisation ? (
                                            <h3
                                                className={
                                                    classes.textePrincipal
                                                }
                                            >
                                                <Trans i18nKey="cotisationModalText">
                                                    Le paiement de votre
                                                    cotisation{" "}
                                                    {{
                                                        year:
                                                            appelcotisation.year,
                                                    }}{" "}
                                                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                                                    s'élève à{" "}
                                                    <b>
                                                        {{
                                                            priceTTC:
                                                                appelcotisation.priceTTC,
                                                        }}
                                                    </b>
                                                    €
                                                </Trans>
                                            </h3>
                                        ) : null
                                    ) : (
                                        <CircularProgress />
                                    )}
                                    <h3>
                                        {translate("billing.billing address")}
                                    </h3>
                                    <AddressForm classes={classes} />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={handleClose}
                                    variant="outlined"
                                    color="secondary"
                                    autoFocus
                                >
                                    {translate("Fermer")}
                                </Button>
                                {!isRetourBanque || retourVar === "ko" ? (
                                    <SimpleSaveButton
                                        variant="contained"
                                        color="secondary"
                                        invalid={invalid}
                                        disabled={submitted}
                                        handleSubmitWithRedirect={(...args) => {
                                            return handleSubmit(...args);
                                        }}
                                        saving={saving || payFormLoading}
                                        submitOnEnter={true}
                                        className={classnames(
                                            className,
                                            classes.button
                                        )}
                                        icon={null}
                                        label={translate("Payer")}
                                    >
                                        {loaded
                                            ? `${translate("Payer")} ${
                                                  appelcotisation.priceTTC
                                              } €`
                                            : translate("Payer")}
                                    </SimpleSaveButton>
                                ) : null}
                            </DialogActions>
                        </Dialog>
                    </form>
                );
            }}
        />
    );
};

export default AppelCotisationModal;
