import React, { useEffect, useState } from "react";
import {
    SimpleForm,
    required,
    Title,
    useTranslate,
    minLength,
    maxLength,
    TextInput,
    SelectInput,
} from "react-admin";
import { Card, TextField, Button, Stack, MenuItem } from "@material-ui/core";
import { CA_STATES, US_STATES } from "../../front/components/States";

import CurrentUserController from "../../controllers/CurrentUserController";
import { reservationFormStyles } from "../../front/components/ChapitreReservationForm";

import { Toolbar, SaveButton } from "react-admin";
import PaysInput from "../../front/components/PaysInput";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { fade } from "@material-ui/core/styles/colorManipulator";
import PaysSelectInput from "../../front/components/PaysSelectInput";
const useStyles = makeStyles((theme) => {
    const reservationStyles = reservationFormStyles(theme);
    return {
        root: {},
        form: {
            position: "relative",
        },
        ...reservationStyles,
        actions: {
            justifyContent: "flex-end",
        },
        sizeTiers: {
            width: "170px",
            marginTop: "0",
        },
        pays: {
            marginTop: "3px",
            width: "170px",
            zIndex: 5000,
        },
        sizeSmall: {
            width: "100px",
        },
        sizeSemi: {
            width: "220px",
            [theme.breakpoints.down("xs")]: {
                width: "100%",
            },
        },
        flex: {
            display: "flex",
            justifyContent: "space-between",
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
            },
        },
        options: {
            textAlign: "left",
        },
        formLine: {
            width: "460px",
            textAlign: "center",
            [theme.breakpoints.down("xs")]: {
                maxWidth: "100%",
            },
            margin: "0 auto",
        },
        formOverlay: {
            padding: theme.spacing(1, 2),
            position: "absolute",
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: fade(theme.palette.background.default, 0.5),
            textAlign: "center",
            "& .MuiTypography-overline": {
                fontWeight: 700,
                lineHeight: 1.1,
            },
        },
    };
});
export const AddressForm = ({ onChange, toolbar, ...props }) => {
    const classes = useStyles();
    const [country, setCountry] = useState();
    const translate = useTranslate();
    const [defaultAddress, setDefaultAddress] = useState({});
    return (
        <CurrentUserController>
            {(user) => {
                const defaultAddress = {
                    firstName: user?.member?.Prénom,
                    lastName: user?.member["Nom propre"],
                    addressLine1: user?.member["Adresse 1-1"],
                    city: user?.member["Ville 1"],
                    postalCode: user?.member["Code postal 1"],
                    country: user?.member["Code pays 1"]
                        ? user.member["Code pays 1"]
                        : "0FR",
                };

                if (!country) {
                    setCountry(defaultAddress.country);
                }
                return (
                    <>
                        <div className={classes.formLine}>
                            <div className={classes.flex}>
                                <TextInput
                                    name="firstName"
                                    validate={[required()]}
                                    className={classes.sizeSemi}
                                    source="firstName"
                                    placeholder={translate("billing.firstName")}
                                    label={translate("billing.firstName")}
                                    variant="outlined"
                                />
                                <TextInput
                                    validate={[required()]}
                                    source="lastName"
                                    className={classes.sizeSemi}
                                    placeholder={translate("billing.lastName")}
                                    label={translate("billing.lastName")}
                                    defaultValue={user?.member["Nom propre"]}
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <div className={classes.formLine}>
                            <div className={classes.flex}>
                                <TextInput
                                    source="addressLine1"
                                    label={translate("billing.addressLine1")}
                                    placeholder={translate(
                                        "billing.addressLine1"
                                    )}
                                    name="addressLine1"
                                    variant="outlined"
                                    fullWidth
                                    defaultValue={defaultAddress.addressLine1}
                                    validate={[required()]}
                                />
                            </div>
                        </div>
                        <div className={classes.formLine}>
                            <div className={classes.flex}>
                                <TextInput
                                    name="city"
                                    source="city"
                                    variant="outlined"
                                    label={translate("billing.city")}
                                    placeholder={translate("billing.city")}
                                    className={classes.sizeSemi}
                                    defaultValue={defaultAddress.city}
                                    validate={required()}
                                />
                                <TextInput
                                    name="postalCode"
                                    source="postalCode"
                                    variant="outlined"
                                    label={translate("billing.postalCode")}
                                    placeholder={translate(
                                        "billing.postalCode"
                                    )}
                                    className={classes.sizeSemi}
                                    defaultValue={defaultAddress.postalCode}
                                    validate={required()}
                                />
                            </div>
                        </div>
                        <div className={classes.formLine}>
                            <div
                                className={`${classes.flex} ${classes.options}`}
                            >
                                <PaysSelectInput
                                    name="country"
                                    source="country"
                                    label={translate("billing.country")}
                                    variant="outlined"
                                    defaultValue={defaultAddress.country}
                                    className={`${classes.sizeSemi} ${classes.options}`}
                                    validate={required()}
                                    onChange={(newVal) => setCountry(newVal)}
                                ></PaysSelectInput>
                                {country === "USA" || country === "CDN" ? (
                                    <SelectInput
                                        name="stateOrProvince"
                                        variant="outlined"
                                        label={translate(
                                            "billing.stateOrProvince"
                                        )}
                                        className={`${classes.sizeSemi} ${classes.options}`}
                                        choices={
                                            country === "USA"
                                                ? US_STATES
                                                : country === "CDN"
                                                ? CA_STATES
                                                : []
                                        }
                                        disabled={
                                            !(
                                                country === "USA" ||
                                                country === "CDN"
                                            )
                                        }
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </>
                );
            }}
        </CurrentUserController>
    );
};
