import React from "react";
import NotificationHelper from "../helpers/NotificationHelper";

import ModelListController from "./ModelListController";

/**
 * @param {Object} props
 * @param {function({ data: NotificationHelper[], loaded: Boolean, error:Error })} props.children
 * @param {Number} props.page pagination
 * @param {Number} props.perPage pagination
 * @returns {{data:NotificationHelper[], loaded:boolean, error:Error}}
 */
const NotificationsController = (props) => {
    return (
        <ModelListController
            {...props}
            resource="notification"
            helperClass={NotificationHelper}
        />
    );
};

export default NotificationsController;
