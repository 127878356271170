import ModelHelper from "./ModelHelper";

const AppelCotisationHelper = class extends ModelHelper {
    /**
     * @property {string}
     */
    name;
    /**
     * @property {number}
     */
    year;
    /**
     * @property {number}
     */
    priceHT;
    /**
     * @property {number}
     */
    priceTTC;
    /**
     * @property {Date}
     */
    dueDate;
    get i18nDataProperty() {
        return "AppelCotisation_i18n";
    }
};

export default AppelCotisationHelper;
