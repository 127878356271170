import { useCallback } from "react";

import { useAuthProvider, useNotify } from "react-admin";
import { defaultAuthParams } from "ra-core/lib/auth/useAuthProvider";
import { useLocation, useHistory } from "react-router-dom";

const useForgot = () => {
    const authProvider = useAuthProvider();
    const location = useLocation();
    const locationState = location.state;
    const notify = useNotify();
    const history = useHistory();
    const nextPathName = locationState && locationState.nextPathname;

    const forgot = useCallback(
        (params = {}, pathName = defaultAuthParams.loginUrl) =>
            authProvider
                .forgot(params)
                .then((ret) => {
                    history.push(nextPathName || pathName);
                    notify("auth.forgotSuccess", "info");
                    return ret;
                })
                .catch((error) => {
                    notify(error?.message || "auth.forgotFailure", "error");
                }),
        [authProvider, history, nextPathName, notify]
    );

    const forgotWithoutProvider = useCallback(
        (_, __) => {
            history.push(defaultAuthParams.loginUrl);
            return Promise.resolve();
        },
        [history]
    );

    return authProvider ? forgot : forgotWithoutProvider;
};

export default useForgot;
