import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment";
import momentTimezone from "moment-timezone";
import en from "moment/locale/en-gb";
import fr from "moment/locale/fr";
import polyglotI18nProvider from "ra-i18n-polyglot";

/**
 * Translation. See https://marmelab.com/react-admin/Translation.html
 * https://github.com/i18next/react-i18next
 */
moment.updateLocale("en", en);
moment.updateLocale("fr", fr);
export default (locales) => {
    const polyglotProvider = polyglotI18nProvider(
        (locale) => locales[locale] || locales[locales.defaultLocale],
        locales.defaultLocale
    );
    i18n.use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources: {
                en: {
                    translation: locales.en,
                },
                fr: {
                    translation: locales.fr,
                },
            },
            lng: locales.defaultLocale,
            fallbackLng: locales.defaultLocale,
            interpolation: {
                escapeValue: false,
                format: (value, format, locale) => {
                    if (format === "uppercase") return value.toUpperCase();
                    if (value instanceof Date) {
                        return moment(value).format(format);
                    }
                    return value;
                },
            },
        });
    moment.locale(locales.defaultLocale);
    momentTimezone.defineLocale(
        locales.defaultLocale,
        moment.localeData()._config
    );
    momentTimezone.locale(locales.defaultLocale);
    i18n.on("languageChanged", (lng) => {
        moment.locale(lng);
        momentTimezone.defineLocale(lng, moment.localeData()._config);
        momentTimezone.locale(lng);
    });
    return {
        translate: (key, options) => {
            let translated = i18n.t(key, options);
            if (translated.indexOf("|||") !== -1) {
                translated = polyglotProvider.translate(key, options);
            }
            return translated;
        },
        changeLocale: (locale) => {
            polyglotProvider.changeLocale(locale);
            localStorage.setItem("locale", locale);
            return i18n.changeLanguage(locale);
        },
        getLocale: () => {
            return i18n.language;
        },
    };
};
