import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HotelController from "../../controllers/HotelController";
import {
    lowerCaseAllWordsExceptFirstLetters,
    upperCaseFirstLetter,
} from "../../utils/caseUtils";

const Hotel = ({ codeHotel, ...props }) => {
    return (
        <HotelController id={codeHotel} withLoader={false}>
            {({ loaded, error, data: hotel }) => {
                return !loaded ? null : hotel ? (
                    <div>
                        {hotel["Ville"] ? (
                            <>
                                {upperCaseFirstLetter(
                                    lowerCaseAllWordsExceptFirstLetters(
                                        hotel["Ville"]
                                    )
                                )}
                                {" - "}
                            </>
                        ) : null}
                        {upperCaseFirstLetter(
                            lowerCaseAllWordsExceptFirstLetters(
                                hotel["Adresse 1"]
                            )
                        )}
                    </div>
                ) : null;
            }}
        </HotelController>
    );
};

export default Hotel;
