import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";

const paletteType = "light";
/*
const primary = {
  light: '#ea8f8f',
  main: '#b71c1c',
  dark: '#801313',
}
*/
const primary = {
    light: "#ffc169",
    main: "#cf841a",
    dark: "#905c12",
};
const secondary = {
    light: "#cb3a49",
    main: "#be091c",
    dark: "#850613",
};
const ternary = {
    light: "#ffc169",
    main: "#cf841a",
    dark: "#905c12",
};
const success = {
    light: "#009900",
    main: "#007700",
    dark: "#004400",
};
const info = {
    light: "#377de5",
    main: "#1a63cf",
    dark: "#0e3f88",
};
const background = {
    default: "#faf7f4",
    paper: "#FFF",
};

let theme = createMuiTheme({
    typography: {
        fontFamily: '"Lato", sans-serif',
        fontWeightMedium: 500,
        h1: {
            fontFamily: '"Metamorphous", serif',
            fontSize: 35,
            marginBottom: "0.5em",
        },
        h2: {
            fontFamily: '"Metamorphous", serif',
            fontSize: 20,
            marginBottom: "0.5em",
        },
        h3: {
            fontFamily: '"Lato", serif',
            fontSize: 16,
            marginBottom: "0.5em",
        },
        h4: {
            fontFamily: '"ITC Galliard W02 Roman", serif',
            fontSize: 16,
            marginBottom: "0.5em",
        },
        h5: {
            fontFamily: '"ITC Galliard W02 Roman", serif',
            fontSize: 16,
        },
        h6: {
            fontSize: 16,
            fontFamily: '"ITC Galliard W02 Roman", serif',
        },
        body1: {
            fontFamily: '"ITC Galliard W02 Roman", serif',
            fontSize: 14,
        },
        body2: {
            fontFamily: '"ITC Galliard W02 Roman", serif',
            fontSize: 12,
        },
        subtitle1: {
            fontFamily: '"ITC Galliard W02 Roman", serif',
        },
        subtitle2: {
            fontFamily: '"ITC Galliard W02 Roman", serif',
        },
        caption: {
            fontFamily: '"Lato", sans-serif',
            fontSize: 13,
            lineHeight: 1.25,
        },
        button: {
            fontFamily: '"Lato", sans-serif',
        },
    },
    palette: {
        type: paletteType,
        primary: primary,
        secondary: secondary,
        ternary: ternary,
        success: success,
        info: info,
        background: background,
        scrollbar: {
            track: "#f0f0f0",
            thumb: secondary.main,
        },
    },
    shape: {
        borderRadius: 0,
    },
});

theme = responsiveFontSizes(theme);

theme = {
    ...theme,
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    backgroundColor: theme.palette.background.paper,
                },
                "*": {
                    "scrollbar-color": theme.palette.scrollbar.thumb,
                    "scrollbar-width": "thin",
                },
                "*::-webkit-scrollbar": {
                    width: 10,
                    height: 10,
                },
                "*::-webkit-scrollbar-track": {
                    backgroundColor: theme.palette.scrollbar.track,
                },
                "*::-webkit-scrollbar-corner": {
                    backgroundColor: "transparent",
                },
                "*::-webkit-scrollbar-thumb": {
                    width: 2,
                    height: 2,
                    boxSizing: "border-box",
                    border: `4px solid ${theme.palette.scrollbar.track}`,
                    borderRadius: 4,
                    backgroundColor: theme.palette.scrollbar.thumb,
                    outline: "0 solid",
                },
                "table th": {
                    textAlign: "left",
                },
            },
        },
        MuiAlert: {
            root: {
                padding: theme.spacing(0.25, 1),
                ...theme.typography.body1,
            },
            action: {
                marginRight: theme.spacing(-0.5),
            },
            standardSuccess: {
                color: theme.palette.success.main,
            },
            standardError: {
                color: theme.palette.secondary.main,
            },
            standardWarning: {
                color: theme.palette.primary.main,
            },
            standardInfo: {
                color: theme.palette.info.main,
            },
        },
        MuiAlertTitle: {
            root: {
                ...theme.typography.subtitle1,
                marginTop: -4,
            },
        },
        MuiDrawer: {
            paper: {
                backgroundColor: "#18202c",
            },
        },
        MuiButton: {
            label: {
                textTransform: "initial",
            },
            contained: {
                boxShadow: "none",
                "&:active": {
                    boxShadow: "none",
                },
                border: `1px solid`,
                backgroundImage: "url(/images/stripes.png)",
                backgroundPosition: "center 2px",
                "&$disabled": {
                    borderColor: theme.palette.grey[400],
                    backgroundImage: "none",
                },
            },
            containedPrimary: {
                borderColor: primary.dark,
            },
            containedSecondary: {
                borderColor: secondary.dark,
            },
            text: {
                color: "#7f7f7f",
                fontFamily: '"Lato", sans-serif',
            },
        },
        MuiTabs: {
            root: {
                marginLeft: theme.spacing(1),
            },
        },
        TabIndicator: {
            root: {
                height: 3,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                backgroundColor: theme.palette.common.white,
            },
        },
        MuiTab: {
            root: {
                textTransform: "initial",
                margin: "0 16px",
                minWidth: 0,
                [theme.breakpoints.up("md")]: {
                    minWidth: 0,
                },
            },
            wrapper: {
                fontFamily: '"Metamorphous", sans-serif',
                padding: 0,
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                },
            },
        },
        MuiIconButton: {
            root: {},
        },
        MuiTooltip: {
            tooltip: {},
        },
        MuiDivider: {
            root: {},
        },
        MuiTypography: {
            paragraph: {
                color: "#7f7f7f",
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {},
        },
        MuiAvatar: {
            root: {},
        },
        MuiOutlinedInput: {
            adornedEnd: {},
        },
    },
};

/**
 * Keep
 */
theme = {
    ...theme,
    overrides: {
        ...theme.overrides,
        MuiChip: {
            root: {
                backgroundColor:
                    theme.palette.type === "dark"
                        ? theme.palette.primary.dark
                        : lighten(theme.palette.primary.light, 0.85),
            },
        },
        MuiCheckbox: {
            root: {
                "& svg": {
                    fontSize: 18,
                },
            },
        },
        MuiInputBase: {
            root: {
                fontFamily: '"Lato", serif',
                "& input": {
                    "&:-webkit-autofill": {
                        transition:
                            "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
                    },
                    "&:-webkit-autofill:focus": {
                        transition:
                            "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
                    },
                    "&:-webkit-autofill:hover": {
                        transition:
                            "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
                    },
                },
            },
        },
        MuiInput: {
            input: {
                fontFamily: '"Lato", serif',
                "&:-webkit-autofill": {
                    transition:
                        "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
                },
                "&:-webkit-autofill:focus": {
                    transition:
                        "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
                },
                "&:-webkit-autofill:hover": {
                    transition:
                        "background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
                },
            },
        },
        MuiFormControlLabel: {
            label: {
                fontFamily: '"Lato", serif',
            },
        },
        MuiFormHelperText: {
            root: {
                fontFamily: '"Lato", serif',
                ...theme.typography.caption,
                fontSize: 10,
            },
        },
        MuiRadio: {
            root: {
                "& svg": {
                    width: "0.7em",
                    height: "0.7em",
                },
            },
        },
        MuiTableCell: {
            root: {
                borderWidth: 1,
                "&:first-of-type": {
                    borderLeftWidth: 0,
                    width: 1,
                },
                "&:last-of-type": {
                    borderRightWidth: 0,
                },
                borderStyle: "solid",
                borderColor:
                    theme.palette.type === "dark"
                        ? "rgba(50, 50, 50, 1)"
                        : theme.palette.grey[300],
            },
            head: {
                background:
                    theme.palette.type === "dark"
                        ? theme.palette.primary.dark
                        : lighten(theme.palette.primary.light, 0.85),
                borderWidth: 0,
                fontSize: "0.75rem",
                /*background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText*/
            },
            body: {},
        },
        MuiTableSortLabel: {
            root: {
                "&:hover": {
                    /*color: theme.palette.primary.contrastText,*/
                },
            },
            active: {
                /*color: theme.palette.primary.contrastText,*/
            },
        },
    },
};

export default theme;
