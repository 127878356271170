import React from "react";
import Typography from "@material-ui/core/Typography";
import { useRosterStyles } from "../pages/Roster";
import classnames from "classnames";
import MemberHasCommanderieHasTitleHelper from "../../helpers/MemberHasCommanderieHasTitleHelper";

/**
 * @param {Object} props
 * @param {MemberHasCommanderieHasTitleHelper} props.title
 */
const MemberDetailsTitleSC = ({
    membre,
    className,
    component: Component = "div",
    source,
    title,
    ...props
}) => {
    const classes = useRosterStyles();
    return (
        <Typography
            component={Component}
            variant="body1"
            color="secondary"
            className={classnames(
                classes.memberListItemLine,
                classes.memberListItemLineGray,
                classes.memberListItemLineSCTitle
            )}
        >
            {title ? <span>{title}</span> : null}
        </Typography>
    );
};

export default MemberDetailsTitleSC;
