import React from "react";
import Typography from "@material-ui/core/Typography";
import { useRosterStyles } from "../pages/Roster";
import classnames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MembreHelper from "../../helpers/MembreHelper";

const useStyles = makeStyles((theme) => ({ root: { lineHeight: 1.5 } }));

/**
 *
 * @param {Object} props
 * @param {MembreHelper} props.membre
 */
const MemberDetailsTitle = ({ membre, className, ...props }) => {
    const rosterClasses = useRosterStyles();
    const classes = useStyles();
    return (
        <Typography
            component="div"
            color="secondary"
            variant="overline"
            className={classnames(
                rosterClasses.memberListItemLine,
                classes.root,
                className
            )}
            {...props}
        >
            {membre.nationalTitles.map((title, index) => (
                <span key={index}>{title.CommanderieTitle.title}</span>
            ))}
        </Typography>
    );
};

export default MemberDetailsTitle;
