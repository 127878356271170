import React, { useEffect, useState } from "react";
import useWidth from "../../hooks/useWidth";
import { isWidthDown } from "@material-ui/core/withWidth";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import { useTranslate, useQueryWithStore, Edit, Create } from "react-admin";
import { Routes } from "../routes";
import MemberDetailsSharingConsentForm from "./MemberDetailsSharingConsentForm";

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {},
    radio: {},
    shareContactInfoWithLabel: { display: "none" },
    buttons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MemberDetailsSharingConsentDialog = ({
    memberNumber,
    codeCommanderie,
    open,
    handleClose,
    onSuccess,
    onPreferencesSet = () => {},
    ...props
}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const width = useWidth();
    const isExtraSmall = isWidthDown("xs", width);
    const [actionsPortalRef, setActionsPortalRef] = useState();
    const {
        loaded,
        error,
        data: [preferences] = [],
        total,
    } = useQueryWithStore({
        type: "getList",
        resource: "membrepreferences",
        payload: {
            // Adding "open" here has no API meaning.
            // this is a trick to force the query to re-run on each open. It is necessary.
            // If not here, the modal might open with stale data (no data for instance)
            // and thus try to create a new MembrePreferences, which won't work if one exists.
            _forceReloadTrick: open,
            pagination: {
                page: 1,
                perPage: 1,
            },
            sort: {
                field: "createdAt",
                order: "DESC",
            },
            filter: {
                memberNumber,
            },
        },
    });
    useEffect(() => {
        if (loaded) {
            onPreferencesSet(preferences);
        }
    }, [preferences, loaded]);
    return (
        <Dialog
            className={classes.dialog}
            TransitionComponent={Transition}
            fullScreen={isExtraSmall}
            open={open}
            onClose={handleClose}
            aria-labelledby="member-details-sharing-dialog-title"
            aria-describedby="member-details-sharing-dialog-description"
            maxWidth="sm"
        >
            <DialogTitle disableTypography>
                <h2>{translate("Contact information sharing settings")}</h2>
            </DialogTitle>
            <DialogContent>
                <p>{translate("contactDetailsSharingSettingsModal.p1")}</p>
                <p>{translate("contactDetailsSharingSettingsModal.p2")}</p>
                <p>{translate("contactDetailsSharingSettingsModal.p3")}</p>
                <p>{translate("contactDetailsSharingSettingsModal.p4")}</p>
                <p>{translate("contactDetailsSharingSettingsModal.p5")}</p>
                {loaded || preferences ? (
                    preferences ? (
                        <Edit
                            resource={"membrepreferences"}
                            basePath={Routes.home}
                            record={preferences}
                            id={preferences.id}
                            undoable={false}
                            component="div"
                            classes={{ main: classes.edit }}
                            onSuccess={onSuccess}
                        >
                            <MemberDetailsSharingConsentForm
                                classes={classes}
                                redirect={false}
                                codeCommanderie={codeCommanderie}
                                actionsPortalRef={actionsPortalRef}
                            />
                        </Edit>
                    ) : (
                        <Create
                            resource={"membrepreferences"}
                            basePath={Routes.home}
                            record={{
                                memberNumber,
                                shareContactInfoWith: "commanderie",
                            }}
                            component="div"
                            classes={{ main: classes.edit }}
                            onSuccess={onSuccess}
                        >
                            <MemberDetailsSharingConsentForm
                                classes={classes}
                                redirect={false}
                                codeCommanderie={codeCommanderie}
                                actionsPortalRef={actionsPortalRef}
                            />
                        </Create>
                    )
                ) : (
                    <CircularProgress size={24} />
                )}
            </DialogContent>
            <DialogActions>
                <span ref={(ref) => setActionsPortalRef(ref)} />
                <Button color="secondary" onClick={handleClose}>
                    {translate("Fermer")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MemberDetailsSharingConsentDialog;
