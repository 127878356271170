import React from "react";
import GradeHelper from "../helpers/GradeHelper";

import ModelListController from "./ModelListController";

/**
 * @param {Object} props
 * @param {function({ data: GradeHelper[], loaded: Boolean, error:Error })} props.children
 * @param {Number} props.page pagination
 * @param {Number} props.perPage pagination
 * @returns {{data:GradeHelper[], loaded:boolean, error:Error}}
 */
const GradeListController = (props) => {
    return (
        <ModelListController
            {...props}
            resource="grade"
            helperClass={GradeHelper}
        />
    );
};

export default GradeListController;
