import React, { useState } from "react";
import APIPage from "./APIPage";
import IntronisationForm from "../components/IntronisationForm";
import { useTranslate } from "react-admin";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useCurrentUser from "../../hooks/useCurrentUser";

const useStyles = makeStyles((theme) => ({
    root: {},
    footer: {
        background: theme.palette.background.default,
    },
    footerContent: {
        maxWidth: 700,
        boxSizing: "border-box",
        margin: "auto",
        padding: theme.spacing(6, 2, 10),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(8, 4, 12),
        },
    },
}));

// TODO : faire fonctionner les ancres avec https://github.com/rafrex/react-router-hash-link
const APIPageIntronisation = ({ location, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const [submitted, setSubmitted] = useState();
    const currentUser = useCurrentUser();
    return (
        <APIPage
            {...props}
            footer={
                <footer className={classes.footer}>
                    <div className={classes.footerContent}>
                        <h2 id="request">
                            {translate("pages.intronisation.footerTitle")}
                        </h2>
                        {submitted ? (
                            <h3>{translate("pages.intronisation.success")}</h3>
                        ) : (
                            <IntronisationForm
                                location={location}
                                setSubmitted={setSubmitted}
                                disabled={
                                    !currentUser || currentUser?.isUSMember
                                }
                            />
                        )}
                    </div>
                </footer>
            }
        />
    );
};

export default APIPageIntronisation;
