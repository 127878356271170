import React from "react";
import moment from "moment";
import { useLocale } from "react-admin";
import { useTranslate } from "react-admin";

const DateJournal = ({ className, post, ...props }) => {
    const locale = useLocale();
    const translate = useTranslate();
    return (
        <React.Fragment>
            {translate(" le  ")}
            {locale === "fr" ? (
                // TODO use i18next and translate interpolation if more languages are supported
                <React.Fragment>
                    {moment(post.datetime).format("D")}{" "}
                    {moment(post.datetime).format("MMM")}{" "}
                    {moment(post.datetime).format("YYYY")}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {moment(post.datetime).format("MMM")}{" "}
                    {moment(post.datetime).format("DD")}
                    {", "}
                    {moment(post.datetime).format("YYYY")}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default DateJournal;
