import { useQueryWithStore } from "react-admin";
import MediaHelper from "../helpers/MediaHelper";
/**
 * @param {object} props
 * @param {function({media: MediaHelper, loaded: boolean})} props.children
 * @returns {any}
 */
const MediaController = ({ children, id }) => {
    const { loaded, error, data } = useQueryWithStore({
        type: "getOne",
        resource: "media",
        payload: { id },
    });
    return children({ loaded, error, media: new MediaHelper(data) });
};

export default MediaController;
