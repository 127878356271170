import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import { useTranslate, useLocale } from "react-admin";
import AddressPartsLocalized from "./AddressPartsLocalized";
import PhoneIcon from "@material-ui/icons/Phone";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import HomeIcon from "@material-ui/icons/Home";
import BusinessIcon from "@material-ui/icons/Business";
import MembreHelper from "../../helpers/MembreHelper";
import PaysController from "../../controllers/PaysController";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    divider: {
        marginBottom: theme.spacing(2),
    },
    address: {},
    addressWrapper: {
        display: "flex",
    },
    addressIcon: {
        margin: theme.spacing(1, 1, 2, 1),
    },
    addressContent: {},
    row: {
        border: `1px solid ${theme.palette.background.default}`,
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
        paddingBottom: theme.spacing(2),
        marginBottom: theme.spacing(2),
        "&:last-of-type": {
            borderBottom: 0,
        },
    },
}));

/**
 *
 * @param {object} props
 * @param {MembreHelper} props.member
 */
const MemberDetailsInfo = ({ member: memberDetails, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const locale = useLocale();
    return memberDetails && memberDetails.hasContactInfo ? (
        <>
            {memberDetails.Surnom ? (
                <div className={classes.row}>
                    <Typography color="primary" variant="h3">
                        {translate("pages.account.fieldNickname")}
                    </Typography>
                    <div>{memberDetails.Surnom}</div>
                </div>
            ) : null}
            {memberDetails.hasSpouse ? (
                <div className={classes.row}>
                    <Typography color="primary" variant="h3">
                        {translate("pages.account.conjoint")}
                    </Typography>
                    <div>
                        {memberDetails["Spouse First Name"] ? (
                            <>{memberDetails["Spouse First Name"]} </>
                        ) : null}
                        {memberDetails["Spouse Last Name"] ? (
                            <>{memberDetails["Spouse Last Name"]} </>
                        ) : null}
                        {memberDetails["Spouse Nick Name"] ? (
                            <>- {memberDetails["Spouse Nick Name"]} </>
                        ) : null}
                        {memberDetails["Spouse Title"] ? (
                            <>({memberDetails["Spouse Title"]})</>
                        ) : null}
                    </div>
                </div>
            ) : null}
            {memberDetails.hasBusinessDetails ? (
                <div className={classes.row}>
                    <>
                        <Typography color="primary" variant="h3">
                            {translate("pages.account.profession")}
                        </Typography>
                        {memberDetails["Profession"] ? (
                            <div>{memberDetails["Profession"]}</div>
                        ) : null}
                    </>
                    {memberDetails.hasBusinessAddress ? (
                        <>
                            <div>
                                <div className={classes.address}>
                                    {memberDetails.hasBusinessAddressSimple ? (
                                        <span
                                            className={classes.addressWrapper}
                                        >
                                            <BusinessIcon
                                                className={classes.addressIcon}
                                            />
                                            <span
                                                className={
                                                    classes.addressContent
                                                }
                                            >
                                                {memberDetails["Société"] ? (
                                                    <>
                                                        {
                                                            memberDetails[
                                                                "Société"
                                                            ]
                                                        }
                                                        <br />
                                                    </>
                                                ) : null}
                                                {memberDetails[
                                                    "Adresse Soc-1"
                                                ] ? (
                                                    <>
                                                        {
                                                            memberDetails[
                                                                "Adresse Soc-1"
                                                            ]
                                                        }
                                                        <br />
                                                    </>
                                                ) : null}
                                                {memberDetails[
                                                    "Adresse Soc-2"
                                                ] ? (
                                                    <>
                                                        {
                                                            memberDetails[
                                                                "Adresse Soc-2"
                                                            ]
                                                        }
                                                        <br />
                                                    </>
                                                ) : null}
                                                {memberDetails[
                                                    "Adresse Soc-3"
                                                ] ? (
                                                    <>
                                                        {
                                                            memberDetails[
                                                                "Adresse Soc-3"
                                                            ]
                                                        }
                                                        <br />
                                                    </>
                                                ) : null}
                                                <AddressPartsLocalized
                                                    locale={locale}
                                                    zip={
                                                        memberDetails[
                                                            "Code postal Soc"
                                                        ] ? (
                                                            <>
                                                                {
                                                                    memberDetails[
                                                                        "Code postal Soc"
                                                                    ]
                                                                }{" "}
                                                            </>
                                                        ) : null
                                                    }
                                                    city={
                                                        memberDetails[
                                                            "Ville Soc"
                                                        ] ? (
                                                            <>
                                                                {
                                                                    memberDetails[
                                                                        "Ville Soc"
                                                                    ]
                                                                }{" "}
                                                            </>
                                                        ) : null
                                                    }
                                                    country={
                                                        memberDetails[
                                                            "Code pays Soc"
                                                        ] ? (
                                                            <>
                                                                <br />
                                                                <PaysController
                                                                    id={
                                                                        memberDetails[
                                                                            "Code pays Soc"
                                                                        ]
                                                                    }
                                                                >
                                                                    {({
                                                                        data,
                                                                    }) => {
                                                                        return data?.Pays;
                                                                    }}
                                                                </PaysController>
                                                            </>
                                                        ) : null
                                                    }
                                                />
                                            </span>
                                        </span>
                                    ) : null}
                                    {memberDetails["Téléphone Soc"] ? (
                                        <Button
                                            component="a"
                                            href={`tel:${memberDetails["Téléphone Soc"]}`}
                                        >
                                            <PhoneIcon
                                                className={classes.leftIcon}
                                            />{" "}
                                            {memberDetails["Téléphone Soc"]}
                                        </Button>
                                    ) : null}
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            ) : null}
            {memberDetails.email || memberDetails.hasCellPhones ? (
                <div className={classes.row}>
                    <Typography color="primary" variant="h3">
                        {translate("roster.details")}
                    </Typography>
                    {memberDetails.email ? (
                        <div>
                            <Button
                                component="a"
                                href={`mailto:${memberDetails.email}`}
                            >
                                <AlternateEmailIcon
                                    className={classes.leftIcon}
                                />{" "}
                                {memberDetails.email}
                            </Button>
                        </div>
                    ) : null}
                    {memberDetails.hasCellPhones ? (
                        <div>
                            {memberDetails["Portable 1"] ? (
                                <Button
                                    component="a"
                                    href={`tel:${memberDetails["Portable 1"]}`}
                                >
                                    <SmartphoneIcon
                                        className={classes.leftIcon}
                                    />{" "}
                                    {memberDetails["Portable 1"]}
                                </Button>
                            ) : null}
                            {memberDetails["Portable 2"] ? (
                                <Button
                                    component="a"
                                    href={`tel:${memberDetails["Portable 2"]}`}
                                >
                                    <SmartphoneIcon
                                        className={classes.leftIcon}
                                    />{" "}
                                    {memberDetails["Portable 2"]}
                                </Button>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            ) : null}
            {memberDetails.hasAddress1 ? (
                <div className={classes.row}>
                    <Typography color="primary" variant="h3">
                        {translate("roster.address1")}
                    </Typography>
                    <div className={classes.address}>
                        <span className={classes.addressWrapper}>
                            <HomeIcon className={classes.addressIcon} />
                            <span className={classes.addressContent}>
                                {memberDetails["Adresse 1-1"] ? (
                                    <>
                                        {memberDetails["Adresse 1-1"]}
                                        <br />
                                    </>
                                ) : null}
                                {memberDetails["Adresse 1-2"] ? (
                                    <>
                                        {memberDetails["Adresse 1-2"]}
                                        <br />
                                    </>
                                ) : null}
                                {memberDetails["Adresse 1-3"] ? (
                                    <>
                                        {memberDetails["Adresse 1-3"]}
                                        <br />
                                    </>
                                ) : null}
                                <AddressPartsLocalized
                                    locale={locale}
                                    zip={
                                        memberDetails["Code postal 1"] ? (
                                            <>
                                                {memberDetails["Code postal 1"]}{" "}
                                            </>
                                        ) : null
                                    }
                                    city={
                                        memberDetails["Ville 1"] ? (
                                            <>{memberDetails["Ville 1"]} </>
                                        ) : null
                                    }
                                    state={
                                        memberDetails["Région 1"] ? (
                                            <>{memberDetails["Région 1"]} </>
                                        ) : null
                                    }
                                    country={
                                        memberDetails["Code pays 1"] ? (
                                            <>
                                                <br />
                                                <PaysController
                                                    id={
                                                        memberDetails[
                                                            "Code pays 1"
                                                        ]
                                                    }
                                                >
                                                    {({ data }) => {
                                                        return data?.Pays;
                                                    }}
                                                </PaysController>
                                            </>
                                        ) : null
                                    }
                                />
                            </span>
                        </span>
                        {memberDetails["Téléphone 1"] ? (
                            <Button
                                component="a"
                                href={`tel:${memberDetails["Téléphone 1"]}`}
                            >
                                <PhoneIcon className={classes.leftIcon} />{" "}
                                {memberDetails["Téléphone 1"]}
                            </Button>
                        ) : null}
                    </div>
                </div>
            ) : null}
            {memberDetails.hasAddress2 ? (
                <div className={classes.row}>
                    <Typography color="primary" variant="h3">
                        {translate("roster.address2")}
                    </Typography>
                    <div className={classes.address}>
                        <span className={classes.addressWrapper}>
                            <HomeIcon className={classes.addressIcon} />
                            <span className={classes.addressContent}>
                                {memberDetails["Adresse 2-1"] ? (
                                    <>
                                        {memberDetails["Adresse 2-1"]}
                                        <br />
                                    </>
                                ) : null}
                                {memberDetails["Adresse 2-2"] ? (
                                    <>
                                        {memberDetails["Adresse 2-2"]}
                                        <br />
                                    </>
                                ) : null}
                                {memberDetails["Adresse 2-3"] ? (
                                    <>
                                        {memberDetails["Adresse 2-3"]}
                                        <br />
                                    </>
                                ) : null}
                                <AddressPartsLocalized
                                    locale={locale}
                                    zip={
                                        memberDetails["Code postal 2"] ? (
                                            <>
                                                {memberDetails["Code postal 2"]}{" "}
                                            </>
                                        ) : null
                                    }
                                    city={
                                        memberDetails["Ville 2"] ? (
                                            <>{memberDetails["Ville 2"]} </>
                                        ) : null
                                    }
                                    state={
                                        memberDetails["Région 2"] ? (
                                            <>{memberDetails["Région 2"]} </>
                                        ) : null
                                    }
                                    country={
                                        memberDetails["Code pays 2"] ? (
                                            <>
                                                <br />
                                                <PaysController
                                                    id={
                                                        memberDetails[
                                                            "Code pays 2"
                                                        ]
                                                    }
                                                >
                                                    {({ data }) => {
                                                        return data?.Pays;
                                                    }}
                                                </PaysController>
                                            </>
                                        ) : null
                                    }
                                />
                            </span>
                        </span>
                        {memberDetails["Téléphone 2"] ? (
                            <Button
                                component="a"
                                href={`tel:${memberDetails["Téléphone 2"]}`}
                            >
                                <PhoneIcon className={classes.leftIcon} />{" "}
                                {memberDetails["Téléphone 2"]}
                            </Button>
                        ) : null}
                    </div>
                </div>
            ) : null}
            {memberDetails.hasAddress3 ? (
                <div className={classes.row}>
                    <Typography color="primary" variant="h3">
                        {translate("roster.address3")}
                    </Typography>
                    <div className={classes.address}>
                        <span className={classes.addressWrapper}>
                            <HomeIcon className={classes.addressIcon} />
                            <span className={classes.addressContent}>
                                {memberDetails["Address #3 Street A"] ? (
                                    <>
                                        {memberDetails["Address #3 Street A"]}
                                        <br />
                                    </>
                                ) : null}
                                {memberDetails["Address #3 Street B"] ? (
                                    <>
                                        {memberDetails["Address #3 Street B"]}
                                        <br />
                                    </>
                                ) : null}
                                {memberDetails["Address #3 Street C"] ? (
                                    <>
                                        {memberDetails["Address #3 Street C"]}
                                        <br />
                                    </>
                                ) : null}
                                <AddressPartsLocalized
                                    locale={locale}
                                    zip={
                                        memberDetails["Address #3 Zip"] ? (
                                            <>
                                                {
                                                    memberDetails[
                                                        "Address #3 Zip"
                                                    ]
                                                }{" "}
                                            </>
                                        ) : null
                                    }
                                    city={
                                        memberDetails["Address #3 City"] ? (
                                            <>
                                                {
                                                    memberDetails[
                                                        "Address #3 City"
                                                    ]
                                                }{" "}
                                            </>
                                        ) : null
                                    }
                                    state={
                                        memberDetails["Address #3 State"] ? (
                                            <>
                                                {
                                                    memberDetails[
                                                        "Address #3 State"
                                                    ]
                                                }{" "}
                                            </>
                                        ) : null
                                    }
                                    country={
                                        memberDetails["Address #3 Country"] ? (
                                            <>
                                                <br />
                                                <PaysController
                                                    id={
                                                        memberDetails[
                                                            "Address #3 Country"
                                                        ]
                                                    }
                                                >
                                                    {({ data }) => {
                                                        return data?.Pays;
                                                    }}
                                                </PaysController>
                                            </>
                                        ) : null
                                    }
                                />
                            </span>
                        </span>
                    </div>
                </div>
            ) : null}
            {memberDetails.hasAddress4 ? (
                <div className={classes.row}>
                    <Typography color="primary" variant="h3">
                        {translate("roster.address4")}
                        {memberDetails["Seasonal Address Dates"] ? (
                            <>
                                <br />
                                <small>
                                    {translate("pages.account.addressDates")}:{" "}
                                    {memberDetails["Seasonal Address Dates"]}
                                </small>
                            </>
                        ) : null}
                    </Typography>
                    <div className={classes.address}>
                        <span className={classes.addressWrapper}>
                            <HomeIcon className={classes.addressIcon} />
                            <span className={classes.addressContent}>
                                {memberDetails["Seasonal Street"] ? (
                                    <>
                                        {memberDetails["Seasonal Street"]}
                                        <br />
                                    </>
                                ) : null}
                                {memberDetails["Seasonal Street #2"] ? (
                                    <>
                                        {memberDetails["Seasonal Street #2"]}
                                        <br />
                                    </>
                                ) : null}
                                {memberDetails["Seasonal Street #3"] ? (
                                    <>
                                        {memberDetails["Seasonal Street #3"]}
                                        <br />
                                    </>
                                ) : null}
                                <AddressPartsLocalized
                                    locale={locale}
                                    zip={
                                        memberDetails["Seasonal Zip"] ? (
                                            <>
                                                {memberDetails["Seasonal Zip"]}{" "}
                                            </>
                                        ) : null
                                    }
                                    city={
                                        memberDetails["Seasonal City"] ? (
                                            <>
                                                {memberDetails["Seasonal City"]}{" "}
                                            </>
                                        ) : null
                                    }
                                    state={
                                        memberDetails["Seasonal ST"] ? (
                                            <>{memberDetails["Seasonal ST"]} </>
                                        ) : null
                                    }
                                    country={
                                        memberDetails["Seasonal Country"] ? (
                                            <>
                                                <br />
                                                <PaysController
                                                    id={
                                                        memberDetails[
                                                            "Seasonal Country"
                                                        ]
                                                    }
                                                >
                                                    {({ data }) => {
                                                        return data?.Pays;
                                                    }}
                                                </PaysController>
                                            </>
                                        ) : null
                                    }
                                />
                            </span>
                        </span>
                    </div>
                </div>
            ) : null}
        </>
    ) : memberDetails.notShared ? (
        <Typography color="primary" variant="h3">
            {translate("roster.contactInfoNotShared")}
        </Typography>
    ) : (
        <Typography color="primary" variant="h3">
            {translate("roster.noContactInfo")}
        </Typography>
    );
};

export default MemberDetailsInfo;
