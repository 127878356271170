const US_STATES = [
    { id: "US-AK", name: "Alaska" },
    { id: "US-AL", name: "Alabama" },
    { id: "US-AZ", name: "Arizona" },
    { id: "US-AR", name: "Arkansas" },
    { id: "US-CA", name: "California" },
    { id: "US-CO", name: "Colorado" },
    { id: "US-CT", name: "Connecticut" },
    { id: "US-DE", name: "Delaware" },
    { id: "US-FL", name: "Florida" },
    { id: "US-GA", name: "Georgia" },
    { id: "US-HI", name: "Hawaii" },
    { id: "US-ID", name: "Idaho" },
    { id: "US-IL", name: "Illinois" },
    { id: "US-IN", name: "Indiana" },
    { id: "US-IA", name: "Iowa" },
    { id: "US-KS", name: "Kansas" },
    { id: "US-KY", name: "Kentucky" },
    { id: "US-LA", name: "Louisiana" },
    { id: "US-ME", name: "Maine" },
    { id: "US-MD", name: "Maryland" },
    { id: "US-MA", name: "Massachusetts" },
    { id: "US-MI", name: "Michigan" },
    { id: "US-MN", name: "Minnesota" },
    { id: "US-MS", name: "Mississippi" },
    { id: "US-MO", name: "Missouri" },
    { id: "US-MT", name: "Montana" },
    { id: "US-NE", name: "Nebraska" },
    { id: "US-NV", name: "Nevada" },
    { id: "US-NH", name: "New Hampshire" },
    { id: "US-NJ", name: "New Jersey" },
    { id: "US-NM", name: "New Mexico" },
    { id: "US-NY", name: "New York" },
    { id: "US-NC", name: "North Carolina" },
    { id: "US-ND", name: "North Dakota" },
    { id: "US-OH", name: "Ohio" },
    { id: "US-OK", name: "Oklahoma" },
    { id: "US-OR", name: "Oregon" },
    { id: "US-PA", name: "Pennsylvania" },
    { id: "US-RI", name: "Rhode Island" },
    { id: "US-SC", name: "South Carolina" },
    { id: "US-SD", name: "South Dakota" },
    { id: "US-TN", name: "Tennessee" },
    { id: "US-TX", name: "Texas" },
    { id: "US-UT", name: "Utah" },
    { id: "US-VT", name: "Vermont" },
    { id: "US-VA", name: "Virginia" },
    { id: "US-WA", name: "Washington" },
    { id: "US-WV", name: "West Virginia" },
    { id: "US-WI", name: "Wisconsin" },
    { id: "US-WY", name: "Wyoming" },
];

const CA_STATES = [
    { id: "CA-AB", name: "Alberta" },
    { id: "CA-BC", name: "British Columbia" },
    { id: "CA-MB", name: "Manitoba" },
    { id: "CA-NB", name: "New Brunswick" },
    { id: "CA-NL", name: "Newfoundland and Labrador" },
    { id: "CA-NS", name: "Nova Scotia" },
    { id: "CA-ON", name: "Ontario" },
    { id: "CA-PE", name: "Prince Edward Island" },
    { id: "CA-QC", name: "Quebec" },
    { id: "CA-SK", name: "Saskatchewan" },
    { id: "CA-NT", name: "Northwest Territories" },
    { id: "CA-NU", name: "Nunavut" },
    { id: "CA-YT", name: "Yukon Territory" },
];

module.exports = { CA_STATES, US_STATES };
