import React from "react";

const Separator = (props) => (
    <img
        src="/images/separation-filigrane.png"
        alt="-----"
        width="125"
        height="52"
        {...props}
    />
);

export default Separator;
