import React from "react";
import { useLogout, useTranslate } from "react-admin";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { Routes } from "../routes";
import Hidden from "@material-ui/core/Hidden";

import makeStyles from "@material-ui/core/styles/makeStyles";
import { isWidthDown } from "@material-ui/core/withWidth";
import RoundButton from "../components/RoundButton";
import Button from "@material-ui/core/Button";
import useWidth from "../../hooks/useWidth";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "ra-core";

const bandWidth = 116;
const sidebarWidth = {
    large: 300,
    small: bandWidth,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: sidebarWidth.large,
        "&$small": {
            width: sidebarWidth.small,
        },
        "&$horizontal": {
            width: "100%",
        },
        ".fade-enter &": {
            opacity: "0.01",
        },

        ".fade-enter.fade-enter-active &": {
            opacity: "1",
            transition: "opacity 150ms ease-in",
        },

        ".fade-exit &": {
            opacity: "1",
            zIndex: -1,
            // position: "absolute",
            // width: "100%",
            // top: "0",
            // left: "0",
        },

        ".fade-exit.fade-exit-active &": {
            opacity: "0.01",
            zIndex: -1,
            transition: "opacity 150ms 100ms ease-in",
            // position: "absolute",
            // width: "100%",
            // top: "0",
            // left: "0",
        },
    },
    small: {},
    large: {},
    horizontal: {},
    main: {
        width: sidebarWidth.large,
        backgroundImage: "url('/images/fondSidebar.png')",
        backgroundRepeat: "repeat-y",
        backgroundPosition: "top right",
        "$small &": {
            width: sidebarWidth.small,
        },
        "$horizontal &": {
            width: "100%",
            height: 40,

            textAlign: "center",
            backgroundImage: "url('/images/fondSidebarJaune.png')",

            backgroundRepeat: "repeat",
            backgroundPosition: "top right",
        },
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
    },
    header: {
        display: "flex",
        "$small &": {
            flexWrap: "wrap",
        },
        justifyContent: "space-between",
    },
    headerLink: {
        width: bandWidth,

        [theme.breakpoints.down("sm")]: {
            width: "auto",
            flexGrow: 0.1,
        },
    },
    sepa: {
        flex: 1,
        flexGrow: 1,
    },
    elem: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        width: "100%",

        "$horizontal &": {
            height: 40,
            flexWrap: "nowrap",
            flex: 1,
            justifyContent: "flex-end",
            flexGrow: 1,
            backgroundImage: "url('/images/fondSidebarRouge.png')",
            display: "flex",
            "&:first-child": {
                justifyContent: "flex-start",
            },
        },
    },
    menuLink: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: 40,
        padding: 0,
        color: "#fff",
        paddingLeft: 10,
        paddingRight: 10,
        flexGrow: 0.2,
        ...theme.typography.h4,
    },
    icon: {
        backgroundSize: "32px 32px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        minHeight: 32,
        minWidth: 32,
        marginRight: 5,
    },
}));

const Sidebar = ({
    className,
    variant = "small",
    content = null,
    openMenu,
    ...props
}) => {
    const logout = useLogout();
    const translate = useTranslate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const width = useWidth();
    const isSmall = isWidthDown("md", width) || variant === "small";
    const isHorizontal = isWidthDown("sm", width);
    const handleToggle = () => {
        dispatch(toggleSidebar());
    };
    return (
        <div
            className={classnames(classes.root, className, {
                [classes.small]: isSmall,
                [classes.large]: !isSmall,
                [classes.horizontal]: isHorizontal,
            })}
        >
            <div className={classes.main}>
                <header className={classes.header}>
                    <Hidden mdUp>
                        <span className={classes.elem}>
                            <Button
                                className={classes.menuLink}
                                onClick={handleToggle}
                            >
                                <span className={classes.icon}>
                                    <img alt="" src="/images/menu.svg" />
                                </span>
                                {translate("Menu")}
                            </Button>
                        </span>
                        <div className={classes.sepa}></div>
                    </Hidden>
                    <div className={classes.elem}>
                        <NavLink
                            component={RoundButton}
                            className={classes.headerLink}
                            to={Routes.compte}
                            srcUrl={"/images/user.svg"}
                            label={
                                <Hidden smDown>
                                    {translate("Mon compte")}
                                </Hidden>
                            }
                        ></NavLink>
                        <RoundButton
                            className={classes.headerLink}
                            onClick={() => logout()}
                            srcUrl={"/images/logout.svg"}
                            label={
                                <Hidden smDown>
                                    {translate("Déconnexion")}
                                </Hidden>
                            }
                        ></RoundButton>
                    </div>
                </header>
                <Hidden smDown> {content ? <div>{content}</div> : null}</Hidden>
            </div>
        </div>
    );
};

export default Sidebar;
