import CommanderieTitleHelper from "./CommanderieTitleHelper";
import ModelHelper from "./ModelHelper";

export default class MemberHasCommanderieHasTitleHelper extends ModelHelper {
    /**
     * @property {Number}
     */
    commanderieTitle;
    /**
     * @property {Number}
     */
    memberHasCommanderie;

    set CommanderieTitle(val) {
        this._CommanderieTitle = new CommanderieTitleHelper(val, this.locale);
    }
    /**
     * @returns {CommanderieTitleHelper}
     */
    get CommanderieTitle() {
        return this._CommanderieTitle;
    }
}
