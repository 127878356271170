import React, { useState } from "react";
import useCurrentUser from "../../hooks/useCurrentUser";
import Button from "@material-ui/core/Button";
import { usePermissions } from "react-admin";
import MemberDetailsSharingConsentDialog from "./MemberDetailsSharingConsentDialog";

const MemberDetailsSharingConsentButton = ({
    memberNumber,
    codeCommanderie,
    ...props
}) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                variant="contained"
                color="primary"
                {...props}
            />
            <MemberDetailsSharingConsentDialog
                memberNumber={memberNumber}
                codeCommanderie={codeCommanderie}
                open={open}
                handleClose={() => setOpen(false)}
                onSuccess={() => setOpen(false)}
            />
        </>
    );
};

export default MemberDetailsSharingConsentButton;
