import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { useTranslate } from "react-admin";
import MemberDetailsModal from "./MemberDetailsModal";

const MemberDetailsButton = ({
    member,
    component: Component = Button,
    children,
    className,
    ...props
}) => {
    const translate = useTranslate();
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Component onClick={handleClick} className={className} {...props}>
                {children ? children : translate("Voir les coordonnées")}
            </Component>
            <MemberDetailsModal
                handleClose={handleClose}
                open={open}
                member={member}
            />
        </>
    );
};

export default MemberDetailsButton;
