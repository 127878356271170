export function upperCaseFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export function lowerCaseAllWordsExceptFirstLetters(string) {
    return string
        ? string.replace(/\S*/g, function (word) {
              return word.charAt(0) + word.slice(1).toLowerCase();
          })
        : "";
}
