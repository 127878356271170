import ModelHelper from "./ModelHelper";

export default class CommanderieTitleHelper extends ModelHelper {
    /**
     * @property {string}
     */
    commanderie;
    /**
     * @property {Number}
     */
    index;
    /**
     * @property {string}
     */
    title;
    get i18nDataProperty() {
        return "CommanderieTitle_i18n";
    }
}
