import React, { useState, useRef } from "react";
import Page from "./Page";
import SousCommanderiesController from "../../controllers/SousCommanderiesController";
import MembreListController from "../../controllers/MembreListController";
import ErrorView from "../components/ErrorView";
import { Form } from "react-final-form";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
    TextInput,
    useTranslate,
    usePermissions,
    /*ReferenceInput,
    AutocompleteInput,*/
} from "react-admin";
import { OnChange } from "react-final-form-listeners";
import Button from "@material-ui/core/Button";
import MemberDetailsButton from "../components/MemberDetailsButton";
import GradeListController from "../../controllers/GradeListController";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";
import InputAdornment from "@material-ui/core/InputAdornment";
import SousCommanderieHelper from "../../helpers/SousCommanderieHelper";
import { useDebounce } from "../../hooks/useDebounce";
import IconButton from "@material-ui/core/IconButton";
import useWidth from "../../hooks/useWidth";
import { isWidthDown } from "@material-ui/core/withWidth";
import MemberDetailsTitle from "../components/MemberDetailsTitle";
import MemberDetailsTitleSC from "../components/MemberDetailsTitleSC";
import MemberDetailsName from "../components/MemberDetailsName";
import Fade from "react-reveal/Fade";
import MUIFade from "@material-ui/core/Fade";
import useCurrentUser from "../../hooks/useCurrentUser";
import MemberDetailsCommittee from "../components/MemberDetailsCommittee";
import MemberDetailsSharingConsentButton from "../components/MemberDetailsSharingConsentButton";
import FichierListController from "../../controllers/FichierListController";
import MediaController from "../../controllers/MediaController";

const useRosterStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 1024,
        marginTop: theme.spacing(4),
        marginLeft: "auto",
        marginRight: "auto",
    },
    form: {
        display: "flex",
        alignItems: "center",
    },
    fieldSearch: {},
    fieldCommanderie: {
        minWidth: 200,
        marginLeft: theme.spacing(1),
        display: "none",
    },
    buttonSousCommanderies: {
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down("xs")]: {
            float: "right",
        },
    },
    buttonAllSCsWrap: {
        position: "relative",
        zIndex: 1,
    },
    memberList: {
        margin: theme.spacing(0, 0, 1),
    },
    nbResults: {
        "&$nbResults$nbResults": {
            // plus grande spécificité du sélecteur
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    memberListItem: {
        ...theme.typography.body1,
        color: theme.palette.common.black,
        display: "block",
        width: "100%",
        textAlign: "left",
        padding: theme.spacing(1, 2),
        margin: theme.spacing(0, -2),
        border: `1px solid ${theme.palette.background.default}`,
        borderLeft: 0,
        borderRight: 0,
        "&:not(:first-of-type)": {
            borderTop: 0,
        },
    },
    memberListItemHeader: {
        margin: theme.spacing(0),
    },
    memberListItemContent: {
        display: "flex",
        alignItems: "center",
    },
    memberListItemMain: {
        flexGrow: 1,
    },
    memberListItemSecondary: {
        flexGrow: 0,
        flexShrink: 0,
    },
    memberListItemLine: {
        "& > span:not(:first-of-type)": {
            "&:before": {
                whiteSpace: "pre",
                content: "'  •  '",
                display: "inline",
            },
        },
    },
    memberListItemLineSCTitle: {
        "& > span:not(:first-of-type)": {
            margin: theme.spacing(0),
            "&:before": {
                whiteSpace: "pre",
                content: "'  •  '",
                display: "inline",
            },
        },
    },
    memberListItemLineGray: {
        color: theme.typography.button.color,
    },
    memberListItemLineMemberName: {
        fontWeight: "bold",
    },
    listCommanderies: {
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(4, -2),
    },
    listCommanderiesItem: {
        border: `1px solid ${theme.palette.background.default}`,
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
        padding: theme.spacing(1, 2),
        boxSizing: "border-box",
        flexBasis: "20%",
        justifyContent: "flex-start",
        textAlign: "left",
        [theme.breakpoints.down("md")]: {
            flexBasis: "33%",
        },
        [theme.breakpoints.down("xs")]: {
            flexBasis: "50%",
        },
    },
    listCommanderiesItemMySC: {
        fontWeight: "bold",
    },
    buttonTypography: {
        cursor: "pointer",
    },
    spaceBetweenSCNameAndButton: {
        [theme.breakpoints.down("xs")]: {
            display: "block",
        },
    },
    subTitle: {
        "&$subTitle$subTitle": {
            // spécificité du sélecteur
            lineHeight: theme.spacing(5) + "px", // même hauteur qu'il y ait ou non un bouton dans la ligne
        },
    },
}));

const makeFilters = (commanderie, search) => {
    const filter = {};
    if (typeof commanderie !== "undefined" && commanderie) {
        filter["hasSousCommanderie"] = commanderie;
    }
    if (search) {
        filter._search = search;
    }
    return filter;
};

/**
 * Recherche
 * /membres?search=John
 *
 * Liste sous commanderies
 * /souscommanderies
 *
 * Liste des membres d'une sous-commanderie
 * /membres?filter={"souscommanderie":1}
 */

const Roster = (props) => {
    const width = useWidth();
    const permissions = usePermissions();
    const isExtraSmall = isWidthDown("xs", width);
    const user = useCurrentUser();
    const classes = useRosterStyles();
    const translate = useTranslate();
    const formRef = useRef();
    const [commanderie, setCommanderie] = useState();
    const [allCommanderies, setAllCommanderies] = useState();
    const [search, setSearch] = useState();
    const [grades, setGrades] = useState();
    const debouncedSearch = useDebounce(search, 300);
    const membersFilter = makeFilters(commanderie, debouncedSearch);

    const getCommanderieDisplayName = () => {
        if (!commanderie) {
            return null;
        }
        const foundCommanderie = allCommanderies.find(
            (sousCommanderie) => sousCommanderie.id === commanderie
        );
        return foundCommanderie ? foundCommanderie.displayName : null;
    };
    const getCommanderieCurrent = () => {
        if (!commanderie) {
            return null;
        }
        const foundCommanderie = allCommanderies.find(
            (sousCommanderie) => sousCommanderie.id === commanderie
        );
        return foundCommanderie ? foundCommanderie : null;
    };

    const handleSearchChange = (value, form) => {
        setSearch(value);
    };
    const handleCommanderieChange = (value, form) => {
        setCommanderie(value);
    };
    const onClickCommanderie = (value) => {
        formRef &&
            formRef.current &&
            formRef.current.mutators &&
            formRef.current.mutators.setCommanderie(value);
        value && resetSearch();
    };
    const resetSearch = () => {
        formRef &&
            formRef.current &&
            formRef.current.mutators &&
            formRef.current.mutators.setSearch("");
    };
    const buttonAllSCs = (
        <Button
            color="primary"
            className={classes.buttonSousCommanderies}
            onClick={() => onClickCommanderie(null)}
        >
            {translate("Toutes les sous-commanderies")}
        </Button>
    );
    return (
        <Page {...props}>
            <div className={classes.root}>
                <GradeListController withLoader={false} onLoaded={setGrades}>
                    {({ data: grades, error, loaded }) => null}
                </GradeListController>
                <h1>
                    {translate("pages.roster.title")}{" "}
                    {user && permissions?.permissions?.rosterAccess && (
                        <MemberDetailsSharingConsentButton
                            variant="outlined"
                            memberNumber={user.memberNumber}
                            codeCommanderie={
                                permissions?.permissions?.rosterAccess
                            }
                            size="small"
                        >
                            {translate(
                                "pages.roster.contactDetailsSharingSettings"
                            )}
                        </MemberDetailsSharingConsentButton>
                    )}{" "}
                    <FichierListController withLoader={false}>
                        {({ data, loaded, error }) => {
                            const fichier = data ? data[0] : null;
                            return fichier?.media ? (
                                <MediaController id={fichier.media}>
                                    {({ loaded, media }) => {
                                        return media && media.src ? (
                                            <MUIFade in key="button">
                                                <Button
                                                    size="small"
                                                    component={"a"}
                                                    href={media.src}
                                                    target="_blank"
                                                    variant="contained"
                                                    color="secondary"
                                                >
                                                    {fichier?.buttonText ||
                                                        translate(
                                                            "telecharger_roster"
                                                        )}
                                                </Button>
                                            </MUIFade>
                                        ) : null;
                                    }}
                                </MediaController>
                            ) : null;
                        }}
                    </FichierListController>
                </h1>
                <Fade key={!!commanderie}>
                    {commanderie ? (
                        <h2 className={classes.subTitle}>
                            {getCommanderieDisplayName()}
                            <span
                                className={classes.spaceBetweenSCNameAndButton}
                            >
                                {" "}
                            </span>
                            {!isExtraSmall ? buttonAllSCs : null}
                        </h2>
                    ) : (
                        <h2 className={classes.subTitle}>
                            {translate("Toutes les sous-commanderies")}
                        </h2>
                    )}
                </Fade>
                <Fade>
                    <Form
                        {...props}
                        onSubmit={(...args) => console.log("submit", args)}
                        mutators={{
                            setCommanderie: ([value], state, utils) => {
                                utils.changeValue(
                                    state,
                                    "commanderie",
                                    () => value
                                );
                            },
                            setSearch: ([value], state, utils) => {
                                utils.changeValue(state, "search", () => value);
                            },
                        }}
                        render={({
                            handleSubmitWithRedirect,
                            invalid,
                            form,
                            ...formProps
                        }) => {
                            formRef.current = form;
                            return (
                                <form className={classes.form}>
                                    <TextInput
                                        fullWidth={!!isExtraSmall}
                                        className={classes.fieldSearch}
                                        variant="outlined"
                                        source="search"
                                        label="roster.searchLabel"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={resetSearch}
                                                        edge="end"
                                                        disabled={!search}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <OnChange name="search">
                                        {(value, previous) =>
                                            handleSearchChange(value, form)
                                        }
                                    </OnChange>
                                    {/* <ReferenceInput
                                        perPage={100}
                                        className={classes.fieldCommanderie}
                                        variant="outlined"
                                        allowEmpty
                                        source="commanderie"
                                        reference="souscommanderie"
                                        sort={{
                                            field: "Intitulé",
                                            order: "ASC",
                                        }}
                                        filterToQuery={(searchText) => ({
                                            _search: searchText,
                                        })}
                                        filter={{
                                            Type: "Sous-commanderie",
                                        }}
                                        label={translate("Sous-commanderie")}
                                    >
                                        <AutocompleteInput
                                            matchSuggestion={() => true}
                                            optionText={(record) =>
                                                record && record.id
                                                    ? new SousCommanderieHelper(
                                                          record
                                                      ).displayName
                                                    : record
                                                    ? translate(
                                                          "Toutes les sous-commanderies"
                                                      )
                                                    : ""
                                            }
                                        />
                                    </ReferenceInput> */}
                                    <TextInput
                                        source="commanderie"
                                        className={classes.fieldCommanderie}
                                    />
                                    <OnChange name="commanderie">
                                        {(value, previous) =>
                                            handleCommanderieChange(value, form)
                                        }
                                    </OnChange>
                                </form>
                            );
                        }}
                    />
                </Fade>

                {isExtraSmall && commanderie ? (
                    <Fade>
                        <div className={classes.buttonAllSCsWrap}>
                            {buttonAllSCs}
                        </div>
                    </Fade>
                ) : null}

                {membersFilter &&
                (membersFilter._search ||
                    membersFilter["hasSousCommanderie"]) ? (
                    <Fade>
                        <MembreListController
                            filter={membersFilter}
                            perPage={1500}
                            sort={[
                                {
                                    field: "rosterIndex",
                                    order: "ASC",
                                },
                                {
                                    field: "Code grade",
                                    order: "DESC",
                                },
                                {
                                    field: "Nom propre",
                                    order: "ASC",
                                    fn: "lower",
                                },
                                {
                                    field: "Prénom",
                                    order: "ASC",
                                    fn: "lower",
                                },
                                {
                                    field: "N° Membre",
                                    order: "ASC",
                                },
                            ]}
                        >
                            {({ loaded, error, data, total }) => (
                                <Fade
                                    key={JSON.stringify({
                                        loaded,
                                        error,
                                        data: data.map(({ id }) => id),
                                        total,
                                    })}
                                >
                                    {data && data.length ? (
                                        <div className={classes.memberList}>
                                            <h3 className={classes.nbResults}>
                                                {translate(
                                                    "{{count}} résultats",
                                                    {
                                                        count:
                                                            total ||
                                                            data.length, // total is wrong on react-admin 3.3.3 but worse bug is on 3.4.0->3.6.2 https://github.com/marmelab/react-admin/issues/4996
                                                    }
                                                )}
                                            </h3>
                                            {data.map((membre, index) => {
                                                const grade = grades
                                                    ? grades.find(
                                                          ({
                                                              "N° Grade": codeGrade,
                                                          }) =>
                                                              codeGrade ===
                                                              membre.codeGrade
                                                      )
                                                    : null;
                                                const sousCommanderie = allCommanderies
                                                    ? allCommanderies.find(
                                                          ({
                                                              "N° Sous-commanderie": idCommanderie,
                                                          }) =>
                                                              idCommanderie ===
                                                              membre.codeHomeSousCommanderie
                                                      )
                                                    : null;
                                                return (
                                                    <MemberDetailsButton
                                                        className={
                                                            classes.memberListItem
                                                        }
                                                        member={membre}
                                                        key={membre.id}
                                                    >
                                                        {membre.hasNationalTitle ? (
                                                            <div
                                                                className={
                                                                    classes.memberListItemHeader
                                                                }
                                                            >
                                                                <MemberDetailsTitle
                                                                    membre={
                                                                        membre
                                                                    }
                                                                    className={
                                                                        classes.memberListItemLine
                                                                    }
                                                                />
                                                            </div>
                                                        ) : null}

                                                        <div
                                                            className={
                                                                classes.memberListItemContent
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    classes.memberListItemMain
                                                                }
                                                            >
                                                                <MemberDetailsName
                                                                    membre={
                                                                        membre
                                                                    }
                                                                    sousCommanderie={
                                                                        getCommanderieCurrent()
                                                                            ? getCommanderieCurrent()
                                                                            : sousCommanderie
                                                                    }
                                                                    className={classnames(
                                                                        classes.memberListItemLine,
                                                                        classes.memberListItemLineMemberName
                                                                    )}
                                                                />
                                                                <Typography
                                                                    component="div"
                                                                    variant="body1"
                                                                    className={
                                                                        classes.memberListItemLine
                                                                    }
                                                                >
                                                                    {membre &&
                                                                    membre.dateIntronisation ? (
                                                                        <span>
                                                                            {translate(
                                                                                "Intronisé en {{date, YYYY}}",
                                                                                {
                                                                                    date:
                                                                                        membre.dateIntronisation,
                                                                                }
                                                                            )}
                                                                        </span>
                                                                    ) : null}
                                                                    {grade ? (
                                                                        <span>
                                                                            {
                                                                                grade.displayName
                                                                            }
                                                                        </span>
                                                                    ) : null}
                                                                    {commanderie ||
                                                                    isExtraSmall ? null : sousCommanderie ? (
                                                                        <Typography
                                                                            component="span"
                                                                            color="primary"
                                                                        >
                                                                            {
                                                                                sousCommanderie.displayName
                                                                            }
                                                                        </Typography>
                                                                    ) : null}
                                                                </Typography>
                                                                <div
                                                                    className={
                                                                        classes.memberListItemLine
                                                                    }
                                                                >
                                                                    {commanderie
                                                                        ? membre
                                                                              .getTitlesFor(
                                                                                  commanderie
                                                                              )
                                                                              ?.map(
                                                                                  (
                                                                                      title,
                                                                                      index
                                                                                  ) => (
                                                                                      <MemberDetailsTitleSC
                                                                                          key={
                                                                                              index
                                                                                          }
                                                                                          component="span"
                                                                                          title={
                                                                                              title
                                                                                                  .CommanderieTitle
                                                                                                  .title
                                                                                          }
                                                                                          membre={
                                                                                              membre
                                                                                          }
                                                                                      />
                                                                                  )
                                                                              )
                                                                        : null}
                                                                    {commanderie ||
                                                                    !isExtraSmall ? null : sousCommanderie ? (
                                                                        <Typography
                                                                            component="span"
                                                                            color="primary"
                                                                        >
                                                                            {
                                                                                sousCommanderie.displayName
                                                                            }
                                                                        </Typography>
                                                                    ) : null}
                                                                </div>
                                                                <div
                                                                    className={
                                                                        classes.memberListItemLine
                                                                    }
                                                                >
                                                                    {membre.hasCommittee ? (
                                                                        <MemberDetailsCommittee
                                                                            component="span"
                                                                            membre={
                                                                                membre
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </MemberDetailsButton>
                                                );
                                            })}
                                        </div>
                                    ) : error ? (
                                        <ErrorView error={error} />
                                    ) : loaded ? (
                                        <h3 className={classes.nbResults}>
                                            {translate("Pas de résultats")}
                                        </h3>
                                    ) : null}
                                </Fade>
                            )}
                        </MembreListController>
                    </Fade>
                ) : null}
                {!(commanderie || search) ? (
                    <Fade>
                        <SousCommanderiesController
                            onLoaded={setAllCommanderies}
                            sort={{ field: "Intitulé", order: "ASC" }}
                            perPage={100}
                            filter={{ Type: "Sous-commanderie" }}
                        >
                            {({ loaded, error, data }) =>
                                data && data.length ? (
                                    <div className={classes.listCommanderies}>
                                        {data.map((souscommanderie, index) => (
                                            <Button
                                                className={classnames(
                                                    classes.listCommanderiesItem,
                                                    user &&
                                                        user.codeHomeSousCommanderie &&
                                                        user.codeHomeSousCommanderie ===
                                                            souscommanderie.id
                                                        ? classes.listCommanderiesItemMySC
                                                        : null
                                                )}
                                                color={
                                                    user?.isInSousCommanderie(
                                                        souscommanderie.id
                                                    )
                                                        ? "secondary"
                                                        : "default"
                                                }
                                                key={index}
                                                onClick={() =>
                                                    onClickCommanderie(
                                                        souscommanderie.id
                                                    )
                                                }
                                            >
                                                {souscommanderie.displayName}
                                            </Button>
                                        ))}
                                    </div>
                                ) : null
                            }
                        </SousCommanderiesController>
                    </Fade>
                ) : null}
            </div>
        </Page>
    );
};

export { useRosterStyles };

export default Roster;
