import React from "react";
import { Prompt } from "react-router";
import SimpleSaveButton from "../components/SimpleSaveButton";
import {
    useTranslate,
    useLocale,
    FormWithRedirect,
    Edit,
    TextInput,
    maxLength,
    TextField,
} from "react-admin";
import { isWidthDown } from "@material-ui/core/withWidth";
import CCTFormLabel from "../components/CCTFormLabel";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import AddressPartsLocalized from "./AddressPartsLocalized";
import PaysInput from "./PaysInput";
import useWidth from "../../hooks/useWidth";
import { Routes } from "../routes";

const MemberContactEdit = ({ classes, id, ...props }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const width = useWidth();
    const isSmall = isWidthDown("md", width);
    return (
        <Edit
            resource={"membre"}
            basePath={Routes.compte}
            id={id}
            undoable={false}
            component="div"
            classes={{ main: classes.edit }}
        >
            <FormWithRedirect
                {...props}
                render={({
                    handleSubmitWithRedirect,
                    invalid,
                    form,
                    pristine,
                    saving,
                    submitting,
                    ...formProps
                }) => {
                    const formState = form.getState();
                    const hasUnsavedChanges =
                        formState.dirty &&
                        (!formState.submitSucceeded ||
                            (formState.submitSucceeded &&
                                formState.dirtySinceLastSubmit));
                    return (
                        <form className={classes.form}>
                            <Prompt
                                when={hasUnsavedChanges}
                                message={translate(
                                    "pages.account.promptUnsavedChanges"
                                )}
                            />
                            <div className={classes.formLine}>
                                <div className={classes.formLineInner}>
                                    <CCTFormLabel className={classes.formLabel}>
                                        {translate("pages.account.nom")}
                                    </CCTFormLabel>
                                    <Typography
                                        variant="h3"
                                        component="span"
                                        className={classes.formLineTextValue}
                                    >
                                        {formProps?.record?.["Nom propre"]}
                                    </Typography>
                                </div>
                            </div>
                            <div className={classes.formLine}>
                                <div className={classes.formLineInner}>
                                    <CCTFormLabel className={classes.formLabel}>
                                        {translate("pages.account.prenom")}
                                    </CCTFormLabel>
                                    <Typography
                                        variant="h3"
                                        component="span"
                                        className={classes.formLineTextValue}
                                    >
                                        {formProps?.record?.Prénom}
                                    </Typography>
                                </div>
                            </div>
                            <div className={classes.formLine}>
                                <div className={classes.formLineInner}>
                                    <CCTFormLabel className={classes.formLabel}>
                                        {translate(
                                            "pages.account.fieldNickname"
                                        )}
                                    </CCTFormLabel>
                                    <TextInput
                                        source="Surnom"
                                        inputProps={{
                                            size: "12",
                                        }}
                                        placeholder={translate(
                                            "pages.account.fieldNickname"
                                        )}
                                        label={translate(
                                            "pages.account.fieldNickname"
                                        )}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                            <div className={classes.formLine}>
                                <div
                                    className={classnames(
                                        classes.formLineInner,
                                        classes.formLineInnerWithBlock
                                    )}
                                >
                                    <CCTFormLabel className={classes.formLabel}>
                                        {translate("pages.account.conjoint")}
                                    </CCTFormLabel>
                                    <div
                                        className={classnames(
                                            classes.formLineBlock,
                                            classes.formLineFullWidth
                                        )}
                                    >
                                        <div
                                            className={classnames(
                                                classes.formLineBlock,
                                                classes.formLineFlex,
                                                classes.formLineFullWidth
                                            )}
                                        >
                                            <TextInput
                                                source="Spouse First Name"
                                                inputProps={{
                                                    size: "12",
                                                }}
                                                placeholder={translate(
                                                    "pages.account.fieldFirstName"
                                                )}
                                                label={translate(
                                                    "pages.account.fieldFirstName"
                                                )}
                                                variant="outlined"
                                            />
                                            <TextInput
                                                source="Spouse Last Name"
                                                inputProps={{
                                                    size: "12",
                                                }}
                                                placeholder={translate(
                                                    "pages.account.fieldLastName"
                                                )}
                                                label={translate(
                                                    "pages.account.fieldLastName"
                                                )}
                                                variant="outlined"
                                            />
                                            <TextInput
                                                source="Spouse Nick Name"
                                                inputProps={{
                                                    size: "12",
                                                }}
                                                placeholder={translate(
                                                    "pages.account.fieldNickname"
                                                )}
                                                label={translate(
                                                    "pages.account.fieldNickname"
                                                )}
                                                variant="outlined"
                                            />
                                        </div>
                                        {formProps?.record?.["Spouse Title"] ? (
                                            <Typography>
                                                {translate(
                                                    "pages.account.spouseTitle"
                                                )}
                                                {" : "}
                                                {
                                                    formProps?.record?.[
                                                        "Spouse Title"
                                                    ]
                                                }
                                            </Typography>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.formLine}>
                                <div
                                    className={classnames(
                                        classes.formLineInner,
                                        classes.formLineInnerWithBlock
                                    )}
                                >
                                    <CCTFormLabel className={classes.formLabel}>
                                        {translate("pages.account.business")}
                                    </CCTFormLabel>
                                    <div
                                        className={classnames(
                                            classes.formLineBlock,
                                            classes.formLineFullWidth
                                        )}
                                    >
                                        <div
                                            className={classnames(
                                                classes.formLineBlock,
                                                classes.formLineFlex,
                                                classes.formLineFullWidth
                                            )}
                                        >
                                            <TextInput
                                                source="Profession"
                                                inputProps={{
                                                    size: "12",
                                                }}
                                                placeholder={translate(
                                                    "pages.account.profession"
                                                )}
                                                label={translate(
                                                    "pages.account.profession"
                                                )}
                                                variant="outlined"
                                            />
                                            <TextInput
                                                source="Société"
                                                inputProps={{
                                                    size: "12",
                                                }}
                                                placeholder={translate(
                                                    "pages.account.societe"
                                                )}
                                                label={translate(
                                                    "pages.account.societe"
                                                )}
                                                variant="outlined"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classnames(classes.formLine)}>
                                <div
                                    className={classnames(
                                        classes.formLineInner,
                                        classes.formLineInnerWithBlock
                                    )}
                                >
                                    <CCTFormLabel
                                        className={classes.formLabel}
                                        htmlFor={`ch1`}
                                    >
                                        {translate("pages.account.adresseSoc")}
                                    </CCTFormLabel>
                                    <div className={classes.formLineBlock}>
                                        <TextInput
                                            fullWidth
                                            source="Adresse Soc-1"
                                            id={`ch1`}
                                            placeholder={translate(
                                                "pages.account.adresseLine1"
                                            )}
                                            autoComplete="street-address address-line1"
                                            label={translate(
                                                "pages.account.adresseLine1"
                                            )}
                                            variant="outlined"
                                        />
                                        <TextInput
                                            fullWidth
                                            source="Adresse Soc-2"
                                            placeholder={translate(
                                                "pages.account.adresseLine2"
                                            )}
                                            autoComplete="street-address address-line2"
                                            label={translate(
                                                "pages.account.adresseLine2"
                                            )}
                                            variant="outlined"
                                        />
                                        <TextInput
                                            fullWidth
                                            source="Adresse Soc-3"
                                            placeholder={translate(
                                                "pages.account.adresseLine3"
                                            )}
                                            autoComplete="street-address address-line3"
                                            label={translate(
                                                "pages.account.adresseLine3"
                                            )}
                                            variant="outlined"
                                        />
                                        <div
                                            className={classnames(
                                                classes.formLineBlock,
                                                classes.formLineFlex
                                            )}
                                        >
                                            <AddressPartsLocalized
                                                locale={locale}
                                                zip={
                                                    <TextInput
                                                        source="Code postal Soc"
                                                        inputProps={{
                                                            size: "9",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.cp"
                                                        )}
                                                        label={translate(
                                                            "pages.account.cp"
                                                        )}
                                                        autoComplete="postal-code"
                                                        variant="outlined"
                                                    />
                                                }
                                                city={
                                                    <TextInput
                                                        source="Ville Soc"
                                                        inputProps={{
                                                            size: "18",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.ville"
                                                        )}
                                                        autoComplete="address-level2"
                                                        label={translate(
                                                            "pages.account.ville"
                                                        )}
                                                        variant="outlined"
                                                    />
                                                }
                                                country={
                                                    <PaysInput
                                                        source="Code pays Soc"
                                                        inputProps={{
                                                            size: "9",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.pays"
                                                        )}
                                                        label={translate(
                                                            "pages.account.pays"
                                                        )}
                                                        variant="outlined"
                                                    />
                                                }
                                            />
                                        </div>
                                        <div className={classes.formLineBlock}>
                                            <TextInput
                                                source="Téléphone Soc"
                                                size="small"
                                                placeholder={translate(
                                                    "pages.account.telephone"
                                                )}
                                                label={translate(
                                                    "pages.account.telephone"
                                                )}
                                                variant="outlined"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.formLine}>
                                <div
                                    className={classnames(
                                        classes.formLineInner,
                                        classes.formLineInnerWithBlock
                                    )}
                                >
                                    <CCTFormLabel
                                        className={classes.formLabel}
                                        htmlFor={`tel1`}
                                    >
                                        {translate("pages.account.portables")}
                                    </CCTFormLabel>
                                    <div className={classes.formLineBlock}>
                                        <TextInput
                                            source="Portable 1"
                                            placeholder={translate(
                                                "pages.account.portable1"
                                            )}
                                            label={translate(
                                                "pages.account.portable1"
                                            )}
                                            variant="outlined"
                                        />
                                        <TextInput
                                            source="Portable 2"
                                            placeholder={translate(
                                                "pages.account.portable2"
                                            )}
                                            label={translate(
                                                "pages.account.portable2"
                                            )}
                                            variant="outlined"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classnames(classes.formLine)}>
                                <div
                                    className={classnames(
                                        classes.formLineInner,
                                        classes.formLineInnerWithBlock
                                    )}
                                >
                                    <CCTFormLabel
                                        className={classes.formLabel}
                                        htmlFor={`ch1`}
                                    >
                                        {translate("pages.account.adresse1")}
                                    </CCTFormLabel>
                                    <div className={classes.formLineBlock}>
                                        <TextInput
                                            fullWidth
                                            source="Adresse 1-1"
                                            id={`ch1`}
                                            placeholder={translate(
                                                "pages.account.adresseLine1"
                                            )}
                                            autoComplete="street-address address-line1"
                                            label={translate(
                                                "pages.account.adresseLine1"
                                            )}
                                            variant="outlined"
                                        />
                                        <TextInput
                                            fullWidth
                                            source="Adresse 1-2"
                                            placeholder={translate(
                                                "pages.account.adresseLine2"
                                            )}
                                            autoComplete="street-address address-line2"
                                            label={translate(
                                                "pages.account.adresseLine2"
                                            )}
                                            variant="outlined"
                                        />
                                        <TextInput
                                            fullWidth
                                            source="Adresse 1-3"
                                            placeholder={translate(
                                                "pages.account.adresseLine3"
                                            )}
                                            autoComplete="street-address address-line3"
                                            label={translate(
                                                "pages.account.adresseLine3"
                                            )}
                                            variant="outlined"
                                        />
                                        <div
                                            className={classnames(
                                                classes.formLineBlock,
                                                classes.formLineFlex
                                            )}
                                        >
                                            <AddressPartsLocalized
                                                locale={locale}
                                                zip={
                                                    <TextInput
                                                        source="Code postal 1"
                                                        inputProps={{
                                                            size: "9",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.cp"
                                                        )}
                                                        label={translate(
                                                            "pages.account.cp"
                                                        )}
                                                        autoComplete="postal-code"
                                                        variant="outlined"
                                                    />
                                                }
                                                city={
                                                    <TextInput
                                                        source="Ville 1"
                                                        inputProps={{
                                                            size: "18",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.ville"
                                                        )}
                                                        autoComplete="address-level2"
                                                        label={translate(
                                                            "pages.account.ville"
                                                        )}
                                                        variant="outlined"
                                                    />
                                                }
                                                state={
                                                    <TextInput
                                                        source="Région 1"
                                                        inputProps={{
                                                            size: "10",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.region"
                                                        )}
                                                        autoComplete="address-level1"
                                                        label={translate(
                                                            "pages.account.region"
                                                        )}
                                                        variant="outlined"
                                                    />
                                                }
                                                country={
                                                    <PaysInput
                                                        source="Code pays 1"
                                                        inputProps={{
                                                            size: "9",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.pays"
                                                        )}
                                                        label={translate(
                                                            "pages.account.pays"
                                                        )}
                                                        variant="outlined"
                                                    />
                                                }
                                            />
                                        </div>
                                        <div className={classes.formLineBlock}>
                                            <TextInput
                                                source="Téléphone 1"
                                                size="small"
                                                placeholder={translate(
                                                    "pages.account.telephone"
                                                )}
                                                label={translate(
                                                    "pages.account.telephone"
                                                )}
                                                variant="outlined"
                                            />
                                            <TextInput
                                                source="Fax 1"
                                                size="small"
                                                placeholder={translate(
                                                    "pages.account.fax"
                                                )}
                                                label={translate(
                                                    "pages.account.fax"
                                                )}
                                                variant="outlined"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.formLine}>
                                <div
                                    className={classnames(
                                        classes.formLineInner,
                                        classes.formLineInnerWithBlock
                                    )}
                                >
                                    <CCTFormLabel
                                        className={classes.formLabel}
                                        htmlFor={`ch2`}
                                    >
                                        {translate("pages.account.adresse2")}
                                    </CCTFormLabel>
                                    <div className={classes.formLineBlock}>
                                        <TextInput
                                            fullWidth
                                            source="Adresse 2-1"
                                            id={`ch2`}
                                            placeholder={translate(
                                                "pages.account.adresseLine1"
                                            )}
                                            label={translate(
                                                "pages.account.adresseLine1"
                                            )}
                                            autoComplete="street-address address-line1"
                                            variant="outlined"
                                        />
                                        <TextInput
                                            fullWidth
                                            source="Adresse 2-2"
                                            placeholder={translate(
                                                "pages.account.adresseLine2"
                                            )}
                                            autoComplete="street-address address-line2"
                                            label={translate(
                                                "pages.account.adresseLine2"
                                            )}
                                            variant="outlined"
                                        />
                                        <TextInput
                                            fullWidth
                                            source="Adresse 2-3"
                                            placeholder={translate(
                                                "pages.account.adresseLine3"
                                            )}
                                            autoComplete="street-address address-line3"
                                            label={translate(
                                                "pages.account.adresseLine3"
                                            )}
                                            variant="outlined"
                                        />
                                        <div
                                            className={classnames(
                                                classes.formLineBlock,
                                                classes.formLineFlex
                                            )}
                                        >
                                            <AddressPartsLocalized
                                                locale={locale}
                                                zip={
                                                    <TextInput
                                                        source="Code postal 2"
                                                        inputProps={{
                                                            size: "9",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.cp"
                                                        )}
                                                        label={translate(
                                                            "pages.account.cp"
                                                        )}
                                                        autoComplete="postal-code"
                                                        variant="outlined"
                                                    />
                                                }
                                                city={
                                                    <TextInput
                                                        source="Ville 2"
                                                        inputProps={{
                                                            size: "18",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.ville"
                                                        )}
                                                        autoComplete="address-level2"
                                                        label={translate(
                                                            "pages.account.ville"
                                                        )}
                                                        variant="outlined"
                                                    />
                                                }
                                                state={
                                                    <TextInput
                                                        source="Région 2"
                                                        inputProps={{
                                                            size: "10",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.region"
                                                        )}
                                                        autoComplete="address-level1"
                                                        label={translate(
                                                            "pages.account.region"
                                                        )}
                                                        variant="outlined"
                                                    />
                                                }
                                                country={
                                                    <PaysInput
                                                        source="Code pays 2"
                                                        inputProps={{
                                                            size: "9",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.pays"
                                                        )}
                                                        label={translate(
                                                            "pages.account.pays"
                                                        )}
                                                        variant="outlined"
                                                    />
                                                }
                                            />
                                        </div>
                                        <div className={classes.formLineBlock}>
                                            <TextInput
                                                source="Téléphone 2"
                                                size="small"
                                                placeholder={translate(
                                                    "pages.account.telephone"
                                                )}
                                                label={translate(
                                                    "pages.account.telephone"
                                                )}
                                                variant="outlined"
                                            />
                                            <TextInput
                                                source="Fax 2"
                                                size="small"
                                                placeholder={translate(
                                                    "pages.account.fax"
                                                )}
                                                label={translate(
                                                    "pages.account.fax"
                                                )}
                                                variant="outlined"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Adresse #3 */}
                            <div className={classes.formLine}>
                                <div
                                    className={classnames(
                                        classes.formLineInner,
                                        classes.formLineInnerWithBlock
                                    )}
                                >
                                    <CCTFormLabel
                                        className={classes.formLabel}
                                        htmlFor={`ch3`}
                                    >
                                        {translate("pages.account.adresse3")}
                                    </CCTFormLabel>
                                    <div className={classes.formLineBlock}>
                                        <TextInput
                                            fullWidth
                                            source="Address #3 Street A"
                                            id={`ch3`}
                                            placeholder={translate(
                                                "pages.account.adresseLine1"
                                            )}
                                            label={translate(
                                                "pages.account.adresseLine1"
                                            )}
                                            autoComplete="street-address address-line1"
                                            variant="outlined"
                                        />
                                        <TextInput
                                            fullWidth
                                            source="Address #3 Street B"
                                            placeholder={translate(
                                                "pages.account.adresseLine2"
                                            )}
                                            autoComplete="street-address address-line2"
                                            label={translate(
                                                "pages.account.adresseLine2"
                                            )}
                                            variant="outlined"
                                        />
                                        <TextInput
                                            fullWidth
                                            source="Address #3 Street C"
                                            placeholder={translate(
                                                "pages.account.adresseLine3"
                                            )}
                                            autoComplete="street-address address-line3"
                                            label={translate(
                                                "pages.account.adresseLine3"
                                            )}
                                            variant="outlined"
                                        />
                                        <div
                                            className={classnames(
                                                classes.formLineBlock,
                                                classes.formLineFlex
                                            )}
                                        >
                                            <AddressPartsLocalized
                                                locale={locale}
                                                zip={
                                                    <TextInput
                                                        source="Address #3 Zip"
                                                        inputProps={{
                                                            size: "9",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.cp"
                                                        )}
                                                        label={translate(
                                                            "pages.account.cp"
                                                        )}
                                                        autoComplete="postal-code"
                                                        variant="outlined"
                                                    />
                                                }
                                                city={
                                                    <TextInput
                                                        source="Address #3 City"
                                                        inputProps={{
                                                            size: "18",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.ville"
                                                        )}
                                                        autoComplete="address-level2"
                                                        label={translate(
                                                            "pages.account.ville"
                                                        )}
                                                        variant="outlined"
                                                    />
                                                }
                                                state={
                                                    <TextInput
                                                        source="Address #3 State"
                                                        inputProps={{
                                                            size: "10",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.region"
                                                        )}
                                                        autoComplete="address-level1"
                                                        label={translate(
                                                            "pages.account.region"
                                                        )}
                                                        variant="outlined"
                                                    />
                                                }
                                                country={
                                                    <PaysInput
                                                        source="Address #3 Country"
                                                        inputProps={{
                                                            size: "9",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.pays"
                                                        )}
                                                        label={translate(
                                                            "pages.account.pays"
                                                        )}
                                                        variant="outlined"
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Adresse saisonnière */}
                            <div className={classes.formLine}>
                                <div
                                    className={classnames(
                                        classes.formLineInner,
                                        classes.formLineInnerWithBlock
                                    )}
                                >
                                    <CCTFormLabel
                                        className={classes.formLabel}
                                        htmlFor={`ch5`}
                                    >
                                        {translate(
                                            "pages.account.adresseSaisonniere"
                                        )}
                                    </CCTFormLabel>
                                    <div className={classes.formLineBlock}>
                                        <TextInput
                                            fullWidth
                                            source="Seasonal Street"
                                            id={`ch5`}
                                            placeholder={translate(
                                                "pages.account.adresseLine1"
                                            )}
                                            label={translate(
                                                "pages.account.adresseLine1"
                                            )}
                                            autoComplete="street-address address-line1"
                                            variant="outlined"
                                        />
                                        <TextInput
                                            fullWidth
                                            source="Seasonal Street #2"
                                            placeholder={translate(
                                                "pages.account.adresseLine2"
                                            )}
                                            autoComplete="street-address address-line2"
                                            label={translate(
                                                "pages.account.adresseLine2"
                                            )}
                                            variant="outlined"
                                        />
                                        <TextInput
                                            fullWidth
                                            source="Seasonal Street #3"
                                            placeholder={translate(
                                                "pages.account.adresseLine3"
                                            )}
                                            autoComplete="street-address address-line3"
                                            label={translate(
                                                "pages.account.adresseLine3"
                                            )}
                                            variant="outlined"
                                        />
                                        <div
                                            className={classnames(
                                                classes.formLineBlock,
                                                classes.formLineFlex
                                            )}
                                        >
                                            <AddressPartsLocalized
                                                locale={locale}
                                                zip={
                                                    <TextInput
                                                        source="Seasonal Zip"
                                                        inputProps={{
                                                            size: "9",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.cp"
                                                        )}
                                                        label={translate(
                                                            "pages.account.cp"
                                                        )}
                                                        autoComplete="postal-code"
                                                        variant="outlined"
                                                    />
                                                }
                                                city={
                                                    <TextInput
                                                        source="Seasonal City"
                                                        inputProps={{
                                                            size: "18",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.ville"
                                                        )}
                                                        autoComplete="address-level2"
                                                        label={translate(
                                                            "pages.account.ville"
                                                        )}
                                                        variant="outlined"
                                                    />
                                                }
                                                state={
                                                    <TextInput
                                                        source="Seasonal ST"
                                                        inputProps={{
                                                            size: "10",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.region"
                                                        )}
                                                        autoComplete="address-level1"
                                                        label={translate(
                                                            "pages.account.region"
                                                        )}
                                                        variant="outlined"
                                                    />
                                                }
                                                country={
                                                    <PaysInput
                                                        source="Seasonal Country"
                                                        inputProps={{
                                                            size: "9",
                                                        }}
                                                        placeholder={translate(
                                                            "pages.account.pays"
                                                        )}
                                                        label={translate(
                                                            "pages.account.pays"
                                                        )}
                                                        variant="outlined"
                                                    />
                                                }
                                            />
                                        </div>
                                        <TextInput
                                            source="Seasonal Address Dates"
                                            placeholder={translate(
                                                "pages.account.addressDates"
                                            )}
                                            label={translate(
                                                "pages.account.addressDates"
                                            )}
                                            variant="outlined"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={classnames(classes.formLine, {
                                    [classes.bottomSticky]: isSmall,
                                    [classes.responsiveBottomButtons]: isSmall,
                                })}
                            >
                                <div className={classes.formLineInner}>
                                    <CCTFormLabel
                                        className={classes.formLabel}
                                    ></CCTFormLabel>
                                    <SimpleSaveButton
                                        color="secondary"
                                        handleSubmitWithRedirect={(...args) => {
                                            return handleSubmitWithRedirect(
                                                ...args
                                            );
                                        }}
                                        className={classnames({
                                            [classes.responsiveBottomButton]: isSmall,
                                        })}
                                        saving={saving}
                                        invalid={invalid}
                                        icon={null}
                                        submitOnEnter={true}
                                        label={translate("Enregistrer")}
                                    />
                                </div>
                            </div>
                        </form>
                    );
                }}
            />
        </Edit>
    );
};

export default MemberContactEdit;
