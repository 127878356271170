import ModelHelper from "./ModelHelper";
import { identity, uniq } from "lodash";
import MemberHasCommanderieHelper from "./MemberHasCommanderieHelper";
import MemberHasCommanderieHasTitleHelper from "./MemberHasCommanderieHasTitleHelper";

export default class MembreHelper extends ModelHelper {
    get displayName() {
        return this["Nom propre"]
            ? [this.Prénom, this["Nom propre"]].filter(Boolean).join(" ")
            : this["Nom"];
    }

    get fullName() {
        return this["Nom"] || this.displayName;
    }

    get codeGrade() {
        return this["Code grade"] || null;
    }

    get dateIntronisation() {
        if (this["Date intronisation"]) {
            // FIX problème de date par rapport au fuseau horaire
            var _date = new Date(this["Date intronisation"]);
            let _userOffset = _date.getTimezoneOffset() * 60 * 1000; // user's offset time
            _date = new Date(_date.getTime() + _userOffset); // redefine variable
        }
        return this["Date intronisation"] ? _date : null;
    }

    get email() {
        return this["E-mail 1"] || this["E-mail 1"] || null;
    }

    get hasSpouse() {
        return this["Spouse First Name"] || this["Spouse Last Name"];
    }

    set rosterCommanderie(val) {
        this._rosterCommanderie = val;
    }

    /**
     * @returns {string}
     */
    get rosterCommanderie() {
        return this._rosterCommanderie;
    }

    set commanderies(val) {
        this._commanderies = val.map(
            (c) => new MemberHasCommanderieHelper(c, this.locale)
        );
    }
    /**
     * @returns {MemberHasCommanderieHelper[]}
     */
    get commanderies() {
        return this._commanderies;
    }

    get codesCommanderies() {
        return uniq(
            [
                ...this.commanderies?.map(({ commanderie }) => commanderie),
            ].filter(identity)
        );
    }

    get commanderie() {
        return this.commanderies?.find(
            ({ SousCommanderie }) =>
                SousCommanderie?.Type === "Commanderie" &&
                SousCommanderie?.["N° Sous-commanderie"] ===
                    this.rosterCommanderie
        );
    }

    get nonRosterCommanderies() {
        return this.commanderies?.filter(
            ({ SousCommanderie }) =>
                SousCommanderie?.Type === "Commanderie" &&
                SousCommanderie?.["N° Sous-commanderie"] !==
                    this.rosterCommanderie
        );
    }

    get nonRosterCommanderiesWithNoSousCommanderieMembership() {
        return this.nonRosterCommanderies?.filter(
            ({ SousCommanderie: commanderie }) =>
                this.sousCommanderies &&
                !this.sousCommanderies.find(
                    ({ SousCommanderie }) =>
                        SousCommanderie["Code commanderie"] ===
                        commanderie["N° Sous-commanderie"]
                )
        );
    }

    get codeCommanderie() {
        return this.commanderie?.commanderie;
    }

    get sousCommanderies() {
        return this.commanderies
            ?.filter(
                ({ SousCommanderie }) =>
                    !(SousCommanderie?.Type === "Commanderie")
            )
            .sort((a, b) => {
                const _a =
                    a?.SousCommanderie?.["Code commanderie"] ===
                    this.rosterCommanderie
                        ? 1
                        : 0;
                const _b =
                    b?.SousCommanderie?.["Code commanderie"] ===
                    this.rosterCommanderie
                        ? 1
                        : 0;
                return _b - _a;
            });
    }

    isInSousCommanderie(sc) {
        return this.commanderies.find(({ commanderie }) => sc === commanderie);
    }

    get homeSousCommanderie() {
        return this.commanderies?.find(
            ({ SousCommanderie }) =>
                !(SousCommanderie?.Type === "Commanderie") &&
                (!this.rosterCommanderie ||
                    SousCommanderie?.["Code commanderie"] ===
                        this.rosterCommanderie)
        );
    }
    get mainCommanderie() {
        return this.commanderies?.find(({ main }) => main === true);
    }

    get codeHomeSousCommanderie() {
        // Va retourner la première sous commanderie
        // L'api renvoie les commanderies triées par index
        // Donc ça renvoie la "home" sous commanderie.
        return this.homeSousCommanderie?.commanderie;
    }

    /**
     *
     * @param {string} codeSousCommanderie
     * @returns {MemberHasCommanderieHasTitleHelper[]}
     */
    getTitlesFor(codeSousCommanderie) {
        return (
            codeSousCommanderie &&
            this.commanderies?.find(
                ({ commanderie }) => commanderie === codeSousCommanderie
            )?.titles
        );
    }

    get homeSousCommanderieTitles() {
        return this.getTitlesFor(this.codeHomeSousCommanderie);
    }

    /**
     * @returns {MemberHasCommanderieHasTitleHelper[]}
     */
    get nationalTitles() {
        return this.commanderie?.titles;
    }

    get hasNationalTitle() {
        return !!this.nationalTitles?.length;
    }

    hasTitleFor(codeSousCommanderie) {
        return this.getTitlesFor(codeSousCommanderie);
    }

    get hasCommittee() {
        return this["Committee #1"] || this["Committee #2"];
    }

    get hasContactInfo() {
        return (
            this.email ||
            this.hasAddress1 ||
            this.hasAddress2 ||
            this.hasCellPhones
        );
    }

    get hasBusinessDetails() {
        return this.Profession || this.Société || this.hasBusinessAddress;
    }

    get hasBusinessAddress() {
        return (
            this["Code postal Soc"] ||
            this["Ville Soc"] ||
            this["Téléphone Soc"]
        );
    }
    get hasBusinessAddressSimple() {
        return this["Code postal Soc"] || this["Ville Soc"];
    }

    get hasCellPhones() {
        return this["Portable 1"] || this["Portable 2"];
    }

    get hasAddress1() {
        return this["Code postal 1"] || this["Ville 1"];
    }

    get hasAddress2() {
        return this["Code postal 2"] || this["Ville 2"];
    }

    get hasAddress3() {
        return this["Address #3 Zip"] || this["Address #3 City"];
    }

    get hasAddress4() {
        return this["Seasonal Zip"] || this["Seasonal City"];
    }
}
