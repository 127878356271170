import React, { useState, useEffect } from "react";

import { useDataProvider, useNotify } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CUSTOM_POST } from "../../restProvider";
import ChapitreReservationPriceTable from "./ChapitreReservationPriceTable";

/**
 * @param {object} reservation
 * @returns {string} une chaîne qui représente l'unicité de la réservation
 */
const serializeReservationEstimateParams = (reservation) => {
    const { chapitre, nbPlacesDemandees } = reservation;
    const data = {
        chapitre,
        q: nbPlacesDemandees,
    };
    return JSON.stringify(data);
};

const dataIsValid = (reservation) =>
    reservation && reservation.chapitre && reservation.participants;
const ChapitreReservationEstimate = ({
    reservation,
    debug = false,
    ...props
}) => {
    const dataProvider = useDataProvider();
    const [estimation, setEstimation] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const notify = useNotify();
    useEffect(() => {
        if (dataIsValid(reservation)) {
            dataProvider[CUSTOM_POST]("reservationweb/estimate", {
                data: reservation,
            })
                .then(({ data }) => {
                    setEstimation(data);
                    setLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                    notify(
                        `Erreur de chargement de l'estimation. ${error.message}`,
                        "warning"
                    );
                });
        }
    }, [serializeReservationEstimateParams(reservation)]);

    return loading ? (
        <CircularProgress />
    ) : (
        <>
            {debug ? (
                <pre>
                    {JSON.stringify(
                        {
                            loading,
                            error,
                            estimation,
                        },
                        null,
                        2
                    )}
                </pre>
            ) : null}
            <ChapitreReservationPriceTable data={estimation} />
        </>
    );
};

export default ChapitreReservationEstimate;
