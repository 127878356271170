import React from "react";
import Page from "./Page";
import { useTranslate } from "react-admin";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Routes } from "../routes";

const useStyles = makeStyles((theme) => ({
    root: { textAlign: "center", marginTop: theme.spacing(4) },
}));

export default (props) => {
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <Page className={classes.root}>
            <h1>{translate("La page demandée n'existe pas")}</h1>
            <Button
                variant="contained"
                color="secondary"
                to={Routes.home}
                component={Link}
            >
                {translate("Retour à l'accueil")}
            </Button>
        </Page>
    );
};
