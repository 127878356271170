import React from "react";
import { usePermissions } from "react-admin";
import MembreHelper from "../helpers/MembreHelper";
import ModelController from "./ModelController";
/**
 * @param {Object} props
 * @param {function({ data: MembreHelper, loaded: Boolean, error: Error })} props.children
 */
const MembreController = ({ details = false, ...props }) => {
    const { permissions } = usePermissions();
    return (
        <ModelController
            {...props}
            helperClass={MembreHelper}
            extraAttributes={{
                rosterCommanderie: permissions?.rosterAccess,
            }}
            resource={
                details === true
                    ? "membre/details"
                    : details
                    ? `membre/${details}`
                    : "membre"
            }
        />
    );
};

export default MembreController;
