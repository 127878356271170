import { stringify } from "query-string";
import getApiUrl from "./utils/getApiUrl";

const apiUrl = getApiUrl();

const loginUri = `${apiUrl}/login`;
const logoutUri = `${apiUrl}/logout`;
const forgotUri = `${apiUrl}/forgot`;
const resetUri = `${apiUrl}/reset`;
const authCheckUri = `${apiUrl}/user/me`;

const AUTH_CONTEXT_FRONT = "front";
const AUTH_CONTEXT_ADMIN = "admin";

let authContext = AUTH_CONTEXT_ADMIN;

const getAuthContext = () => String(authContext);
const setAuthContext = (context) => {
    authContext = context;
};

let refreshUserPromise;

function refreshUser() {
    if (!refreshUserPromise) {
        /**
         * set roleContext from getAuthContext or params
         */
        const _query = {};
        if (getAuthContext() && typeof _query.roleContext === "undefined") {
            _query.roleContext = getAuthContext();
        }
        refreshUserPromise = fetch(
            new Request(
                `${authCheckUri}${_query ? `?${stringify(_query)}` : ""}`,
                {
                    method: "GET",
                    credentials: "include",
                }
            )
        )
            .then(async (response) => {
                if (response.status < 200 || response.status >= 300) {
                    localStorage.removeItem(`authenticated${getAuthContext()}`);
                    throw new Error(await getErrorMessage(response));
                }
                return response.json();
            })
            .then((responseJson) => {
                const { success, error } = responseJson;
                if (success) {
                    localStorage.setItem(
                        `authenticated${getAuthContext()}`,
                        JSON.stringify(responseJson)
                    );
                    return responseJson;
                } else {
                    throw new Error(error);
                }
            })
            .finally(() => {
                refreshUserPromise = undefined;
            });
    }
    return refreshUserPromise;
}

let logoutPromise;

const isJsonResponse = (response) => {
    const contentType = response?.headers?.get("content-type");
    return contentType && contentType.indexOf("application/json") !== -1;
};

const getErrorMessage = async (response) => {
    let message;
    if (isJsonResponse(response)) {
        const body = await response.json();
        message = body?.message;
    }
    if (typeof message === "undefined") {
        message = response.statusText;
    }
    return message;
};

const authProvider = {
    login: (params) => {
        const { username, password, token } = params;
        const request = new Request(`${loginUri}`, {
            method: "POST",
            body: JSON.stringify({ email: username, password, token }),
            credentials: "include",
            headers: new Headers({ "Content-Type": "application/json" }),
        });
        return fetch(request).then(async (response) => {
            if (response.status < 200 || response.status >= 300) {
                const message = await getErrorMessage(response);
                throw new Error(message);
            }

            return refreshUser();
        });
    },
    logout: () => {
        if (!logoutPromise) {
            const request = new Request(`${logoutUri}`, {
                method: "GET",
                credentials: "include",
            });
            logoutPromise = fetch(request)
                .then((response) => {
                    if (response.status < 200 || response.status >= 300)
                        throw new Error(response.statusText);
                    localStorage.removeItem(
                        `authenticated${AUTH_CONTEXT_FRONT}`
                    );
                    localStorage.removeItem(
                        `authenticated${AUTH_CONTEXT_ADMIN}`
                    );
                    return;
                })
                .finally(() => {
                    logoutPromise = undefined;
                });
        }
        return logoutPromise;
    },
    checkAuth: async (error, ...args) => {
        if (error && error.status === 401) {
            return Promise.reject({ redirectTo: "/login" });
        }
        if (!localStorage.getItem(`authenticated${getAuthContext()}`)) {
            await refreshUser();
        } else {
            refreshUser().catch((error) => console.error(error));
        }
        if (localStorage.getItem(`authenticated${getAuthContext()}`)) {
            const json = JSON.parse(
                localStorage.getItem(`authenticated${getAuthContext()}`)
            );
            if (
                authContext === AUTH_CONTEXT_ADMIN &&
                !(
                    json &&
                    json.roles &&
                    json.roles.find(({ adminAccess }) => !!adminAccess)
                )
            ) {
                // Maybe it's just out of date
                try {
                    await refreshUser();
                } catch (error) {
                    console.error(error);
                }
                if (window) {
                    window.location = "/";
                    return;
                }
            }
            if (
                authContext === AUTH_CONTEXT_FRONT &&
                !(
                    json &&
                    json.roles &&
                    json.roles.find(({ name }) => name === "Membre")
                )
            ) {
                // Maybe it's just out of date
                try {
                    await refreshUser();
                } catch (error) {
                    console.error(error);
                }
                if (window && json.adminUrl) {
                    window.location = json.adminUrl;
                }
                return Promise.reject(new Error("Vous n'êtes pas membre"));
            }
            return Promise.resolve(json);
        }
    },
    /**
     * Appelé à chaque erreur de l'api. Si promesse rejetée, va appeler logout()
     */
    checkError: (error, ...args) => {
        if (error && error.status === 401) {
            return authProvider.checkAuth(error);
        } else {
            return Promise.resolve();
        }
    },
    getPermissions: async () => {
        if (!localStorage.getItem(`authenticated${getAuthContext()}`)) {
            try {
                await refreshUser();
            } catch (error) {
                // DO NOT THROW or you won't ever be able to access public pages like "forgot password"
            }
        }
        return localStorage.getItem(`authenticated${getAuthContext()}`)
            ? JSON.parse(
                  localStorage.getItem(`authenticated${getAuthContext()}`)
              )
            : null;
    },
    forgot: (params) => {
        const { email, token } = params;
        const request = new Request(`${forgotUri}`, {
            method: "POST",
            body: JSON.stringify({ email, token }),
            credentials: "include",
            headers: new Headers({ "Content-Type": "application/json" }),
        });
        return fetch(request).then(async (response) => {
            if (
                response &&
                response.status &&
                `${response.status}`.indexOf(2) === 0
            ) {
                return response;
            } else {
                const message = await getErrorMessage(response);
                throw new Error(message || "auth.forgotFailure");
            }
        });
    },
    reset: (params) => {
        const request = new Request(`${resetUri}`, {
            method: "POST",
            body: JSON.stringify(params),
            credentials: "include",
            headers: new Headers({ "Content-Type": "application/json" }),
        });
        return fetch(request).then(async (response) => {
            if (
                response &&
                response.status &&
                `${response.status}`.indexOf(2) === 0
            ) {
                return response;
            } else {
                const message = await getErrorMessage(response);
                throw new Error(message || "auth.resetFailure");
            }
        });
    },
};

export {
    setAuthContext,
    getAuthContext,
    AUTH_CONTEXT_FRONT,
    AUTH_CONTEXT_ADMIN,
};
export default authProvider;
