import React, { useState, useEffect } from "react";
import ChapitreController from "../../controllers/ChapitreController";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import Button from "@material-ui/core/Button";
import Page from "./Page";
import { Routes } from "../routes";
import ErrorView from "../components/ErrorView";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ChapitreHeader from "../components/ChapitreHeader";
import QuillContent from "../../components/QuillContent";
import { parse } from "query-string";
import MediaController from "../../controllers/MediaController";
import MediaImg from "../components/MediaImg";
import Typography from "@material-ui/core/Typography";
import useWidth from "../../hooks/useWidth";
import { isWidthDown } from "@material-ui/core/withWidth";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useContentPreview from "../../hooks/useContentPreview";

const maxWidthPage = 1260;
const useStyles = makeStyles((theme) => ({
    root: {},
    header: {
        "&:after": {
            display: "none",
        },
    },
    article: {
        padding: theme.spacing(1, 8),
        boxSizing: "border-box",

        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(1, 4),
        },
        maxWidth: maxWidthPage,
        margin: "0 auto",
        width: "100%",
    },
    img: {
        display: "block",
        maxWidth: "100%",
        height: "auto",
    },
    link: {
        textDecoration: "none",
    },
    content: ({ blockPhotosFloating }) => {
        const style = {
            display: blockPhotosFloating ? "block" : "flex",
        };
        if (!blockPhotosFloating) {
            style.flexDirection = "column";
        }
        return style;
    },
    resume: {},
    resumeContent: {},
    resumePhotosDetails: ({ blockPhotosFloating }) => ({
        marginLeft: blockPhotosFloating ? theme.spacing(3) : 0,
        marginBottom: theme.spacing(2),
        float: blockPhotosFloating ? "right" : "none",
        width: blockPhotosFloating ? 280 : "auto",
        background: theme.palette.background.default,
        padding: theme.spacing(1, 2),
    }),
    resumeNoContent: {
        textAlign: "center",
        padding: theme.spacing(8, 0, 4),
    },
}));

export default ({
    match: {
        params: { id, slug, idReservation, action },
    },
    location: { pathname, search },
    history,
    ...props
}) => {
    const contentPreview = useContentPreview();
    const translate = useTranslate();
    const width = useWidth();
    const floatMqLarge = useMediaQuery("@media (min-width: 1160px)");
    const floatMqSmall = useMediaQuery("@media (min-width: 760px)");
    const isSmall = isWidthDown("sm", width);
    const blockPhotosFloating = floatMqLarge || (isSmall && floatMqSmall);
    const classes = useStyles({ blockPhotosFloating });
    const [backLink, setBackLink] = useState(Routes.chapitresParticipations);
    useEffect(() => {
        if (search) {
            const query = parse(search);
            if (query && query.backUrl) {
                setBackLink(query.backUrl);
            }
        }
    }, [search]);

    const blockPhotos = (chapitre, floating) =>
        chapitre.resumePhotosDetails ? (
            <div className={classes.resumePhotosDetails}>
                <QuillContent
                    noClear={floating}
                    content={chapitre.resumePhotosDetails}
                    className={classes.resumePhotosDetailsContent}
                />
            </div>
        ) : null;
    return (
        <Page
            noSidebar={contentPreview}
            noFooter={contentPreview}
            className={classes.root}
            noPadding={true}
            topBar={
                contentPreview ? null : (
                    <Link to={backLink} className={classes.link}>
                        <Button variant="outlined" className={classes.goback}>
                            <ArrowBackIosIcon fontSize="small" />
                            {translate("Retour")}
                        </Button>
                    </Link>
                )
            }
        >
            <article className={classes.article}>
                <ChapitreController id={id} resume>
                    {({ data: chapitre, error }) =>
                        error ? (
                            <ErrorView component="h2" error={error} />
                        ) : (
                            <>
                                {blockPhotosFloating
                                    ? blockPhotos(chapitre, blockPhotosFloating)
                                    : null}
                                <ChapitreHeader
                                    chapitre={chapitre}
                                    buttons={false}
                                    className={classes.header}
                                />
                                {chapitre.resume_poster_hero ? (
                                    <MediaController
                                        id={chapitre.resume_poster_hero}
                                    >
                                        {({ loaded, media }) =>
                                            loaded ? (
                                                /**
                                                 * TODO image component with https://mui.wertarbyte.com/#material-ui-image
                                                 */
                                                <MediaImg
                                                    className={classes.img}
                                                    media={media}
                                                    alt={chapitre.displayName}
                                                />
                                            ) : null
                                        }
                                    </MediaController>
                                ) : null}
                                <div className={classes.content}>
                                    {blockPhotosFloating
                                        ? null
                                        : blockPhotos(
                                              chapitre,
                                              blockPhotosFloating
                                          )}
                                    <div className={classes.resume}>
                                        {chapitre.resume ? (
                                            <QuillContent
                                                content={chapitre.resume}
                                                className={
                                                    classes.resumeContent
                                                }
                                            />
                                        ) : (
                                            <div
                                                className={
                                                    classes.resumeNoContent
                                                }
                                            >
                                                <Typography variant="h2">
                                                    {translate(
                                                        "Le résumé du chapitre n'est pas encore publié."
                                                    )}
                                                </Typography>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )
                    }
                </ChapitreController>
            </article>
        </Page>
    );
};
