import React, { useState } from "react";
import FichierHelper from "../helpers/FichierHelper";

import ModelListController from "./ModelListController";

/**
 * @param {Object} props
 * @param {function({ data: FichierHelper[], loaded: Boolean })} props.children
 * @param {Date} props.minDate Filtrer les chapitres : après la date spécifiée.
 * @param {Number} props.page pagination
 * @param {Number} props.perPage pagination
 * @returns {{data:FichierHelper[], loaded:boolean}}
 */
const FichierListController = ({
    participating,
    filter = {},
    sort = {
        field: "publishDate",
        order: "DESC",
    },
    ...props
}) => {
    const [now] = useState(new Date());
    return (
        <ModelListController
            {...props}
            sort={sort}
            filter={{ publishDate: { "{{Op.lte}}": now }, ...filter }}
            resource="fichier"
            helperClass={FichierHelper}
        />
    );
};

export default FichierListController;
