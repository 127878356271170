import ModelHelper from "./ModelHelper";

const TastevinHelper = class extends ModelHelper {
    /**
     * @property {string}
     */
    name;
    /**
     * @property {string}
     */
    title;
    /**
     * @property {string}
     */
    versionTitle;
    /**
     * @property {string}
     */
    buttonText;
    /**
     * @property {number}
     */
    image;
    /**
     * @property {number}
     */
    image_small;
    /**
     * @property {string}
     */
    file;
    /**
     * @property {string}
     */
    externalLink;
    get i18nDataProperty() {
        return "Tastevin_i18n";
    }
    get displayName() {
        return this.title || this.name;
    }
    get link() {
        return this.file || this.externalLink;
    }
};

export default TastevinHelper;
