import querystring from "query-string";

/**
 * @return Boolean
 */
const useContentPreview = () => {
    const qs = querystring.parse(window.location.search, {
        ignoreQueryPrefix: true,
    });
    return !!qs?.contentPreview;
};

export default useContentPreview;
