import React, { useState, useEffect } from "react";
import ChapitreController from "../../controllers/ChapitreController";
import { useTranslate } from "react-admin";
import MediaController from "../../controllers/MediaController";
import MediaImg from "../components/MediaImg";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Page from "./Page";
import { Routes } from "../routes";
import ErrorView from "../components/ErrorView";
import QuillContent from "../../components/QuillContent";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ChapitresController from "../../controllers/ChapitresController";
import ChapitresList from "../components/ChapitresList";
import moment from "moment";
import ChapitreHeader from "../components/ChapitreHeader";
import { parse } from "query-string";

const maxWidthPage = 1260;
const useStyles = makeStyles((theme) => ({
    root: {},
    article: {
        padding: theme.spacing(1, 8),
        boxSizing: "border-box",

        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(1, 2),
        },
        maxWidth: maxWidthPage,
        margin: "0 auto",
        width: "100%",
    },
    img: {
        display: "block",
        maxWidth: "100%",
        height: "auto",
    },
    description: {
        padding: theme.spacing(1, 8),
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(1, 2),
        },
    },
    link: {
        textDecoration: "none",
    },
    next: {
        background: theme.palette.background.default,
        padding: theme.spacing(8),
        textAlign: "center",
    },
    nextChapitre: {
        maxWidth: 400,
        margin: "0 auto",
        "& h2": { marginBottom: 40 },
    },
}));

export default (props) => {
    const {
        match: {
            params: { id },
        },
        location: { search },
    } = props;
    const translate = useTranslate();
    const classes = useStyles();
    const [nextChapitreMinDate, setNextChapitreMinDate] = useState();
    const [currentChapitre, setCurrentChapitre] = useState();
    const [backState, setBackState] = useState({
        to: Routes.chapitres,
        text: translate("Retour aux chapitres"),
    });
    useEffect(() => {
        if (search) {
            const query = parse(search);
            if (query && query.backUrl) {
                setBackState({
                    to: query.backUrl,
                    text: translate("Retour"),
                });
            }
        }
    }, [search]);

    useEffect(() => {
        if (currentChapitre) {
            const nextDate = moment(currentChapitre.datetime)
                .add(1, "second")
                .toDate();
            setNextChapitreMinDate(nextDate);
        }
    }, [JSON.stringify(currentChapitre)]);

    const onLoaded = (chapitre) => {
        if (JSON.stringify(chapitre) !== JSON.stringify(currentChapitre)) {
            setCurrentChapitre(chapitre);
        }
    };

    return (
        <Page
            className={classes.root}
            noPadding={true}
            topBar={
                <Link to={backState.to} className={classes.link}>
                    <Button variant="outlined" className={classes.goback}>
                        <ArrowBackIosIcon fontSize="small" />
                        {backState.text}
                    </Button>
                </Link>
            }
        >
            <article className={classes.article}>
                <ChapitreController id={id} onLoaded={onLoaded}>
                    {({ data: chapitre, error }) =>
                        error ? (
                            <ErrorView component="h2" error={error} />
                        ) : (
                            <>
                                <ChapitreHeader chapitre={chapitre} />
                                {chapitre.poster_hero ? (
                                    <MediaController id={chapitre.poster_hero}>
                                        {({ loaded, media }) =>
                                            loaded ? (
                                                /**
                                                 * TODO image component with https://mui.wertarbyte.com/#material-ui-image
                                                 */
                                                <MediaImg
                                                    className={classes.img}
                                                    media={media}
                                                    alt={chapitre.displayName}
                                                />
                                            ) : null
                                        }
                                    </MediaController>
                                ) : null}
                                {chapitre.description ? (
                                    <QuillContent
                                        content={chapitre.description}
                                        className={classes.description}
                                    />
                                ) : null}
                            </>
                        )
                    }
                </ChapitreController>
            </article>
            <aside className={classes.next}>
                <div className={classes.nextChapitre}>
                    {nextChapitreMinDate ? (
                        <ChapitresController
                            perPage={1}
                            minDate={nextChapitreMinDate}
                        >
                            {({ loaded, error, data: chapitres }) =>
                                loaded && !error ? (
                                    <>
                                        <h2>{translate("Chapitre suivant")}</h2>
                                        <ChapitresList
                                            loaded={loaded}
                                            chapitres={chapitres}
                                            grid={false}
                                        />
                                    </>
                                ) : null
                            }
                        </ChapitresController>
                    ) : null}
                </div>
            </aside>
        </Page>
    );
};
