import React, {
    HtmlHTMLAttributes,
    ReactNode,
    useRef,
    useEffect,
    useMemo,
    useState,
} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
    Card,
    Avatar,
    createMuiTheme,
    makeStyles,
    Theme,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import LockIcon from "@material-ui/icons/Lock";
import { StaticContext } from "react-router";
import { useHistory } from "react-router-dom";
import { useCheckAuth } from "ra-core";

import defaultTheme from "../front/theme";
import Notification from "../front/layout/Notification";
import DefaultLoginForm from "./LoginForm";
import LocaleSwitcher from "../components/LocaleSwitcher";
import Logo from "../front/components/Logo";
import { fade } from "@material-ui/core/styles/colorManipulator";
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Props {
    backgroundImage?: string;
    children: ReactNode;
    after: ReactNode;
    // eslint-disable-next-line @typescript-eslint/ban-types
    classes?: object;
    className?: string;
    staticContext?: StaticContext;
    // eslint-disable-next-line @typescript-eslint/ban-types
    theme: object;
    deferChildrenAfterCheckAuth: boolean;
}

const useStyles = makeStyles(
    (theme: Theme) => ({
        main: {
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            height: "1px",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundImage:
                "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
            overflow: "auto",
        },
        card: {
            flexShrink: 0,
            minWidth: 300,
            maxWidth: 300,
            background: fade(theme.palette.common.black, 0.85),
            color: theme.palette.common.white,
        },
        avatar: {
            marginTop: "6em",
            margin: "1em",
            display: "flex",
            justifyContent: "center",
        },
        icon: {
            width: 150,
            background: "none",
            height: 150,
            flexGrow: 0,
            flexShrink: 0,
        },
        after: {
            marginTop: theme.spacing(2),
        },
        logo: {
            width: 150,
            height: 150,
        },
        loader: {
            margin: theme.spacing(4, 2, 0),
            textAlign: "center",
        },
    }),
    { name: "RaLogin" }
);

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     import { useHistory } from 'react-router-dom';
const Aimport { fade } from '@material-ui/core/styles/colorManipulator';
pp = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
const Login: React.FunctionComponent<
    Props & HtmlHTMLAttributes<HTMLDivElement>
> = (props) => {
    const {
        theme,
        deferChildrenAfterCheckAuth,
        classes: classesOverride,
        className,
        children,
        after,
        staticContext,
        backgroundImage = "/images/login-background.jpg",
        ...rest
    } = props;
    const containerRef = useRef<HTMLDivElement>();
    const classes = useStyles(props);
    const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
    const [checkingAuth, setCheckingAuth] = useState(true);
    let backgroundImageLoaded = false;
    const checkAuth = useCheckAuth();
    const history = useHistory();
    useEffect(() => {
        setCheckingAuth(true);
        checkAuth({}, false)
            .then(() => {
                // already authenticated, redirect to the home page
                history.push("/");
            })
            .catch(() => {
                // not authenticated, stay on the login page
            })
            .finally(() => {
                setCheckingAuth(false);
            });
    }, [checkAuth, history]);

    const updateBackgroundImage = () => {
        if (!backgroundImageLoaded && containerRef.current) {
            containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
            backgroundImageLoaded = true;
        }
    };

    // Load background image asynchronously to speed up time to interactive
    const lazyLoadBackgroundImage = () => {
        if (backgroundImage) {
            const img = new Image();
            img.onload = updateBackgroundImage;
            img.src = backgroundImage;
        }
    };

    useEffect(() => {
        if (!backgroundImageLoaded) {
            lazyLoadBackgroundImage();
        }
    });

    return (
        <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <div
                className={classnames(classes.main, className)}
                {...rest}
                ref={containerRef}
            >
                <div className={classes.avatar}>
                    <Avatar className={classes.icon}>
                        <Logo className={classes.logo} />
                    </Avatar>
                </div>
                <Card className={classes.card}>
                    {/**
                     * On ne charge pas les enfants tant qu'on ne sait pas si l'utilisateur
                     * est authentifié ou non.
                     * Car si il est authentifié, on va le rediriger et démonter tous les enfants
                     * or ceux qui ont un recatcha vont le charger puis seront démontés avant
                     * que le script google n'ait eu le temps d'ajouter le badge.
                     * Du coup, au moment d'être démontés, ces éléments ne pourront pas
                     * supprimer le badge.
                     * Il sera ajouté par le script après redirection
                     * et donc son style ne sera pas géré
                     * et il ne pourra pas être déchargé.
                     */}
                    {deferChildrenAfterCheckAuth && checkingAuth ? (
                        <div className={classes.loader}>
                            <CircularProgress size={24} />
                        </div>
                    ) : (
                        children
                    )}
                    <div className={classes.after}>
                        <LocaleSwitcher />
                    </div>
                </Card>
                {after}
                <Notification />
            </div>
        </ThemeProvider>
    );
};

Login.propTypes = {
    backgroundImage: PropTypes.string,
    children: PropTypes.node,
    after: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    theme: PropTypes.object,
    staticContext: PropTypes.object,
    deferChildrenAfterCheckAuth: PropTypes.bool,
};

Login.defaultProps = {
    theme: defaultTheme,
    children: <DefaultLoginForm />,
    deferChildrenAfterCheckAuth: false,
};

export default Login;
