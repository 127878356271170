import ModelHelper from "./ModelHelper";

export default class VenueHelper extends ModelHelper {
    /**
     * @property {string}
     */
    name;
    /**
     * @property {string}
     */
    fulladdress;
    get i18nDataProperty() {
        return "Venue_i18n";
    }
    get displayName() {
        return this.title || this.name;
    }
}
