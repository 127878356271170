import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import GradeController from "../../controllers/GradeController";

const useStyles = makeStyles((theme) => ({
    root: {
        float: "right",
        marginLeft: theme.spacing(2),
        marginTop: -theme.spacing(6),
        marginRight: -theme.spacing(2),
    },
    img: {},
}));

const Medaille = ({ codeGrade, ...props }) => {
    const classes = useStyles();
    return (
        <GradeController id={codeGrade} withLoader={false}>
            {({ loaded, error, data: grade }) => {
                return !loaded ? null : grade ? (
                    <div className={classes.root}>
                        {grade.medailleImgInfo ? (
                            <img
                                className={classes.img}
                                alt={grade.medailleImgInfo.alt}
                                src={grade.medailleImgInfo.src}
                                width={grade.medailleImgInfo.width}
                                height={grade.medailleImgInfo.height}
                            />
                        ) : null}
                    </div>
                ) : null;
            }}
        </GradeController>
    );
};

export default Medaille;
