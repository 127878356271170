import React, { useRef, useState, useEffect } from "react";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Menu from "./Menu";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import Notification from "./Notification";
import { useSetLocale, useLocale, usePermissions } from "react-admin";
import CssBaseline from "@material-ui/core/CssBaseline";
import useContentPreview from "../../hooks/useContentPreview";
import querystring from "query-string";
import MemberDetailsSharingConsent from "../components/MemberDetailsSharingConsent";

const menuWidth = 220;
const useStyles = makeStyles((theme) => ({
    "@global": {
        html: {
            height: "100%",
            overflow: "hidden",
        },
        body: {
            height: "100%",
            overflow: "hidden",
            ...{ ...theme.typography.body1, color: null },
        },
        "#root": {
            height: "100%",
            "& > div": {
                height: "100%",
            },
        },
        "p:not(.MuiTypography-root):not(.MuiFormHelperText-root)": {
            ...theme.typography.body1,
            color: theme.palette.text.secondary,
        },
        "h1:not(.MuiTypography-root):not(.MuiFormHelperText-root)": {
            ...theme.typography.h1,
        },
        "h2:not(.MuiTypography-root):not(.MuiFormHelperText-root)": {
            ...theme.typography.h2,
        },
        "h3:not(.MuiTypography-root):not(.MuiFormHelperText-root)": {
            ...theme.typography.h3,
        },
        "h4:not(.MuiTypography-root):not(.MuiFormHelperText-root)": {
            ...theme.typography.h4,
        },
        "h5:not(.MuiTypography-root):not(.MuiFormHelperText-root)": {
            ...theme.typography.h5,
        },
        "h6:not(.MuiTypography-root):not(.MuiFormHelperText-root)": {
            ...theme.typography.h6,
        },
        "a:not(.MuiTypography-root):not(.MuiFormHelperText-root):not(.MuiButtonBase-root)": {
            ...theme.typography.body2,
        },
    },
    root: {
        flexGrow: 1,
        display: "flex",
        height: "100%",
    },
    appFrame: {
        flexGrow: 1,
    },
    menu: {
        flexBasis: menuWidth,
        flexGrow: 0,
        flexShrink: 0,
        [theme.breakpoints.down("sm")]: {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            width: "100%",
        },
    },
    content: {
        flexGrow: 1,
    },
}));
/**
 * Layout principal de l'app front.
 * Gestion responsive du menu, sidebar et autres grande frames
 */

const Layout = ({ children, theme: themeOverride, ...props }) => {
    const classes = useStyles();
    const { permissions } = usePermissions();
    const setLocale = useSetLocale();
    const locale = useLocale();
    const contentPreview = useContentPreview();
    const qs = querystring.parse(window.location.search, {
        ignoreQueryPrefix: true,
    });
    const forcedLocale = qs.forcedLocale;
    useEffect(() => {
        let shouldBeLocale = locale;
        const localStorageLocale = localStorage.getItem("locale");
        if (forcedLocale) {
            shouldBeLocale = forcedLocale;
        } else if (localStorageLocale) {
            shouldBeLocale = localStorageLocale;
        } else if (permissions && permissions.locale) {
            shouldBeLocale = permissions.locale;
        }
        if (shouldBeLocale !== locale) {
            setLocale(shouldBeLocale);
        }
    }, [permissions ? permissions.locale : null, locale]);

    return (
        <div className={classes.root}>
            {contentPreview ? null : (
                <Menu className={classes.menu} menuWidth={menuWidth} />
            )}
            <div className={classes.content}>{children}</div>
            <Notification />
            {contentPreview ? null : <MemberDetailsSharingConsent />}
        </div>
    );
};

const LayoutWithTheme = ({ theme: themeOverride, ...props }) => {
    const themeProp = useRef(themeOverride);
    const [theme, setTheme] = useState(createMuiTheme(themeOverride));
    useEffect(() => {
        if (themeProp.current !== themeOverride) {
            themeProp.current = themeOverride;
            setTheme(createMuiTheme(themeOverride));
        }
    }, [themeOverride, themeProp, theme, setTheme]);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Layout {...props} />
        </ThemeProvider>
    );
};

export default LayoutWithTheme;
