import { stringify } from "query-string";
import { isEmpty, isArray } from "lodash";
import {
    fetchUtils,
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from "react-admin";
import { getAuthContext } from "./authProvider";
import getApiUrl from "./utils/getApiUrl";

const apiUrl = getApiUrl();

const CUSTOM_POST = "custom_post";
const CUSTOM_GET = "custom_get";

let locale;
const setLocale = (_locale) => {
    locale = _locale;
};
const getLocale = () => locale;

const resourcePrimaryKeysMapping = {
    membre: "N° Membre",
    "membre/details": "N° Membre",
    "membre/parrain": "N° Membre",
    langue: "N° Langue",
    pays: "N° Pays",
    civilite: "N° Civilité",
    grade: "N° Grade",
    hotel: "N° Hôtel",
    type: "N° Type",
    miseensommeil: "N° Mise en sommeil",
    modereglement: "N° Mode règlement",
    categorieprofessionnelle: "N° Catégorie professionnelle",
    chapitre: "Date chapitre",
    reservation: "N° Réservation",
    reservationdetail: "N° Réservation détail",
    souscommanderie: "N° Sous-commanderie",
    privatearea: "id",
};
const defaultPrimaryKey = "id";

const stringifyError = (errors) =>
    errors
        .map((validationError) => validationError.message || "erreur")
        .join(", ");

const getFileValue = (value) =>
    value
        ? value instanceof File
            ? value
            : value.rawFile && value.rawFile instanceof File
            ? value.rawFile
            : null
        : null;

/**
 * Takes all values out of an array or object value, accessing every key in it.
 * @param {Object|Array} data Object, Map or Array with file values
 * @returns {Object} Object with all the keys of `data` that contained a file
 * mapped to values that represent the corresponding uploaded file.
 * NB : modifies the param `data` object's values
 * NB: different url from
 */
const sendFiles = (data, options) => {
    const fileFields = Object.keys(data).filter((name) =>
        getFileValue(data[name])
    );
    if (!fileFields.length) {
        return;
    }
    const formData = new FormData();
    const formDataDocs = new FormData();
    let title = ``;
    let label = ``;
    fileFields.forEach((fieldName) => {
        const fileValue = getFileValue(data[fieldName]);
        if (
            fieldName === "attachedDocuments" ||
            fieldName === "privatearea_attachedDocuments"
        ) {
            label = fileValue.name;
            title = fileValue.name;
            formDataDocs.append(fieldName, fileValue, fileValue.name);
        } else {
            formData.append(fieldName, fileValue, fileValue.name);
        }
        delete data[fieldName];
    });
    const url = `${apiUrl}/upload/${
        options ? `?options=${JSON.stringify(options)}` : ""
    }`;
    let type = "postjournal";
    let attachedDocuments_name = "";
    if (data.attachedDocuments_name) {
        attachedDocuments_name = data.attachedDocuments_name;
    }
    if (data.type) {
        type = data.type;
    }
    if (data.privatearea_attachedDocuments_name) {
        type = "private";
        attachedDocuments_name = data.privatearea_attachedDocuments_name;
    }
    let id = data.id ? data.id : "";
    label = attachedDocuments_name !== "" ? attachedDocuments_name : label;

    let lang = data.language_id ? data.language_id : "fr";

    const urlDocs = `${apiUrl}/upload/attachedDocuments/${id}/${lang}?label=${encodeURIComponent(
        label
    )}&title=${encodeURIComponent(title)}&type=${type}${
        options ? `&options=${JSON.stringify(options)}` : ""
    }`;
    let fetchRequests = [];
    if (Array.from(formData).length > 0) {
        fetchRequests.push(
            fetch(
                new Request(url, {
                    method: "POST",
                    body: formData,
                    credentials: "include",
                })
            )
        );
    }
    // Transforme FormData en tableau et vérifie s'il n'est pas vide
    if (Array.from(formDataDocs).length > 0) {
        fetchRequests.push(
            fetch(
                new Request(urlDocs, {
                    method: "POST",
                    body: formDataDocs,
                    credentials: "include",
                })
            )
        );
    }
    return Promise.all(fetchRequests).then(async (responses) => {
        const filesList = await Promise.all(
            responses.map(async (response, index) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                const files = await response.json();

                // Associe les id des fichiers aux clés respectives dans 'data'
                for (const key in files) {
                    if (files.hasOwnProperty(key)) {
                        data[key] = files[key].id;
                    }
                }

                return files;
            })
        );

        return Object.assign({}, ...filesList);
    });
};

const unescapeFilters = (filters) => {
    if (!(typeof filters === "object" && filters !== null)) {
        return;
    }
    Object.keys(filters).forEach((key) => {
        if (key.indexOf("{{dot}}")) {
            const value = filters[key];
            delete filters[key];
            key = key.replace("{{dot}}", ".");
            if (typeof value === "object") {
                unescapeFilters(value);
            }
            filters[key] = value;
        }
    });
};

const makeSortArray = (sort) =>
    sort.map(({ field, order, fn }) => {
        const sortArray = [field, order];
        fn && sortArray.push(fn);
        return sortArray;
    });

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The data request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataRequestToHTTP = (type, resource, params = {}) => {
    let url = "";
    const options = {};
    let primaryKey = defaultPrimaryKey;
    const sort = params.sort
        ? isArray(params.sort)
            ? [...params.sort]
            : [{ ...params.sort }]
        : [];
    if (resourcePrimaryKeysMapping[resource]) {
        primaryKey = resourcePrimaryKeysMapping[resource];
        sort.forEach((_sort) => {
            if (_sort && _sort.field && _sort.field === defaultPrimaryKey) {
                _sort.field = primaryKey;
            }
        });
    }
    /**
     * set roleContext from getAuthContext or params
     */
    const _query = params.query || {};
    if (getAuthContext() && typeof _query.roleContext === "undefined") {
        _query.roleContext = getAuthContext();
    }
    // Make a copy before modifying the object or the store will go nuts
    const filter = params.filter ? { ...params.filter } : {};
    /**
     * remove _search parameter and format query
     */
    let search;
    if (filter && typeof filter._search !== "undefined") {
        search = filter._search;
        delete filter._search;
    }
    let attributes;
    if (filter && filter._attributes) {
        attributes =
            isArray(filter._attributes) && !isEmpty(filter._attributes)
                ? filter._attributes
                : undefined;
        delete filter._attributes;
    }
    unescapeFilters(filter);
    switch (type) {
        case CUSTOM_POST:
            url = `${apiUrl}/${resource}${
                _query ? `?${stringify(_query)}` : ""
            }`;
            options.method = "POST";
            options.body = JSON.stringify(params.data);
            break;
        case CUSTOM_GET:
            url = `${apiUrl}/${resource}`;
            if (_query) {
                const paramString = stringify(_query);
                url += (url.includes("?") ? "&" : "?") + paramString;
            }
            break;
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            const query = {
                ..._query,
                search: search,
                filter: JSON.stringify(filter),
                sort: JSON.stringify(makeSortArray(sort)),
                limit: perPage,
                offset: (page - 1) * perPage,
            };
            if (attributes) {
                query.attributes = JSON.stringify(attributes);
            }
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            break;
        }
        case GET_ONE:
            if (typeof params.id === "undefined" || params.id === null) {
                //throw new Error(`params.id is mandatory for ${type} action`);
                //console.warn(`params.id is mandatory for ${type} action`);
                return;
            }
            url = `${apiUrl}/${resource}/${params.id}${
                _query ? `?${stringify(_query)}` : ""
            }`;
            break;
        case GET_MANY: {
            const query = {
                ..._query,
                search: search,
                filter: JSON.stringify({ [primaryKey]: params.ids }),
            };
            if (attributes) {
                query.attributes = JSON.stringify(attributes);
            }
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            break;
        }
        case GET_MANY_REFERENCE: {
            const { page, perPage } = params.pagination;
            const query = {
                ..._query,
                sort: JSON.stringify(makeSortArray(sort)),
                limit: perPage,
                offset: (page - 1) * perPage,
                search: search,
                filter: JSON.stringify({
                    ...filter,
                    [params.target]: params.id,
                }),
            };
            if (attributes) {
                query.attributes = JSON.stringify(attributes);
            }
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            break;
        }
        case UPDATE:
            url = `${apiUrl}/${resource}/${params.id}${
                _query ? `?${stringify(_query)}` : ""
            }`;
            options.method = "PUT";
            options.body = JSON.stringify(params.data);
            break;
        case CREATE:
            url = `${apiUrl}/${resource}${
                _query ? `?${stringify(_query)}` : ""
            }`;
            options.method = "POST";
            options.body = JSON.stringify(params.data);
            break;
        case DELETE:
            url = `${apiUrl}/${resource}/${params.id}${
                _query ? `?${stringify(_query)}` : ""
            }`;
            options.method = "DELETE";
            break;
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The data request params, depending on the type
 * @returns {Object} Data response
 */
const convertHTTPResponse = (response, type, resource, params) => {
    const { headers, json, body } = response;
    if (resourcePrimaryKeysMapping[resource]) {
        switch (type) {
            case GET_MANY:
            case GET_LIST:
            case GET_MANY_REFERENCE:
                json.forEach((data) => {
                    data.id = data[resourcePrimaryKeysMapping[resource]];
                });
                break;
            case UPDATE:
            case GET_ONE:
            case CREATE:
            case DELETE:
                json.id = json[resourcePrimaryKeysMapping[resource]];
                break;
            // Possible problem if we don't do this but non occured
            // case CUSTOM_GET:
            // case CUSTOM_POST:
            //     if (isArray(json)) {
            //         json.forEach((data) => {
            //             data.id = data[resourcePrimaryKeysMapping[resource]];
            //         });
            //     } else {
            //         json.id = json[resourcePrimaryKeysMapping[resource]];
            //     }
            //     break;
            default:
                break;
        }
    }
    switch (type) {
        case CUSTOM_GET:
        case CUSTOM_POST:
            return {
                data: json ? json : body,
                total: headers.get("content-range")
                    ? parseInt(
                          headers.get("content-range").split("/").pop(),
                          10
                      )
                    : null,
            };
        case GET_LIST:
        case GET_MANY_REFERENCE:
            if (!headers.has("content-range")) {
                throw new Error(
                    "The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?"
                );
            }
            return {
                data: json,
                total: parseInt(
                    headers.get("content-range").split("/").pop(),
                    10
                ),
            };
        case CREATE:
            return { data: { ...params.data, id: json ? json.id : null } };
        default:
            return { data: json };
    }
};

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&limit=24&offset=0
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
const _restProvider = (apiUrl, httpClient = fetchUtils.fetchJson) => {
    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return async (type, resource, params) => {
        // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map((id) =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: "PUT",
                        body: JSON.stringify(params.data),
                    })
                )
            ).then((responses) => ({
                data: responses.map((response) => response.json),
            }));
        }
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map((id) =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: "DELETE",
                    })
                )
            ).then((responses) => ({
                data: responses.map((response) => response.json),
            }));
        }

        if ([UPDATE, CREATE].indexOf(type) !== -1) {
            await sendFiles(params.data);
        }

        // TODO
        // Fix non saved empty fields in i18n array
        // Handle empty field changes in arrays
        // e.g comparing params.data and params.previousData
        // see https://github.com/marmelab/react-admin/issues/4726

        const converted = convertDataRequestToHTTP(type, resource, params);
        if (!converted) {
            return Promise.resolve({ data: {} });
        }
        const { url, options } = converted;
        return httpClient(url, options)
            .then((response) =>
                convertHTTPResponse(response, type, resource, params)
            )
            .catch((error) => {
                if (
                    error &&
                    error.body &&
                    error.body.errorFields &&
                    !isEmpty(error.body.errorFields)
                ) {
                    const errors = Object.keys(error.body.errorFields).map(
                        (key) =>
                            `${key} : ${stringifyError(
                                error.body.errorFields[key]
                            )}`
                    );
                    const errorsByField = {};
                    Object.keys(error.body.errorFields).forEach((key) => {
                        errorsByField[key] = stringifyError(
                            error.body.errorFields[key]
                        );
                    });
                    const parsedError = new Error(errors.join(", "));
                    parsedError.errorsByField = errorsByField;
                    parsedError.originalError = error.body;
                    throw parsedError;
                }
                throw error;
            });
    };
};

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({
            Accept: "application/json",
            "X-Locale": getLocale(),
        });
    }
    options.credentials = "include";
    return fetchUtils.fetchJson(url, options);
};

export {
    sendFiles,
    apiUrl,
    httpClient,
    resourcePrimaryKeysMapping,
    CUSTOM_POST,
    CUSTOM_GET,
    setLocale,
    getLocale,
};
export default _restProvider(apiUrl, httpClient);
