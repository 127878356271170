import React from "react";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MembreHelper from "../../helpers/MembreHelper";

const useStyles = makeStyles((theme) => ({
    honoraryStatus: {
        ...theme.typography.body1,
        color: theme.palette.secondary.main,
    },
}));

/**
 *
 * @param {Object} props
 * @param {MembreHelper} props.membre
 */
const MemberDetailsName = ({
    membre,
    sousCommanderie,
    className,
    ...props
}) => {
    const classes = useStyles();
    const membership = sousCommanderie
        ? membre.isInSousCommanderie(sousCommanderie["N° Sous-commanderie"])
        : null;
    const honoraryStatuses = [];
    membership?.honoraire && honoraryStatuses.push("Honoraire");
    membership?.honorisCausa && honoraryStatuses.push("Honoris causa");
    membership?.secondarySC && honoraryStatuses.push("Dual");

    const mainCommanderie = membre.commanderie?.commanderie;
    if (mainCommanderie !== membre.mainCommanderie?.commanderie) {
        honoraryStatuses.push("Int’l Dual");
    }
    return (
        <Typography
            component="div"
            variant="subtitle1"
            className={classnames(className)}
        >
            {membre.fullName}
            {/* Affichage de la commanderie principale */}
            {honoraryStatuses.length ? (
                <>
                    {/* First span so that css adds • */}
                    <span></span>
                    {honoraryStatuses.map((status) => (
                        <span key={status} className={classes.honoraryStatus}>
                            {status}
                        </span>
                    ))}
                </>
            ) : null}
        </Typography>
    );
};

export default MemberDetailsName;
