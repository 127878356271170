import React from "react";

import { ReferenceInput, AutocompleteInput } from "react-admin";

const PaysInput = ({ inputProps = {}, ...props }) => {
    return (
        <ReferenceInput
            reference="pays"
            sort={{
                field: "N° Pays",
                order: "ASC",
            }}
            filterToQuery={(searchText) => ({
                _search: searchText,
            })}
            {...props}
            perPage={1000}
        >
            <AutocompleteInput
                fullWidth
                options={{
                    inputProps: {
                        autocomplete: "offIsay",
                        ...inputProps,
                    },
                }}
                optionText={(suggestion) =>
                    suggestion && suggestion.Pays ? suggestion.Pays : "-"
                }
                matchSuggestion={() => true}
            />
        </ReferenceInput>
    );
};

export default PaysInput;
