import MembreHelper from "./MembreHelper";
export default class UserHelper {
    constructor(user) {
        Object.assign(this, user);
    }
    /**
     * @property
     */
    memberNumber;
    get displayName() {
        return this.member.displayName;
    }
    set member(val) {
        this._member = new MembreHelper(val);
    }
    /**
     * @returns {MembreHelper}
     */
    get member() {
        return this._member;
    }
    get codeGrade() {
        return this.member ? this.member.codeGrade : null;
    }
    get dateIntronisation() {
        return this.member ? this.member.dateIntronisation : null;
    }
    get message() {
        return "Pellentesque convallis eros eget velit tincidunt fringilla.        Suspendisse ultrices pretium pretium. ";
    }
    get codesCommanderies() {
        return this.member.codesCommanderies;
    }
    get codeCommanderie() {
        return this.member?.codeCommanderie;
    }
    get isUSMember() {
        return this.codeCommanderie === "USA";
    }
    get codeHomeSousCommanderie() {
        return this.member?.codeHomeSousCommanderie;
    }
    get mainCommanderie() {
        return this.member?.mainCommanderie;
    }
    isInSousCommanderie(sc) {
        return this.member?.isInSousCommanderie(sc);
    }
}
