import React from "react";

import { useGetList, ReferenceInput, AutocompleteInput } from "react-admin";
const PaysSelectInput = ({ inputProps = {}, ...props }) => {
    const { data, loading, error } = useGetList(
        "pays",
        { page: 1, perPage: 10000 },
        { field: "Pays", order: "ASC" }
    );

    const choices = data
        ? Object.values(data).filter((item) => item["N° Pays"] !== "000")
        : [];

    return (
        <AutocompleteInput
            {...props}
            fullWidth
            choices={choices}
            loading={loading}
            error={error}
            options={{
                inputProps: {
                    autoComplete: "offIsay",
                    ...inputProps,
                },
                suggestionsContainerProps: {
                    style: {
                        zIndex: 5000,
                    },
                },
            }}
            optionText={(suggestion) =>
                suggestion && suggestion.Pays ? suggestion.Pays : "-"
            }
            matchSuggestion={(filterValue, suggestion) => {
                return (
                    suggestion["N° Pays"] !== "000" &&
                    suggestion.Pays.toLowerCase().includes(
                        filterValue.toLowerCase()
                    )
                );
            }}
        />
    );
};

export default PaysSelectInput;
