import React, { useState, useEffect } from "react";
import CardMedia from "@material-ui/core/CardMedia";
import classnames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import { useInView } from "react-intersection-observer";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: ({ ratio }) => `${ratio * 100}%`,
        position: "relative",
        backgroundColor: theme.palette.background.default,
    },
    media: {
        opacity: 0,
        transition: "opacity .3s ease-in-out",
        "$loaded &": {
            opacity: 1,
        },
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    loader: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    placeholder: {
        opacity: 0,
        transition: "opacity .3s ease-in-out",
        "$loaded &": {
            opacity: 1,
        },
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: "url('/images/logo-cct.png')",
        backgroundSize: "100px 100px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
    },
    loaded: {},
    img: {
        display: "none",
    },
}));

const MediaBlock = ({
    srcLoaded,
    src,
    alt,
    title,
    ratio = 1,
    className,
    classes: overrideClasses = {},
    ...props
}) => {
    const classes = useStyles({ ratio });
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const handleLoaded = (event) => {
        setLoading(false);
        setError(false);
    };
    const handleError = (event) => {
        setLoading(false);
        setError(true);
    };
    useEffect(() => {
        if (srcLoaded && !src) {
            setError(true);
            setLoading(false);
        }
    }, [src, srcLoaded]);
    return (
        <div
            ref={ref}
            className={classnames(
                classes.root,
                !loading ? classes.loaded : null,
                className,
                !loading ? overrideClasses.loaded : null
            )}
        >
            {inView ? (
                <>
                    {error ? (
                        <Fade in>
                            <span className={classes.placeholder}></span>
                        </Fade>
                    ) : loading ? (
                        <Fade in>
                            <span className={classes.loader}>
                                <CircularProgress />
                            </span>
                        </Fade>
                    ) : null}
                    {src ? (
                        <CardMedia
                            image={src}
                            alt={alt}
                            title={title}
                            className={classes.media}
                            {...props}
                        />
                    ) : null}
                    {src ? (
                        /* eslint-disable-next-line jsx-a11y/alt-text */
                        <img
                            className={classes.img}
                            src={src}
                            onLoad={handleLoaded}
                            onError={handleError}
                        />
                    ) : null}
                </>
            ) : null}
        </div>
    );
};

export default MediaBlock;
