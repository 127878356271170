import React from "react";
import PostJournalHelper from "../../helpers/PostJournalHelper";
import PostJournalListItem from "./PostJournalListItem";
import Grid from "@material-ui/core/Grid";
import { useTranslate } from "react-admin";
/**
 * @param {object} props
 * @param {PostJournalHelper[]} props.posts
 * @returns {React.ReactElement}
 */
const PostJournalList = ({ posts, grid = true, loaded, type = "journal" }) => {
    const translate = useTranslate();
    return !loaded ? (
        <span>{translate("Chargement")}</span>
    ) : posts && posts.length ? (
        <>
            {posts.map((post, index) =>
                /*
value         |0px     600px    960px    1280px   1920px
key           |xs      sm       md       lg       xl
screen width  |--------|--------|--------|--------|-------->
range         |   xs   |   sm   |   md   |   lg   |   xl
*/

                !grid ? (
                    <PostJournalListItem post={post} key={index} type={type} />
                ) : (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                        <PostJournalListItem post={post} type={type} />
                    </Grid>
                )
            )}
        </>
    ) : (
        <span>{translate("Aucun résultat")}</span>
    );
};

export default PostJournalList;
