import frenchMessages from "ra-language-french";

export default {
    ...frenchMessages,
    "Robot détecté":
        "Google reCAPTCHA n'a pas pu vérifier l'authenticité de votre demande.",
    "Voir la page": "Voir la page",
    en: "Anglais",
    fr: "Français",
    Anglais: "Anglais",
    Français: "Français",
    Chargement: "Chargement",
    Unauthorized: "Non autorisé",
    Forbidden: "Interdit",
    Enregistrer: "Enregistrer",
    Prix: "Prix",
    Général: "Général",
    "Les réservations sont ouvertes": "Les réservations sont ouvertes",
    "Les réservations sont fermées": "Les réservations sont fermées",
    "Créer une réservation": "Créer une réservation",
    "Ajouter un participant": "Ajouter un participant",
    "Confrérie des Chevaliers du Tastevin":
        "Confrérie des Chevaliers du Tastevin",
    or: "ou",
    and: "et",
    auth: {
        email: "E-mail",
        forgot: "Créer / récupérer mon mot de passe",
        motDePasseValidate:
            "Le mot de passe doit faire plus de 8 charactères, contenir majuscule et minuscule.",
        forgotButton: "Recevoir un lien de réinitialisation",
        forgotSuccess: "Vous allez recevoir un e-mail",
        forgotFailure: "Il y a eu une erreur",
        username: "Identifiant (e-mail)",
        resetButton: "Enregistrer ce mot de passe",
        resetFailure: "Votre mot de passe n'a pas été enregistré…",
        resetSuccess: "Votre mot de passe a bien été enregistré.",
        credentialsRequest: "Demande d'identifiants",
        lastName: "Nom",
        firstName: "Prénom",
        sendCredentialRequest: "Envoyer la demande",
        dateOfBirth: "Date de naissance",
        identityVerification: "Vérification d'identité",
        identityVerificationPlaceholder:
            "Date / chapitre d'intronisation, nom de vos parrains, etc...",
    },
    recaptcha: {
        disclaimer: {
            text: "Ce site est protégé par reCAPTCHA et la",
            privacy: "Politique de confidentialité",
            and: "ainsi que les",
            terms: "Conditions d'utilisation",
            apply: "de Google s'appliquent",
        },
    },
    "Pas de résultats": "Pas de résultats",
    "Nous allons traiter votre demande. Vous pourrez ensuite réinitialiser votre mot de passe":
        "Nous allons traiter votre demande. Vous pourrez ensuite réinitialiser votre mot de passe.",
    "Remplissez ce formulaire si vous pensez que nous n'avons pas votre adresse e-mail à jour":
        "Remplissez ce formulaire si vous pensez que nous n'avons pas votre adresse e-mail à jour.",
    "Sinon, cliquez sur": "Sinon, cliquez sur",
    "Si vous ne recevez pas d'email, faites une":
        "Si vous ne recevez pas d'email, faites une",
    "J'ai lu et j'accepte les ": "J'ai lu et j'accepte les ",
    "conditions générales de réservation":
        "conditions générales de réservation",
    preferencePlacement: "Je souhaite être assis à côté de :",
    "Vos préferences de placement": "Vos préferences de placement",
};
