import React from "react";
import PaysHelper from "../helpers/PaysHelper";
import ModelController from "./ModelController";
/**
 * @param {Object} props
 * @param {function({ data: PaysHelper, loaded: Boolean, error: Error })} props.children
 */
const PaysController = ({ resume = false, ...props }) => {
    return (
        <ModelController
            {...props}
            helperClass={PaysHelper}
            resource={"pays"}
        />
    );
};

export default PaysController;
