import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import DateChapitre from "../components/DateChapitre";
import VenueController from "../../controllers/VenueController";
import nl2br from "../../utils/nl2br";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import { Trans } from "react-i18next";
import { Routes } from "../routes";
import ReservationWebListController from "../../controllers/ReservationWebListController";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ChapitreReservationStatusBadge } from "./ChapitreReservationStatus";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(2),
        "&:after": {
            content: '" "',
            display: "block",
            clear: "both",
        },
    },
    title: {
        display: "flex",
        alignItems: "center",
    },
    date: {
        marginRight: theme.spacing(1),
    },
    btResa: {
        float: "right",
        margin: theme.spacing(1),
        padding: theme.spacing(2),
        lineHeight: 1.2,
        textAlign: "center",
    },
    lieu: {
        paddingLeft: theme.spacing(8),

        backgroundImage: "url(/images/SCflecheBlack.svg)",
        backgroundSize: "17px 16px",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: theme.spacing(4),
        [theme.breakpoints.down("xs")]: {
            paddingLeft: theme.spacing(4),
            backgroundPositionX: theme.spacing(0),
        },
        backgroundPositionY: "center",
    },
}));

const ChapitreHeader = ({ chapitre, className, buttons = true }) => {
    const classes = useStyles();
    return chapitre && chapitre.id ? (
        <header className={classnames(classes.root, className)}>
            <h1 className={classes.title}>
                <DateChapitre
                    className={classes.date}
                    chapitre={chapitre}
                ></DateChapitre>
                <span>{chapitre.displayName}</span>
            </h1>
            {buttons && (
                <ReservationWebListController
                    filter={{
                        chapitre: chapitre.id,
                    }}
                >
                    {({ data, loaded, error }) => {
                        return loaded ? (
                            data && data.length ? ( // Réservation existante
                                data.map((reservation, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            variant="contained"
                                            color="secondary"
                                            className={classes.btResa}
                                            component={Link}
                                            to={generatePath(
                                                Routes.chapitreReserver,
                                                {
                                                    id: chapitre.id,
                                                    slug: chapitre.displayName,
                                                    idReservation:
                                                        reservation.id,
                                                }
                                            )}
                                        >
                                            <Trans>
                                                Voir ma
                                                <br />
                                                réservation
                                            </Trans>
                                            <ChapitreReservationStatusBadge
                                                reservation={reservation}
                                                iconOnly
                                                floating
                                            />
                                        </Button>
                                    );
                                })
                            ) : chapitre.reservationIsOpen ? (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.btResa}
                                    component={Link}
                                    to={generatePath(Routes.chapitreReserver, {
                                        id: chapitre.id,
                                        slug: chapitre.displayName,
                                    })}
                                >
                                    <Trans>
                                        Reserver mes places
                                        <br />
                                        pour ce chapitre
                                    </Trans>
                                </Button>
                            ) : null
                        ) : (
                            <CircularProgress />
                        );
                    }}
                </ReservationWebListController>
            )}
            {chapitre.venue ? (
                <VenueController id={chapitre.venue}>
                    {({ loaded, data: venue }) =>
                        loaded ? (
                            <div className={classes.lieu}>
                                <Typography variant="caption">
                                    {venue.displayName && (
                                        <b>{venue.displayName}</b>
                                    )}
                                    <br />
                                    {nl2br(venue.fulladdress)}
                                </Typography>
                            </div>
                        ) : null
                    }
                </VenueController>
            ) : null}
        </header>
    ) : null;
};

export default ChapitreHeader;
