import React, { useState, useEffect, forwardRef } from "react";
import { useTranslate, useNotify, useMutation } from "react-admin";
import Button from "@material-ui/core/Button";
import { CUSTOM_GET } from "../../restProvider";
import PayButton from "./PayButton";

const ChapitreReservationPayButton = forwardRef(
    ({ id, addressInfo, ...props }, ref) => {
        const translate = useTranslate();
        return (
            <PayButton
                resource={`reservationweb/${id}/payment?address=${encodeURIComponent(
                    JSON.stringify(addressInfo)
                )}`}
                addressInfo={addressInfo}
                {...props}
            >
                {translate("Payer")}
            </PayButton>
        );
    }
);

export default ChapitreReservationPayButton;
