import React from "react";
import {
    useTranslate,
    FormWithRedirect,
    RadioButtonGroupInput,
    required,
} from "react-admin";
import Portal from "@material-ui/core/Portal";
import SousCommanderieController from "../../controllers/SousCommanderieController";
import SimpleSaveButton from "./SimpleSaveButton";

const MemberDetailsSharingConsentForm = ({
    classes,
    codeCommanderie,
    actionsPortalRef,
    ...props
}) => {
    const translate = useTranslate();
    return (
        <FormWithRedirect
            {...props}
            render={({
                handleSubmitWithRedirect,
                invalid,
                form,
                ...formProps
            }) => {
                return (
                    <form className={classes.form}>
                        <SousCommanderieController id={codeCommanderie}>
                            {({ data }) => (
                                <RadioButtonGroupInput
                                    validate={[required()]}
                                    source="shareContactInfoWith"
                                    classes={{
                                        label:
                                            classes.shareContactInfoWithLabel,
                                    }}
                                    row={false}
                                    className={classes.radio}
                                    choices={[
                                        {
                                            id: "commanderie",
                                            name: translate(
                                                "Share my information with members of {{commanderieName}}",
                                                {
                                                    commanderieName:
                                                        data?.displayName,
                                                }
                                            ),
                                        },
                                        {
                                            id: "sous-commanderie",
                                            name: translate(
                                                "Share my info only with members of my chapter(s)"
                                            ),
                                        },
                                        {
                                            id: "none",
                                            name: translate(
                                                "Do not share my info with other members"
                                            ),
                                        },
                                    ]}
                                />
                            )}
                        </SousCommanderieController>

                        {actionsPortalRef ? (
                            <Portal container={actionsPortalRef}>
                                <SimpleSaveButton
                                    color="secondary"
                                    handleSubmitWithRedirect={(...args) => {
                                        return handleSubmitWithRedirect(
                                            ...args
                                        );
                                    }}
                                    invalid={invalid}
                                    icon={null}
                                    submitOnEnter={true}
                                    label={translate("Enregistrer")}
                                />
                            </Portal>
                        ) : null}
                    </form>
                );
            }}
        />
    );
};

export default MemberDetailsSharingConsentForm;
