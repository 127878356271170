import React, { useState } from "react";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useTranslate, usePermissions } from "react-admin";
import CurrentUserController from "../../controllers/CurrentUserController";
import GradeController from "../../controllers/GradeController";
import ChapitresController from "../../controllers/ChapitresController";
import ChapitresList from "../components/ChapitresList";
import Grid from "@material-ui/core/Grid";
import NotificationListItem from "../components/NotificationListItem";
import TastevinEnMain from "../components/TastevinEnMain";
import SectionHeader from "../components/SectionHeader";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Page from "./Page";
import { makeStyles } from "@material-ui/core/styles";
import { Routes } from "../routes";
import NotificationsController from "../../controllers/NotificationsController";
import AppelCotisationModal from "../components/AppelCotisationModal";
import querystring from "query-string";
import PostsJournalController from "../../controllers/PostsJournalController";
import PostJournalsListItem from "../components/PostJournalListItem";
import Medaille from "../components/Medaille";
import HomepageText from "../components/HomepageText";

const useStyles = makeStyles((theme) => ({
    root: {},

    userContent: { marginTop: theme.spacing(6) },
    gridTitle: { marginBottom: 0 },
    gridHeader: {
        "&$gridHeader$gridHeader": {
            paddingBottom: 0,
        },
    },
    membershipUpToDate: {
        color: theme.palette.secondary.dark,
    },
}));
export default ({ match: { path, params, ...match }, ...props }) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const owsMembershipToConfrerie =
        permissions?.memberOwsMembershipToConfrerie;
    const [maxDate] = useState(moment().startOf("day").toDate());
    const sidebarProps = {
        variant: "large",
        content: <TastevinEnMain />,
    };
    const classes = useStyles();

    const isRetourAppelCotisation = path === Routes.appelCotisationRetour;

    const [open, setOpen] = useState(isRetourAppelCotisation);
    const handleClose = () => {
        setOpen(false);
    };

    const retourVar = isRetourAppelCotisation
        ? querystring.parse(props.location.search, { ignoreQueryPrefix: true })
              .code
        : "";
    return (
        <Page sidebarProps={sidebarProps}>
            {isRetourAppelCotisation ? (
                <AppelCotisationModal
                    open={open}
                    isRetourBanque={true}
                    handleClose={handleClose}
                    idAppelCotisation={params.id}
                    retourVar={retourVar}
                />
            ) : null}
            <section className={classes.root}>
                <CurrentUserController>
                    {(user) => (
                        <Grid container spacing={6} justify="center">
                            <GradeController id={user.codeGrade}>
                                {({ loaded, error, data: grade }) => {
                                    return !loaded ? (
                                        translate("Chargement")
                                    ) : grade ? (
                                        <>
                                            <Grid
                                                item
                                                xs={12}
                                                xl={10}
                                                className={classes.userContent}
                                            >
                                                <Medaille
                                                    codeGrade={user.codeGrade}
                                                ></Medaille>
                                                <Typography variant="h2">
                                                    {translate(
                                                        "Bienvenue {{username}}",
                                                        {
                                                            username: `${user.displayName}`,
                                                        }
                                                    )}
                                                </Typography>
                                                <Typography variant="h4">
                                                    {user.dateIntronisation
                                                        ? translate(
                                                              "{{grade}} intronisé le {{date}}",
                                                              {
                                                                  grade:
                                                                      grade.displayName ||
                                                                      translate(
                                                                          "Membre"
                                                                      ),
                                                                  date:
                                                                      user.dateIntronisation,
                                                              }
                                                          )
                                                        : grade.displayName ||
                                                          translate("Membre")}
                                                </Typography>
                                                <HomepageText />
                                                <NotificationsController>
                                                    {({
                                                        loaded,
                                                        error,
                                                        data: notifications,
                                                    }) => {
                                                        return !loaded ? (
                                                            translate(
                                                                "Chargement"
                                                            )
                                                        ) : notifications ? (
                                                            <>
                                                                {notifications.map(
                                                                    (
                                                                        notification,
                                                                        index
                                                                    ) => (
                                                                        <NotificationListItem
                                                                            key={
                                                                                index
                                                                            }
                                                                            notification={
                                                                                notification
                                                                            }
                                                                        ></NotificationListItem>
                                                                    )
                                                                )}
                                                                {!owsMembershipToConfrerie ||
                                                                notifications.filter(
                                                                    ({
                                                                        type,
                                                                    }) =>
                                                                        type ===
                                                                        "appelCotisation"
                                                                )
                                                                    .length ? null : (
                                                                    <Typography
                                                                        className={
                                                                            classes.membershipUpToDate
                                                                        }
                                                                    >
                                                                        {translate(
                                                                            "Vous êtes à jour de votre cotisation"
                                                                        )}
                                                                    </Typography>
                                                                )}
                                                            </>
                                                        ) : null;
                                                    }}
                                                </NotificationsController>
                                            </Grid>
                                            <Grid item xs={12} xl={10}>
                                                <Divider />
                                            </Grid>
                                        </>
                                    ) : null;
                                }}
                            </GradeController>
                        </Grid>
                    )}
                </CurrentUserController>
            </section>
            <section>
                <Grid container spacing={6} justify="center">
                    <Grid item xs={12} xl={10} className={classes.gridHeader}>
                        <SectionHeader
                            title={
                                <h1 className={classes.gridTitle}>
                                    {translate("Chapitres")}
                                </h1>
                            }
                            right={
                                <Button component={Link} to={Routes.chapitres}>
                                    {translate("Tous les chapitres")}
                                </Button>
                            }
                        />
                    </Grid>
                    <ChapitresController
                        participating
                        minDate={null}
                        maxDate={maxDate}
                        perPage={1}
                        sort={{
                            field: "datetime",
                            order: "DESC",
                        }}
                    >
                        {({ loaded, error, data: chapitres }) =>
                            loaded && !error ? (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={5}
                                    >
                                        <h2>{translate("Vous y étiez")}</h2>
                                        <ChapitresList
                                            loaded={loaded}
                                            chapitres={chapitres}
                                            itemsProps={{ participating: true }}
                                            grid={false}
                                        />
                                    </Grid>
                                </>
                            ) : null
                        }
                    </ChapitresController>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                        <ChapitresController perPage={1}>
                            {({ loaded, error, data: chapitres }) =>
                                loaded && !error ? (
                                    <>
                                        <h2>{translate("À venir")}</h2>
                                        <ChapitresList
                                            loaded={loaded}
                                            chapitres={chapitres}
                                            grid={false}
                                        />
                                    </>
                                ) : null
                            }
                        </ChapitresController>
                    </Grid>
                </Grid>
            </section>
            <section>
                <Grid container spacing={6} justify="center">
                    <Grid item xs={12} xl={10} className={classes.gridHeader}>
                        <SectionHeader
                            title={
                                <h1 className={classes.gridTitle}>
                                    {translate("Journal")}
                                </h1>
                            }
                            right={
                                <Button component={Link} to={Routes.journal}>
                                    {translate("Tous les articles")}
                                </Button>
                            }
                        />
                    </Grid>
                    <PostsJournalController perPage={2}>
                        {({ loaded, error, data: posts }) =>
                            loaded && !error && posts && posts.length
                                ? posts.map((post, index) => (
                                      <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={5}
                                          key={index}
                                      >
                                          <PostJournalsListItem
                                              post={post}
                                              key={0}
                                          />
                                      </Grid>
                                  ))
                                : null
                        }
                    </PostsJournalController>
                </Grid>
            </section>
        </Page>
    );
};
