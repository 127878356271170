import React from "react";
import ChapitreHelper from "../../helpers/ChapitreHelper";
import ChapitresListItem from "./ChapitresListItem";
import Grid from "@material-ui/core/Grid";
import { useTranslate } from "react-admin";
/**
 * @param {object} props
 * @param {ChapitreHelper[]} props.chapitres
 * @returns {React.ReactElement}
 */
const ChapitresList = ({
    chapitres,
    grid = true,
    itemsProps,
    backUrl,
    loaded,
}) => {
    const translate = useTranslate();
    const _itemProps = {};
    if (backUrl) {
        _itemProps.backUrl = backUrl;
    }
    return !loaded ? (
        <span>{translate("Chargement")}</span>
    ) : chapitres && chapitres.length ? (
        <>
            {chapitres.map((chapitre, index) =>
                /*
value         |0px     600px    960px    1280px   1920px
key           |xs      sm       md       lg       xl
screen width  |--------|--------|--------|--------|-------->
range         |   xs   |   sm   |   md   |   lg   |   xl
*/

                !grid ? (
                    <ChapitresListItem
                        {..._itemProps}
                        {...itemsProps}
                        chapitre={chapitre}
                        key={index}
                    />
                ) : (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                        <ChapitresListItem
                            {..._itemProps}
                            {...itemsProps}
                            chapitre={chapitre}
                        />
                    </Grid>
                )
            )}
        </>
    ) : (
        <span>{translate("Aucun résultat")}</span>
    );
};

export default ChapitresList;
