import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Drawer, useMediaQuery } from "@material-ui/core/";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { useTranslate, usePermissions } from "react-admin";
import { Routes } from "../routes";
import Logo from "../components/Logo";
import MenuItem from "./MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarVisibility } from "ra-core";
import PageListController from "../../controllers/PageListController";
import { generatePath } from "react-router";
import LocaleSwitcher from "../../components/LocaleSwitcher";
import CookieSettings from "../../components/CookieSettings";
import getApiUrl from "../../utils/getApiUrl";
const apiUrl = getApiUrl();

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#000",
        position: "relative",
        zIndex: 50,
    },
    paper: {
        width: ({ menuWidth }) => menuWidth,
        top: 40,
        height: "calc(100% - 40px)",
    },
    rootMenu: {
        "& .MuiBackdrop-root": {
            top: 40,
        },
    },
    nav: {
        backgroundImage: "url('/images/fondMenu.png')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundSize: "cover",
        position: "absolute",
        width: "100%",
        [theme.breakpoints.up(450)]: {
            width: ({ menuWidth }) => menuWidth,
        },
        [theme.breakpoints.up("md")]: {
            position: "fixed",
        },
        top: 0,
        bottom: 0,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
    },
    head: {
        display: "block",
        textAlign: "center",
    },
    list: {
        color: theme.palette.primary.main.contrastText,
        /*background: theme.palette.primary.main,*/
        margin: 0,
        listStyle: "none",
        padding: theme.spacing(0),
        "& a": { textDecoration: "none" },
    },
    listSub: {
        color: theme.palette.primary.main.contrastText,
        /*background: theme.palette.primary.main,*/
        margin: 0,
        listStyle: "none",
        padding: theme.spacing(0),
        "& li": {
            border: "none",
            padding: theme.spacing(0),
        },
    },
    item: {
        borderRight: "8px solid transparent",
        display: "block",
        padding: theme.spacing(2, 2),
        margin: theme.spacing(0, 0),
        marginRight: 0,
        // Pas le choix  du !important le fontsize de a est déjà appliqué par le thème
        fontSize: "14px !important",
    },
    activeItem: {
        borderRight: "8px solid #fff",
    },
    spacer: {
        flexGrow: 1,
    },
    bottomLink: {
        display: "block",
        padding: theme.spacing(2, 2),
        margin: theme.spacing(0, 0),
        textAlign: "center",
    },
}));

const getPage = (pages, _identifier) =>
    pages.find(({ identifier }) => identifier === _identifier);

const Menu = ({ className, menuWidth, openMenu, adminUrl, ...props }) => {
    const classes = useStyles({ menuWidth });
    const translate = useTranslate();
    const [loadedPages, setLoadedPages] = useState();
    const [pageMecenat, setPageMecenat] = useState();
    const [pageIntronisation, setPageIntronisation] = useState();
    const [pagePaiementChancellerie, setPagePaiementChancellerie] = useState();
    const { permissions } = usePermissions();
    useEffect(() => {
        if (loadedPages && loadedPages.length) {
            setPageMecenat(getPage(loadedPages, "mecenat"));
            setPageIntronisation(getPage(loadedPages, "intronisation"));
            setPagePaiementChancellerie(
                getPage(loadedPages, "paiement-chancellerie")
            );
        } else {
            setPageMecenat(null);
            setPageIntronisation(null);
            setPagePaiementChancellerie(null);
        }
    }, [loadedPages]);
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    const dispatch = useDispatch();
    const handleClose = () => {
        if (!isDesktop) dispatch(setSidebarVisibility(false));
    };
    const linkProps = {
        activeClassName: classes.activeItem,
        className: classes.item,
    };
    const linkPropsSub = {
        activeClassName: classes.activeItem,
        className: classes.item,
    };

    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
    const propsForMenuItemThatHaveNoSubmenu = {
        onClick: () => handleClose(),
    };

    const child = (
        <nav className={classes.nav}>
            <ul className={classes.list}>
                <MenuItem
                    className={classes.head}
                    {...propsForMenuItemThatHaveNoSubmenu}
                >
                    <NavLink
                        to={Routes.home}
                        {...{ ...linkProps, activeClassName: null }}
                        exact
                    >
                        <Logo />
                    </NavLink>
                </MenuItem>
                <MenuItem
                    subMenu={
                        <ul className={classes.listSub}>
                            <MenuItem
                                className={classes.itemSub}
                                {...propsForMenuItemThatHaveNoSubmenu}
                            >
                                <NavLink
                                    to={Routes.chapitresParticipations}
                                    {...linkProps}
                                >
                                    {translate("menu.chapitresParticipations")}
                                </NavLink>
                            </MenuItem>
                            <MenuItem {...propsForMenuItemThatHaveNoSubmenu}>
                                <NavLink
                                    to={Routes.chapitres}
                                    {...linkPropsSub}
                                >
                                    {translate("menu.chapitresAVenir")}
                                </NavLink>
                            </MenuItem>
                            <MenuItem {...propsForMenuItemThatHaveNoSubmenu}>
                                <NavLink
                                    to={Routes.chapitresReservations}
                                    className={classes.itemSub}
                                    {...linkProps}
                                >
                                    {translate("menu.chapitresMesResas")}
                                </NavLink>
                            </MenuItem>
                        </ul>
                    }
                >
                    <NavLink to={Routes.chapitres} {...linkProps}>
                        {translate("menu.chapitres")}
                    </NavLink>
                </MenuItem>
                <MenuItem {...propsForMenuItemThatHaveNoSubmenu}>
                    <NavLink to={Routes.journal} {...linkProps}>
                        {/*<Badge
                            badgeContent={4}
                            color="secondary"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >*/}
                        {translate("menu.journal")}
                        {/*</Badge>*/}
                    </NavLink>
                </MenuItem>
                {permissions && permissions.privateArea ? (
                    <MenuItem {...propsForMenuItemThatHaveNoSubmenu}>
                        <NavLink to={Routes.privatearea} {...linkProps}>
                            {translate("menu.privatearea")}
                        </NavLink>
                    </MenuItem>
                ) : null}

                {permissions && permissions.rosterAccess ? (
                    <MenuItem {...propsForMenuItemThatHaveNoSubmenu}>
                        <NavLink to={Routes.roster} {...linkProps}>
                            {translate("menu.roster")}
                        </NavLink>
                    </MenuItem>
                ) : null}
                <MenuItem {...propsForMenuItemThatHaveNoSubmenu}>
                    <Link
                        {...linkProps}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${apiUrl}/boutiqueRedirect`}
                    >
                        {translate("menu.boutique")}
                    </Link>
                </MenuItem>
                {pageIntronisation ? (
                    <MenuItem
                        {...(pagePaiementChancellerie
                            ? propsForMenuItemThatHaveNoSubmenu
                            : {})}
                        subMenu={
                            pagePaiementChancellerie ? (
                                <ul className={classes.listSub}>
                                    <MenuItem
                                        className={classes.itemSub}
                                        {...propsForMenuItemThatHaveNoSubmenu}
                                    >
                                        <NavLink
                                            to={generatePath(Routes.catchAll, {
                                                slug:
                                                    pageIntronisation.slug ||
                                                    "i",
                                                id: pageIntronisation.id,
                                            })}
                                            {...linkProps}
                                        >
                                            {pageIntronisation.menuTitle}
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem
                                        {...propsForMenuItemThatHaveNoSubmenu}
                                    >
                                        <NavLink
                                            to={generatePath(Routes.catchAll, {
                                                slug:
                                                    pagePaiementChancellerie.slug ||
                                                    "i",
                                                id: pagePaiementChancellerie.id,
                                            })}
                                            {...linkProps}
                                        >
                                            {pagePaiementChancellerie.menuTitle}
                                        </NavLink>
                                    </MenuItem>
                                </ul>
                            ) : null
                        }
                    >
                        <NavLink
                            to={generatePath(Routes.catchAll, {
                                slug: pageIntronisation.slug || "i",
                                id: pageIntronisation.id,
                            })}
                            {...linkProps}
                        >
                            {pageIntronisation.menuTitle}
                        </NavLink>
                    </MenuItem>
                ) : null}
                {pageMecenat ? (
                    <MenuItem {...propsForMenuItemThatHaveNoSubmenu}>
                        <NavLink
                            to={generatePath(Routes.catchAll, {
                                slug: pageMecenat.slug || "i",
                                id: pageMecenat.id,
                            })}
                            {...linkProps}
                        >
                            {pageMecenat.menuTitle}
                        </NavLink>
                    </MenuItem>
                ) : null}
                <PageListController
                    filter={{
                        identifier: {
                            "{{Op.in}}": [
                                "intronisation",
                                "mecenat",
                                "paiement-chancellerie",
                            ],
                        },
                    }}
                    onLoaded={setLoadedPages}
                    withLoader={false}
                >
                    {({ loaded, error, data: pages }) => null}
                </PageListController>
            </ul>
            <div className={classes.spacer}></div>

            {permissions && permissions.adminUrl ? (
                <Link
                    href={permissions.adminUrl}
                    target="_blank"
                    className={classes.bottomLink}
                >
                    {translate("menu.admin")}
                </Link>
            ) : null}
            <LocaleSwitcher />
            <CookieSettings />
        </nav>
    );
    return isDesktop ? (
        <div className={classnames(classes.root, className)}>{child}</div>
    ) : (
        <Drawer
            open={open}
            variant="temporary"
            direction={"left"}
            keepMounted
            onClose={handleClose}
            anchor={"left"}
            style={{ height: "calc(100% - 40px)", top: 40 }}
            className={classnames(classes.rootMenu, className)}
            classes={{
                paper: classes.paper,
            }}
        >
            <div className={classnames(classes.root, className)}>{child}</div>
        </Drawer>
    );
};

export default Menu;
