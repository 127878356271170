import React, { useState } from "react";
import ChapitreController from "../../controllers/ChapitreController";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import Button from "@material-ui/core/Button";
import Page from "./Page";
import { Routes } from "../routes";
import ErrorView from "../components/ErrorView";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ChapitreHeader from "../components/ChapitreHeader";
import ChapitreReservation from "../components/ChapitreReservation";
import NotificationsController from "../../controllers/NotificationsController";
import NotificationListItem from "../components/NotificationListItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppelCotisationModalButton from "../components/AppelCotisationModalButton";
import { fade } from "@material-ui/core/styles/colorManipulator";
import AppelCotisationListController from "../../controllers/AppelCotisationListController";
import AppelCotisationHelper from "../../helpers/AppelCotisationHelper";
import Typography from "@material-ui/core/Typography";
import { chapitreReservationMobileViewBreakpoint } from "../components/ChapitreReservation";

const maxWidthPage = 1260;
const useStyles = makeStyles((theme) => ({
    root: {},
    article: {
        padding: theme.spacing(1, 8),
        boxSizing: "border-box",

        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(1, 4),
        },
        maxWidth: maxWidthPage,
        margin: "0 auto",
        width: "100%",
    },
    img: {
        display: "block",
        maxWidth: "100%",
        height: "auto",
    },
    link: {
        textDecoration: "none",
    },
    reservation: {
        position: "relative",
    },
    appelCotisation: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        boxSizing: "border-box",
        padding: theme.spacing(8, 2, 1, 2),
        background: fade(theme.palette.background.paper, 0.85),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        margin: theme.spacing(0),
        [theme.breakpoints.down(chapitreReservationMobileViewBreakpoint)]: {
            margin: theme.spacing(0, -4),
        },
    },
    appelCotisationContent: {
        textAlign: "center",
        marginBottom: theme.spacing(4),
        "& h2": {
            marginBottom: theme.spacing(2),
        },
    },
}));

export default ({
    match: {
        params: { id, slug, idReservation, action },
    },
    location: { pathname },
    history,
    ...props
}) => {
    const translate = useTranslate();
    const classes = useStyles();

    /**
     * @type {[AppelCotisationHelper, function]}
     */
    const [appelsCotisation, setAppelsCotisation] = useState();

    const backLink =
        idReservation && action
            ? generatePath(Routes.chapitreReserver, {
                  id: id,
                  slug: slug,
                  idReservation,
                  action: null,
              })
            : generatePath(Routes.chapitre, {
                  id: id,
                  slug: slug,
              });
    const backLinkText = idReservation
        ? translate("Retour")
        : translate("Annuler");
    const backLinkIcon = idReservation ? (
        <ArrowBackIosIcon fontSize="small" />
    ) : (
        <CloseIcon fontSize="small" />
    );
    return (
        <Page
            className={classes.root}
            noPadding={true}
            topBar={
                <Link to={backLink} className={classes.link}>
                    <Button variant="outlined" className={classes.goback}>
                        {backLinkIcon}
                        {backLinkText}
                    </Button>
                </Link>
            }
        >
            <article className={classes.article}>
                <ChapitreController id={id}>
                    {({ data: chapitre, error }) =>
                        error ? (
                            <ErrorView component="h2" error={error} />
                        ) : (
                            <>
                                <ChapitreHeader
                                    chapitre={chapitre}
                                    buttons={false}
                                />
                                {appelsCotisation ? (
                                    <div className={classes.reservation}>
                                        <ChapitreReservation
                                            chapitre={chapitre}
                                            id={idReservation}
                                            action={action}
                                            redirect={(basePath, id, data) => {
                                                return generatePath(
                                                    Routes.chapitreReserver,
                                                    {
                                                        id: chapitre.id,
                                                        slug:
                                                            chapitre.displayName,
                                                        idReservation: data.id,
                                                    }
                                                );
                                            }}
                                        ></ChapitreReservation>
                                        {appelsCotisation &&
                                        appelsCotisation.length ? (
                                            <div
                                                className={
                                                    classes.appelCotisation
                                                }
                                            >
                                                {appelsCotisation.map(
                                                    (
                                                        appelCotisation,
                                                        index
                                                    ) => (
                                                        <div
                                                            key={index}
                                                            className={
                                                                classes.appelCotisationContent
                                                            }
                                                        >
                                                            <Typography
                                                                variant="h2"
                                                                gutterBottom
                                                            >
                                                                {translate(
                                                                    "Pour réserver, vous devez payer votre cotisation pour l'année {{year}}",
                                                                    {
                                                                        year:
                                                                            appelCotisation.year,
                                                                    }
                                                                )}
                                                            </Typography>
                                                            <AppelCotisationModalButton
                                                                idAppelCotisation={
                                                                    appelCotisation.id
                                                                }
                                                            ></AppelCotisationModalButton>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                ) : (
                                    <CircularProgress />
                                )}
                            </>
                        )
                    }
                </ChapitreController>
                <AppelCotisationListController
                    userRequiredPayments
                    onLoaded={(data) => setAppelsCotisation(data || [])}
                />
            </article>
        </Page>
    );
};
