import { useQueryWithStore } from "react-admin";
import { isNull } from "lodash";
import GradeHelper from "../helpers/GradeHelper";
/**
 * @param {Object} props
 * @param {function({ data: GradeHelper, loaded: Boolean })} props.children
 */
const GradeController = ({ children, id }) => {
    const { loaded, error, data } =
        typeof id === "undefined" || isNull(id)
            ? { loaded: true, data: null, error: null }
            : useQueryWithStore({
                  type: "getOne",
                  resource: "grade",
                  payload: { id },
              });
    return children({ loaded, error, data: new GradeHelper(data) });
};

export default GradeController;
