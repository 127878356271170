import { useQueryWithStore } from "react-admin";
import { isNull } from "lodash";
/**
 * @param {Object} props
 * @param {function({ data, loaded: Boolean })} props.children
 */
const JaugeController = ({ children, id }) => {
    const { loaded, error, data } = useQueryWithStore({
        type: "getOne",
        resource: `chapitreweb/jauge`,
        payload: { id: id },
    });

    return children({ loaded, error, data: data });
};

export default JaugeController;
