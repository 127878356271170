import React from "react";
import LoginForm from "./LoginForm";
import Login from "./Login";
import RecaptchaDisclaimer from "../components/RecaptchaDisclaimer";
import { useLocation } from "react-router";
import querystring from "query-string";

const LoginPage = (props) => {
    const location = useLocation();
    const redirectTo = querystring.parse(location.search, {
        ignoreQueryPrefix: true,
    }).redirectTo;
    return (
        <Login
            {...props}
            deferChildrenAfterCheckAuth
            after={<RecaptchaDisclaimer variant="caption" />}
        >
            <LoginForm redirectTo={redirectTo} />
        </Login>
    );
};
export default LoginPage;
