import React from "react";
import PageHelper from "../helpers/PageHelper";

import ModelListController from "./ModelListController";

/**
 * @param {Object} props
 * @param {function({ data: PageHelper[], loaded: Boolean, error:Error })} props.children
 * @param {Number} props.page pagination
 * @param {Number} props.perPage pagination
 * @returns {{data:PageHelper[], loaded:boolean, error:Error}}
 */
const PageListController = (props) => {
    return (
        <ModelListController
            {...props}
            resource="page"
            helperClass={PageHelper}
        />
    );
};

export default PageListController;
