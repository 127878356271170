const createPasswordValidator = (passwordValidatorSchema) =>
    passwordValidatorSchema
        .is()
        .min(8) // Minimum length 8
        .is()
        .max(100) // Maximum length 100
        .has()
        .lowercase() // Must have lowercase letters
        .has()
        .uppercase();
module.exports = createPasswordValidator;
