import React from "react";
import { Link } from "react-router-dom";
import PageListController from "../../controllers/PageListController";
import { generatePath } from "react-router";
import { Routes } from "../routes";

const LinkPage = ({ identifier, children, ...props }) => {
    return (
        <PageListController
            filter={{
                identifier: identifier,
            }}
            withLoader={false}
        >
            {({ loaded, error, data: pages }) => {
                return loaded && !error && pages && pages[0] ? (
                    <Link
                        to={generatePath(Routes.catchAll, {
                            slug: pages[0].slug || "m",
                            id: pages[0].id,
                        })}
                        {...props}
                    >
                        {children || pages[0].menuTitle}
                    </Link>
                ) : null;
            }}
        </PageListController>
    );
};
export default LinkPage;
