import React, {
    Children,
    cloneElement,
    createElement,
    FunctionComponent,
} from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import {
    WithPermissions,
    AdminChildren,
    CustomRoutes,
    CatchAllComponent,
    TitleComponent,
    DashboardComponent,
} from "ra-core";

interface Props {
    catchAll: CatchAllComponent;
    children: AdminChildren;
    customRoutes?: CustomRoutes;
    dashboard?: DashboardComponent;
    title?: TitleComponent;
}

const useStyles = makeStyles((theme) => ({
    transitionGroup: {
        flexGrow: 1,
        position: "relative",
        height: "100%",
        display: "flex",
        flexDirection: "row",
    },
    route: {
        height: "100%",
        flex: "0 0 calc(min(100vw, 100%))",
        "& ~ $route": {
            marginLeft: "-calc(min(100vw, 100%))",
            zIndex: -1,
        },
    },
}));

const RoutesWithLayout: FunctionComponent<Props> = ({
    catchAll,
    children,
    customRoutes,
    dashboard,
    title,
}) => {
    const location = useLocation();
    const classes = useStyles();
    const childrenAsArray = React.Children.toArray(children);
    const firstChild: React.ReactElement<any> | null =
        childrenAsArray.length > 0
            ? (childrenAsArray[0] as React.ReactElement<any>)
            : null;

    return (
        <TransitionGroup className={classes.transitionGroup}>
            <CSSTransition
                key={`${location.pathname}`}
                timeout={{ enter: 300, exit: 300 }}
                classNames="fade"
            >
                <div className={classes.route}>
                    <Switch location={location}>
                        {customRoutes &&
                            customRoutes.map((route, key) =>
                                cloneElement(route, { key })
                            )}
                        {Children.map(
                            children,
                            (child: React.ReactElement<any>) => (
                                <Route
                                    key={child.props.name}
                                    path={`/${child.props.name}`}
                                    render={(props) =>
                                        cloneElement(child, {
                                            // The context prop instruct the Resource component to
                                            // render itself as a standard component
                                            intent: "route",
                                            ...props,
                                        })
                                    }
                                />
                            )
                        )}
                        {dashboard ? (
                            <Route
                                exact
                                path="/"
                                render={(routeProps) => (
                                    <WithPermissions
                                        authParams={{
                                            route: "dashboard",
                                        }}
                                        component={dashboard}
                                        {...routeProps}
                                    />
                                )}
                            />
                        ) : firstChild ? (
                            <Route
                                exact
                                path="/"
                                render={() => (
                                    <Redirect
                                        to={`/${firstChild.props.name}`}
                                    />
                                )}
                            />
                        ) : null}
                        <Route
                            render={(routeProps) =>
                                createElement(catchAll, {
                                    ...routeProps,
                                    title,
                                })
                            }
                        />
                    </Switch>
                </div>
            </CSSTransition>
        </TransitionGroup>
    );
};

export default RoutesWithLayout;
