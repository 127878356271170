import React from "react";
import Page from "./Page";
import QuillContent from "../../components/QuillContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MediaController from "../../controllers/MediaController";
import MediaBlock from "../components/MediaBlock";

const useStyles = makeStyles((theme) => ({
    root: {},
    wrap: { paddingTop: theme.spacing(4) },
    header: {
        padding: theme.spacing(2, 8, 0),
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(2, 4, 0),
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2, 2, 0),
        },
    },
    title: {
        maxWidth: 900,
        margin: "auto",
        marginBottom: theme.spacing(4),
    },
    description: {},
    hero: {
        maxWidth: 1260 - theme.spacing(16),
        margin: "auto",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    content: {
        maxWidth: 900,
        margin: "auto",
        padding: theme.spacing(1, 8, 4),
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(1, 4, 4),
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(1, 2, 4),
        },
        boxSizing: "border-box",
    },
    poster: {
        display: "block",
        maxWidth: "100%",
        height: "auto",
    },
}));

const defaultPosterMediaRatio = 0.5;

const APIPage = ({ page, footer, ...props }) => {
    const classes = useStyles();
    return (
        <Page className={classes.root} noPadding {...props}>
            <div className={classes.wrap}>
                {page.title ? (
                    <header className={classes.header}>
                        <div className={classes.title}>
                            <h1>{page.title}</h1>
                        </div>
                    </header>
                ) : null}
                {page.poster_hero && (
                    <div className={classes.hero}>
                        <MediaController id={page.poster_hero}>
                            {({ loaded, media }) => (
                                <MediaBlock
                                    srcLoaded={loaded}
                                    src={media.src}
                                    title={page.title}
                                    alt={page.title}
                                    ratio={
                                        media
                                            ? media.height / media.width
                                            : defaultPosterMediaRatio
                                    }
                                    className={classes.media}
                                />
                            )}
                        </MediaController>
                    </div>
                )}
                {page.content ? (
                    <QuillContent
                        content={page.content}
                        className={classes.content}
                    />
                ) : null}
                {footer}
            </div>
        </Page>
    );
};

export default APIPage;
