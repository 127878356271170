import React, { useState, useEffect } from "react";
import PostJournalController from "../../controllers/PostJournalController";
import PostsJournalController from "../../controllers/PostsJournalController";

import PublicationJournalInfo from "../components/PublicationJournalInfo";
import { generatePath } from "react-router";
import { useTranslate } from "react-admin";
import moment from "moment";

import MediaController from "../../controllers/MediaController";
import MediaImg from "../components/MediaImg";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Page from "./Page";
import { Routes } from "../routes";
import ErrorView from "../components/ErrorView";
import QuillContent from "../../components/QuillContent";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AttachedDocumentsController from "../../controllers/AttachedDocumentsController";
import AttachedDocumentHelper from "../../helpers/AttachedDocumentHelper";

const maxWidthPage = 1260;
const useStyles = makeStyles((theme) => ({
    root: {},
    article: {
        padding: theme.spacing(1, 8),

        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(1, 2),
        },
        maxWidth: maxWidthPage,
        boxSizing: "border-box",
        margin: "0 auto",
    },
    title: {
        display: "flex",
        alignItems: "center",
    },
    date: {
        marginRight: theme.spacing(1),
    },
    img: {
        display: "block",
        maxWidth: "100%",
        height: "auto",
    },

    link: {
        textDecoration: "none",
        [theme.breakpoints.down("xs")]: {
            "&:not(:first-of-type)": {
                marginTop: theme.spacing(2),
            },
        },
    },
    header: {
        padding: theme.spacing(1, 8),
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(1, 2),
        },
    },
    description: {
        padding: theme.spacing(1, 8),
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(1, 2),
        },
    },
    goback: {
        margin: theme.spacing(4),

        [theme.breakpoints.down("md")]: {
            margin: theme.spacing(2),
        },
    },
    next: {
        background: theme.palette.background.default,
        padding: theme.spacing(8),
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(1, 2),
        },
        textAlign: "center",
    },
    nextPost: {
        maxWidth: maxWidthPage,
        display: "flex",
        justifyContent: "space-between",
        margin: "0 auto",
        padding: theme.spacing(1, 8),
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(1, 2),
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(1, 0),
            flexDirection: "column",
        },
    },
    nextButton: {
        textAlign: "right",
        lineHeight: 1.2,
        marginRight: 10,
    },
    prevButton: {
        lineHeight: 1.2,
        textAlign: "left",
        marginLeft: 10,
    },
}));

export default ({
    match: {
        params: { id, slug },
    },
    history,
    ...props
}) => {
    const translate = useTranslate();
    const classes = useStyles();
    const [prevPostMaxDate, setPrevPostMaxDate] = useState();
    const [nextPostMinDate, setNextPostMinDate] = useState();
    const [currentPost, setCurrentPost] = useState();
    useEffect(() => {
        if (currentPost) {
            const nextDate = moment(currentPost.datetime)
                .add(1, "second")
                .toDate();
            setNextPostMinDate(nextDate);
            const prevDate = moment(currentPost.datetime)
                .subtract(1, "second")
                .toDate();
            setPrevPostMaxDate(prevDate);
        }
    }, [JSON.stringify(currentPost)]);
    const onLoaded = (post) => {
        if (JSON.stringify(post) !== JSON.stringify(currentPost)) {
            setCurrentPost(post);
        }
    };
    return (
        <Page className={classes.root} noPadding={true}>
            <Link to={Routes.journal} className={classes.link}>
                <Button variant="outlined" className={classes.goback}>
                    <ArrowBackIosIcon fontSize="small" />
                    {translate("Retour au journal")}
                </Button>
            </Link>
            <article className={classes.article}>
                <PostJournalController id={id} onLoaded={onLoaded}>
                    {({ data: post, error }) =>
                        error ? (
                            <ErrorView component="h2" error={error} />
                        ) : (
                            <>
                                <header className={classes.header}>
                                    <h1 className={classes.title}>
                                        <span>{post.displayName}</span>
                                    </h1>
                                    <PublicationJournalInfo
                                        post={post}
                                    ></PublicationJournalInfo>
                                </header>
                                {post.poster_hero && (
                                    <MediaController id={post.poster_hero}>
                                        {({ loaded, media }) =>
                                            loaded ? (
                                                /**
                                                 * TODO image component with https://mui.wertarbyte.com/#material-ui-image
                                                 */
                                                <MediaImg
                                                    className={classes.img}
                                                    media={media}
                                                    alt={post.displayName}
                                                />
                                            ) : null
                                        }
                                    </MediaController>
                                )}
                                <QuillContent
                                    content={post.content}
                                    className={classes.description}
                                />

                                <div>
                                    <AttachedDocumentsController
                                        postJournal_id={id}
                                        language_id={post.locale}
                                        type="postjournal"
                                    >
                                        {({ loaded, docs }) =>
                                            loaded && docs ? (
                                                docs.map((doc) => {
                                                    const helperDoc = new AttachedDocumentHelper(
                                                        doc
                                                    );
                                                    return (
                                                        <div key={doc.id}>
                                                            <Button
                                                                component="a"
                                                                href={
                                                                    helperDoc.url
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                variant="outlined"
                                                                color="primary"
                                                            >
                                                                <InsertDriveFileIcon />{" "}
                                                                {doc.label
                                                                    ? doc.label
                                                                    : doc.title}
                                                            </Button>
                                                            <br></br>
                                                            <br></br>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div>Loading...</div>
                                            )
                                        }
                                    </AttachedDocumentsController>
                                </div>
                            </>
                        )
                    }
                </PostJournalController>
            </article>
            <aside className={classes.next}>
                <div className={classes.nextPost}>
                    {prevPostMaxDate ? (
                        <PostsJournalController
                            perPage={1}
                            minDate={null}
                            maxDate={prevPostMaxDate}
                        >
                            {({ loaded, error, data: posts }) =>
                                loaded && !error ? (
                                    <>
                                        {posts.map((post, index) => (
                                            <Link
                                                key={index}
                                                className={classes.link}
                                                to={generatePath(
                                                    Routes.journalPost,
                                                    {
                                                        id: post.id,
                                                        slug: post.displayName,
                                                    }
                                                )}
                                            >
                                                <Button variant="outlined">
                                                    <ArrowBackIosIcon fontSize="small" />
                                                    <div
                                                        className={
                                                            classes.prevButton
                                                        }
                                                    >
                                                        <i>
                                                            {translate(
                                                                "article précédent"
                                                            )}
                                                        </i>
                                                        <div>{post.title}</div>
                                                    </div>
                                                </Button>
                                            </Link>
                                        ))}
                                    </>
                                ) : null
                            }
                        </PostsJournalController>
                    ) : null}
                    {nextPostMinDate ? (
                        <PostsJournalController
                            perPage={1}
                            minDate={nextPostMinDate}
                            sort={{
                                field: "datetime",
                                order: "ASC",
                            }}
                        >
                            {({ loaded, error, data: posts }) =>
                                loaded && !error ? (
                                    <>
                                        {posts.map((post, index) => (
                                            <Link
                                                key={index}
                                                className={classes.link}
                                                to={generatePath(
                                                    Routes.journalPost,
                                                    {
                                                        id: post.id,
                                                        slug: post.displayName,
                                                    }
                                                )}
                                            >
                                                <Button variant="outlined">
                                                    <div
                                                        className={
                                                            classes.nextButton
                                                        }
                                                    >
                                                        <i>
                                                            {translate(
                                                                "article suivant"
                                                            )}
                                                        </i>
                                                        <div>{post.title}</div>
                                                    </div>
                                                    <ArrowForwardIosIcon fontSize="small" />
                                                </Button>
                                            </Link>
                                        ))}
                                    </>
                                ) : null
                            }
                        </PostsJournalController>
                    ) : null}
                </div>
            </aside>
        </Page>
    );
};
