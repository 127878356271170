import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "../layout/Sidebar";
import classnames from "classnames";
import useWidth from "../../hooks/useWidth";
import { isWidthDown } from "@material-ui/core/withWidth";
import Footer from "../layout/Footer";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
        height: "100%",
        boxSizing: "border-box",
        "&$horizontal": {
            paddingTop: 40,
        },
        paddingTop: 0,
    },
    main: {
        ".fade-enter &": {
            opacity: "0.01",
        },

        ".fade-enter.fade-enter-active &": {
            opacity: "1",
            transition: "opacity 300ms ease-in",
        },

        ".fade-exit &": {
            opacity: "1",
            // position: "absolute",
            // width: "100%",
            // top: "0",
            // left: "0",
        },

        ".fade-exit.fade-exit-active &": {
            opacity: "0.01",
            transition: "opacity 300ms ease-in",
            // position: "absolute",
            // width: "100%",
            // top: "0",
            // left: "0",
        },
        // position: "fixed",
        // top: 0,
        // bottom: 0,
        // left: 220,
        // right: 116,

        // "$horizontal &": {
        //     top: 40,
        //     left: 0,
        //     right: 0,
        // },

        flexGrow: 1,
        overflowY: "auto",
        overflowX: "hidden",
        WebkitOverflowScrolling: "touch",
        margin: "0 auto",
        padding: ({ noPadding }) => (noPadding ? 0 : theme.spacing(0, 4)),
        [theme.breakpoints.down("xs")]: {
            padding: ({ noPadding }) => (noPadding ? 0 : theme.spacing(0, 2)),
        },
    },
    pageContent: {
        minHeight: "calc(100vh - 300px)",
    },
    sidebar: {
        flexGrow: 0,
        flexShrink: 0,

        "$horizontal &": {
            width: "100%",
            position: "fixed",
            top: 0,
            height: 40,
            minHeight: 40,
            zIndex: 30,
        },
    },
    horizontal: {},
    footer: {},

    topBar: {
        margin: theme.spacing(4),

        [theme.breakpoints.down("md")]: {
            margin: theme.spacing(2),
        },
    },
}));

const Page = ({
    children,
    className,
    sidebarProps,
    noPadding,
    topBar,
    noSidebar,
    noFooter,
    ...props
}) => {
    const classes = useStyles({ noPadding });

    const width = useWidth();
    const isHorizontal = isWidthDown("sm", width);

    return (
        <div
            className={classnames(classes.root, className, className, {
                [classes.horizontal]: isHorizontal,
            })}
        >
            <main className={classes.main}>
                {topBar ? <div className={classes.topBar}>{topBar}</div> : null}
                <div className={classes.pageContent}>{children}</div>
                {noFooter ? null : <Footer />}
            </main>
            {noSidebar ? null : (
                <Sidebar className={classes.sidebar} {...sidebarProps} />
            )}
        </div>
    );
};

Page.defaultProps = {
    sidebarProps: null,
};

export default Page;
